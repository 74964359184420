import React from 'react';
import { connect } from 'react-redux';
import ls from 'local-storage';
import Modal from '../modal';
import history from '../history';
import { decryptData, encryptData } from '../secure/keys';
import { setCookiesAccepted } from '../actions/userActions';

class CookieScreenOK extends React.Component {
  renderActions = () => {

    return (
      <React.Fragment>
        <button  
          onClick={() => this.cookieAccepted()} 
          className="ui primary button">
          Akkoord
        </button>
        <div className="ui button" onClick={()=> this.resetScreen()}>Terug</div>
      </React.Fragment>
    );
  }

  cookieAccepted = () => {
    const unique_id = this.getRM().unique_id;
    this.props.setCookiesAccepted(unique_id);
    this.resetScreen();
  }

  getRM = () => {
    if (ls.get('rm') !== null && ls.get('rm') !== undefined ){
      return decryptData(ls.get('rm'),3);
    }
    return null;
  }

  resetCookieScreen = () => {
    let rm = this.getRM();   
      rm = { ...rm, cookies_accepted: 1 }
      ls.set('rm', encryptData(rm));
  }

  resetScreen = () => {
    this.resetCookieScreen();
    history.push('/');
  }

  render() {
    return (
      <Modal 
        title='Wij gebruiken cookies op onze website'
        content='Met deze cookies kunnen wij de website gebruiksvriendelijker maken. Wij maken gebruik van geanonimiseerde data en uw persoonlijke gegevens worden NIET gedeeld met derden. Meer informatie vindt u in de cookie verklaring op het inlog scherm. Bent u akkoord met het plaatsen van cookies?'
        actions={this.renderActions()}
        onDismiss={() => this.resetScreen()}
        noBackgroundImage = 'true'
      />
    );
  } 
}

export default connect(null, { setCookiesAccepted })(CookieScreenOK)