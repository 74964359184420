import React from 'react';
import { Route, Redirect } from 'react-router-dom';


export const PrivateRoute = ({ component: Component,auth, ...rest }) => {

  //let isSignedIn = auth.isSignedIn;

  return(
    <Route { ...rest} render={props => auth.isSignedIn
    ? (
      <Component key={props.match.params.id || 'empty' } { ...props } />
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
    } />
  );
}

