import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportToCSV = ({ csvData, fileName }) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = 'xlsx';

  const exportToCSV = (csvData, fileName) => {


    if (csvData.length>0){
      if( Object.prototype.toString.call( csvData ) === '[object Array]' ) {
        let names = csvData.map((data) => {
          if (data[0] !== undefined){
            return data[0].onderwerp;
          }
          return null;
        });
        const XLSnames = names.filter(name => name !== null);
        let sheets = csvData.map((data) => {
          if (data.length>0) { 
            return data;
          }
          return null;
        });
        sheets = sheets.filter( sheet => sheet != null);
        let XLSsheets = sheets.map((sheet) => {
          const ws =  XLSX.utils.json_to_sheet(sheet);

        //Set header style
        var range = XLSX.utils.decode_range(ws['!ref']);
        for(var C = range.s.c; C <= range.e.c; ++C) {
          var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
          if(!ws[address]) continue;
          ws[address].v = ws[address].v.toUpperCase();
          ws[address].s = {
            "font": {
              "name": "Calibri",
              bold: true,
              "sz": 16,
              "color": {
                "rgb": "FF2222FF"
              }
            }
          }
        }
          return ws;
        });
        
        const wb = XLSX.utils.book_new();
        for(let i=0;i<XLSnames.length;i++){
          if (XLSnames[i].length > 31) { XLSnames[i] = XLSnames[i].slice(XLSnames[i].length -31)}
          XLSX.utils.book_append_sheet(wb, XLSsheets[i],XLSnames[i]);
        }

        const excelBuffer = XLSX.write(wb, {bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName);
      }
    }
  }

  return (
    <div className='ui positive button' style={{ float: 'right'}} onClick={(e) => exportToCSV(csvData, fileName)}>Export</div>
  );
}