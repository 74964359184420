/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
import  { 
  FETCH_NOTCOUPLED_VALUES
} from '../actions/types';

const initialState = 
  {
    999999999:
    {
      value_id: 0,
      unique_id: 0,
      document_id: 0,
      doc_type: '',
      document_name: '',
      description: '',
      interpretation: '-',
      bondage: '',
      topic: '-',
      ow_29: '',
      ow_210: '',
      status: '',
      qual_quan: '',
      reference: ''
    }
  };

export default (state = initialState, action) => {

  switch (action.type) {
    case FETCH_NOTCOUPLED_VALUES:
      state = initialState;
      return { ...state, ..._.mapKeys(action.payload, 'value_id')};
        
    default:
      return state;
  }
};