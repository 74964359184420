/* eslint-disable import/no-anonymous-default-export */
import { FETCH_PROFILES } from '../actions/types';
import _ from 'lodash';

const INIT_STATE = { 
  0: {
    profile_id: 0,
    profile_name: "",
    description: "",
    themes: []
  }
};

export default (state = INIT_STATE, action) => {
  switch(action.type){
    case FETCH_PROFILES:
      return {...state, ..._.mapKeys(action.payload, 'profile_id')};
    default:
    return state;
  };
};