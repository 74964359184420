import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ selected, onSelectedChange, options, label, image }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (event) => {
     if (ref.current && ref.current.contains(event.target)) {
        return;
      }
 
      setOpen(false);
    };
 
    document.body.addEventListener('click', onBodyClick);
 
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  const convertSVG = (svg) => {
    let blob = new Blob([svg], {type: 'image/svg+xml'});
    return URL.createObjectURL(blob);
  }

  const setSelectedValue = () => {
 
    if (selected.value !== undefined){
      if (image === 'true'){
        return (    
          <div className="text">
            <img className='ui avatar image' src={convertSVG(selected.city_logo)} alt={selected.value} />
              {selected.value}
            </div>
          );
      }

      return(
        <div className="text">
          {selected.value}
        </div>        
      );
    }
  }

  const renderedOptions = () => {

    if (image === 'true') {
      const renderOptionsWithImages = options.map((option) => {
        if(selected.value.toLowerCase() === option.value.toLowerCase()){
          return null;
        } 
        return (
          <div 
            key={option.key}
            onClick = {() => onSelectedChange(option)}
            className='item'
          > 
            <img className='ui avatar image' src={convertSVG(option.city_logo)} alt={option.city_name} />
            {option.value}
          </div>
        );
      });
      return renderOptionsWithImages;
    } else{
      
      const renderOptionsWithoutImages = options.map((option) => {
        
        if(selected.value === option.value){
          return null;
        }

        return (
          <div 
            key={option.key}
            onClick = {() => onSelectedChange(option)}
            className='item'
          > 
            {option.value}
          </div>
        );
      });
      return renderOptionsWithoutImages;
      
    }
  }


  return (
    <div ref={ref} style={{width:'10em', height: '60px', zIndex: '90' }} className="ui form">
      <div className="field">
        <label className="label">
          {label}
        </label>
        <div
          style={{
            width: "3.5em", 
            height: "40px", 
            boxShadow: "1px 1px rgba(34,36,38,0.15)",
            outline: "none" 
          }} 
          className={`ui selection dropdown ${open ? 'visible active' : ''}`} 
          onClick={() => setOpen(!open)}>
          <i className="dropdown icon" />
          {setSelectedValue()}
          <div className={`menu ${open ? 'visible transition' : ''}`} >
          {renderedOptions()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;