/* eslint-disable import/no-anonymous-default-export */
import { SHOW_PASSWORD, SHOW_PHONENUMBER } from '../actions/types';
const INIT_STATE = { 
  passwordShown: false, 
  blackedNumber: "blackednumber"
};

export default (state = INIT_STATE, action) => {
  switch(action.type){
    case SHOW_PASSWORD:
      return {  ...state, passwordShown: action.payload };
    case SHOW_PHONENUMBER:
      return {  ...state, blackedNumber: action.payload };
    default:
    return state;
  };
};