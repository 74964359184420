import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import UserForm from '../users/UserForm';
import Dropdown from '../elements/Dropdown';
import Header from '../Header';
import { setActiveMenu, setSelectedRole, setDimensions } from '../../actions';
import { setNewUser, createUser } from '../../actions/userActions';


class UserCreate extends React.Component {

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('4');
    this.props.setNewUser();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  
  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  changeSelection = (role) => {
    var rights = '';
    if(role.value === "Gebruiker") rights="01000100000000000000"; 
    if(role.value === "Beheerder") rights="11101110000000000100";
    if(role.value === "Admin") rights="11111111111111110100";
    if(role.value === "SuperAdmin") rights="11111111111111111111";
    this.props.setSelectedRole(role,this.props.user, rights);
  }

  renderDropdown = () =>{
    const roles= [
      {key: "1", text: "User", value: "Gebruiker"}, 
      {key: "2", text: "SuperUser", value: "Beheerder"}, 
      {key: "3", text: "Admin", value: "Admin"}, 
      {key: "4", text: "SuperAdmin", value: "SuperAdmin"}
    ];
    const sel = {value: this.props.user.role}
    return(
      <Dropdown 
        label='' 
        options={roles}
        selected={sel} 
        onSelectedChange={(setSelectedRole) => this.changeSelection(setSelectedRole)}
        image='false'
      />
    );
  }

  onSubmit = (formValues) => {
    this.props.createUser(formValues, this.props.page.activePage);
  };

  render(){
    const initialValues = _.pick(this.props.user, 'fname', 'mname', 'lname', 'city_oin', 'city_name', 'email', 'role', 'username', 'password', 'phonenumber', 'blackednumber');
    
    return(
      <div 
        style={{ 
          width:this.props.dimensions.width, 
          height: this.props.dimensions.height,  
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(175,244,165,0.48082983193277307) 100%)'
        }}
        className="ui container"
      >
        <div className="ui container">
        <Header location={this.props.location.pathname} />
        <h3>Nieuwe gebruiker aanmaken:</h3>
        <h4 className="ui dividing header">
        <div>
          <div style={{ display: "inline-block" }} className="ui content">
            Gebruiker #{this.props.user.unique_id}
          </div>
          <div style={{ float:"right", marginTop: "-43px" }}>
            Rol: {this.renderDropdown()}
          </div>
        </div>
        </h4>
        <UserForm
          ind= {this.props.user.ind}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          userid= '0'
          name='Opslaan'
        />
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users[0],
    dimensions: state.dimensions,
    page: state.pages
  }
}

export default connect(mapStateToProps, { setActiveMenu, setSelectedRole, setNewUser, createUser, setDimensions })(UserCreate);