import documents from '../apis/documents';
import history from '../history';
import _ from 'lodash';


import { 
  FETCH_DOCUMENTS,
  FETCH_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  CREATE_DOCUMENT,
  FETCH_DOMAINS,
  SET_PRIMARY_DOMAIN,
  SET_SECUNDARY_DOMAIN, 
  ADD_DOMAIN,
  FETCH_THEMES,
  SET_THEME,
  ADD_THEME,
  SET_TYPE,
  SET_DOCUMENT,
  READ_ALL_URLS,
  SET_ACCORDION_ID,
  SET_DOCUMENT_ARRAY_DELETE,
  FETCH_DELETED_DOCUMENTS,
  SET_DOC_CHOICE
} from './types';

const debugmode = process.env.REACT_APP_DEBUGMODE;

export const addNewTheme = (theme) => async dispatch => {
  const response = await documents.post('update_themes.php', theme);

  dispatch({type: ADD_THEME, payload: response.data });
};

export const changeAccordionId = (id) => dispatch => {

  dispatch({ type: SET_ACCORDION_ID, payload: id });
}

export const fetchDomains = () => async dispatch => _fetchDomains(dispatch);
  const _fetchDomains = _.memoize(async (dispatch) => {
  const response = await documents.get(`/read_all_domains.php?debugmode=${debugmode}`);

  dispatch({ type: FETCH_DOMAINS, payload: response.data });
});

export const addNewDomain = (domain) => async dispatch => {
  const response = await documents.post('update_domains.php', domain);

  dispatch({ type: ADD_DOMAIN, payload: response.data});
};

export const setDomain = (i, domain) => dispatch => {
  
  if (i === 1) {
    dispatch ({ type: SET_PRIMARY_DOMAIN, payload:domain });
  }
  dispatch ({ type: SET_SECUNDARY_DOMAIN, payload: domain });
};

export const setTypes = (type) => dispatch => {
  dispatch ({ type: SET_TYPE, payload: type });
  dispatch({ type:SET_DOCUMENT, payload: { key: 0, text: '', value: '' }})
}; 

export const setDocument = (doc) => {
  return { type: SET_DOCUMENT, payload: doc }
};

export const setTheme = (theme) => dispatch => {
  dispatch ({ type: SET_THEME, payload: theme })
}; 

export const fetchThemes = () => async dispatch => _fetchThemes(dispatch);
  const _fetchThemes = _.memoize(async (dispatch) => {
  const response = await documents.get(`/read_all_themes.php?debugmode=${debugmode}`);

  dispatch({ type: FETCH_THEMES, payload: response.data });
});

export const deleteDocument = (id, unique_id, activePage) => async dispatch => {
  const data = { document_id: id, unique_id: unique_id, debugmode: debugmode }
  const response = await documents.post(`delete.php?document_id=${id}`, data);

  dispatch({ type: DELETE_DOCUMENT, payload: response.data.document_id });
  history.push(`/documents/list/${activePage}`);
};

export const editDocument = (docId, formValues, activePage) => async dispatch => {
  const response = await documents.post(`/update.php?id=${docId}`, formValues);

  dispatch({ type: EDIT_DOCUMENT, payload: response.data })
  history.push(`/documents/list/${activePage}`)
};

export const fetchDocuments = () => async dispatch => _fetchDocuments(dispatch);
const _fetchDocuments = _.memoize(async (dispatch) => {
  const response = await documents.get(`/read_all.php?debugmode=${debugmode}`);

  dispatch({ type: FETCH_DOCUMENTS, payload: response.data })
});

export const fetchAllDocuments = () => async (dispatch) => {
  const response = await documents.get(`/read_all.php?debugmode=${debugmode}`);

  dispatch({ type: FETCH_DOCUMENTS, payload: response.data })
}

export const fetchDocument = (id) => async dispatch => {
  const response = await documents.get(`/read_one.php?id=${id}`);

  dispatch({ type: FETCH_DOCUMENT, payload: response.data })
};

export const createDocument = (formValues) => async (dispatch) => {
  const unique_id=formValues.unique_id;
  formValues = { ...formValues, debugmode: debugmode };
  const response = await documents.post(`/create.php?key=${unique_id}`, { ...formValues, unique_id });

  dispatch({ type: CREATE_DOCUMENT, payload: response.data })
  history.push('/documents/list/1')
};

export const getAllURLs = () => async dispatch => {
  const response = await documents.get(`/read_all_urls.php`);
  console.log(response.data);
  dispatch({ type: READ_ALL_URLS, payload: response.data })
}

export const getDeletedDocuments = () => async dispatch => {
  const response = await documents.get(`/read_deleted.php?debugmode=${debugmode}`);

  if (response.status === 200){
    dispatch({ type: FETCH_DELETED_DOCUMENTS, payload: response.data });
  } else {
    dispatch({ type: FETCH_DELETED_DOCUMENTS, payload: null });
  }
  dispatch({ type: SET_DOCUMENT_ARRAY_DELETE, payload: [] });
  
};

export const setArrDelete = (arr) => {
  return {
    type: SET_DOCUMENT_ARRAY_DELETE,
    payload: arr
  }
};

export const deleteDocumentFinal = (arr) => async dispatch => {
  const data = { arr: arr, debugmode: debugmode, no_arr: false };
  const response = await documents.post(`/delete.php`, data);

  if (response.status === 200){
    dispatch({ type: FETCH_DELETED_DOCUMENTS, payload: response.data });
  } else {
    dispatch({ type: FETCH_DELETED_DOCUMENTS, payload: null });
  }
  dispatch({ type: SET_DOCUMENT_ARRAY_DELETE, payload: [] });
  history.push(`/beheer/dashboard`);
}

export const resetDocument = (arr) => async dispatch => {
  const data = { arr: arr, debugmode: debugmode };
  const response = await documents.post(`/update.php`, data);

  if (response.status === 200){
    dispatch({ type: FETCH_DELETED_DOCUMENTS, payload: response.data });
  } else {
    dispatch({ type: FETCH_DELETED_DOCUMENTS, payload: null });
  }
  dispatch(fetchAllDocuments());
  dispatch({ type: SET_DOCUMENT_ARRAY_DELETE, payload: [] });
  history.push(`/beheer/dashboard`);
}

export const resetValueOnDocument= (action) => dispatch =>{

  switch(action.actionSelected){
    case '1':
      return history.push(`/documents/new`);
    case '2':
      return history.push(`/beheer/documenten/list/reset/${action.valId}`);
    case '3':
      return dispatch(resetDocument([action.docId]));
    default:
      return history.push(`/beheer/waarden/delete/${action.valId}`);
  }
}

export const setDocumentChoice = (target) => dispatch => {
  dispatch({ type: SET_DOC_CHOICE, payload: target });
}
