import React from 'react';
import ls from 'local-storage';
import Header from '../Header';
import Profiles from '../elements/Profiles';
import { encryptData } from '../../secure/keys';
import { connect } from 'react-redux';
import { setDimensions, fetchProfiles } from '../../actions';
import { fetchValues } from '../../actions/valueActions';


class AnalyseMenu extends React.Component {
  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };

  componentDidMount(){

    if (this.props.auth !== undefined && this.props.auth.isSignedIn && this.props.auth.userId !== "") {
      const currentUser = this.props.currentUser;
      ls.set('cu', encryptData(currentUser));
    }

    window.addEventListener('resize', this.updateDimensions);
    this.props.fetchProfiles();
    this.props.fetchValues();
    ls.set('ll', '/analyse');
  };

  setProfile = (idx) => {
    const colors = ['grey', 'red', 'orange', 'brown', 'olive', 'green', 'teal', 'blue', 'violet', 'purple'];

    if (!this.props.profiles[idx]) {
      return <Profiles color={colors[0]} profile={this.props.profiles[0]} icon='icon0.png' />
    };
    return <Profiles color={colors[idx]} profile={this.props.profiles[idx]} icon={`icon${idx}.png`} />;
  }

  render(){
    return(
      <div 
        style={{ 
          width:this.props.dimensions.width, 
          height: this.props.dimensions.height, 
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(118,238,221,0.48082983193277307) 100%)'
        }}
        className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h2>Analyse</h2>
          <div className="ui three cards">
            {this.setProfile(1)}
            {this.setProfile(2)}
            {this.setProfile(3)}
          </div>
          <div className="ui three cards">
            {this.setProfile(4)}
            {this.setProfile(5)}
            {this.setProfile(6)}
          </div>
          <div className="ui three cards">
            {this.setProfile(7)}
            {this.setProfile(8)}
            {this.setProfile(9)}        
          </div>
        </div>
      </div>
    );
  };

};

const mapStateToProps = (state) => {
  return {
    dimensions: state.dimensions,
    profiles: state.profiles,
    auth: state.auth,
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps, { setDimensions, fetchProfiles, fetchValues })(AnalyseMenu);