import React from 'react';
import Header from '../Header';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setActiveMenu, setDimensions } from '../../actions';
import { fetchDocuments, setTypes, setDocument } from '../../actions/documentActions';
import { createValue } from '../../actions/valueActions';
import ValueForm from './ValueForm';
import Dropdown from '../elements/Dropdown';

class ValueCreate extends React.Component {

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('3');
    this.props.fetchDocuments();
    this.props.setDocument({key: 0, text: '', value: ''})

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  prepareInitialValues = () => {
    let inVal= _.pick(this.props.value, 'value_id', 'unique_id','document_id', 'doc_type', 'document_name', 'description', 'interpretation', 'bondage', 'topic', 'ow_29', 'ow_210', 'status', 'qual_quan', 'reference','ind');
    inVal.topic = inVal.topic.charAt(0).toUpperCase() + inVal.topic.slice(1);
    inVal.interpretation = inVal.interpretation.charAt(0).toUpperCase() + inVal.interpretation.slice(1);
    return inVal;
  }

  renderDocDropdown = () => {
    let documenten = Object.values(this.props.documents).map((doc) => {
      if(doc.type.includes(this.props.selections.selectedType.key)) {
        return { key: doc.document_id, text: doc.title, value: doc.type};
      }
      return null;
    });
    documenten = documenten.filter(doc => doc !== null);
    return (
      <Dropdown
      label={'Document'}
      options={documenten}
      selected={this.props.selections.selectedDocument}
      onSelectedChange={(setDoc) => this.onChangeSelection(setDoc)}
      image='false'
      />
    );
  }

  onChangeSelection = (doc) => {
    const docDropDown = document.getElementById('docDropDown');
    const lblDocName = document.getElementById('lblDocumentName');
    docDropDown.style.display = 'none';
    lblDocName.style.display ='inline-block';
    this.props.setDocument(doc);
  }

  renderDocTypeDropdown = () => {
    const types = [
      {key: 'BL', text: 'BL', value: 'Beleidswaarde'}, 
      {key: 'BP', text: 'BP', value: 'Bestemmingsplan'},
      {key: 'BS', text: 'BS', value: 'Bruidsschat'},
      {key: 'OV', text: 'OV', value: 'Omgevingsverordening'},
      {key: 'VOW', text: 'VOW', value: 'Ontwerpbesluit'},
      {key: 'VR', text: 'VR', value: 'Verordening'}
      
    ];
    
    return (
        <Dropdown
        label={'Type Document'}
        options={types}
        selected={this.props.selections.selectedType}
        onSelectedChange={(setType) => this.props.setTypes(setType)}
        image='false'
        />
    );
  }

  onSubmit = (formValues, activePage) => {
    const docDropDown = document.getElementById('docDropDown');
    if (this.props.selections.selectedDocument.value === '') {
      docDropDown.style.display = 'inline-block';
    } else {
      docDropDown.style.visibility = 'none';
    }
    formValues = { ...formValues, unique_id: this.props.currentUser.unique_id}
    formValues = { ...formValues, document_id: this.props.selections.selectedDocument.key, document_name: this.props.selections.selectedDocument.text, doc_type: this.props.selections.selectedDocument.value };
    console.log(formValues);
    this.props.createValue(formValues, activePage);
  }

  render(){
    const initialValues = this.prepareInitialValues();
    return(
      <div 
      style={{ 
        width:this.props.dimensions.width, 
        height: this.props.dimensions.height,  
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,179,165,0.48082983193277307) 100%)'
      }}
      className="ui container"
      >
          <div className="ui container">
            <Header location={this.props.location.pathname} />
            <h3>Nieuwe waarde toevoegen:</h3>
            <h4 className="ui dividing header">
              <div>
                <div style={{ display: "inline-block" }} className="ui form">
                  <div className='two fields'>
                    <div className='eight wide field'>
                      {this.renderDocTypeDropdown()}
                    </div>
                    <div className='eight wide field'>
                      {this.renderDocDropdown()}
                      <label id='docDropDown' style={{ display: 'none', marginTop: '8px' }} className="ui top pointing red basic label">Selecteer een document!</label>
                    </div>
                  </div>
                </div>
                <label id='lblDocumentName' style={{ display: 'none'}}>{`Komt voor in "${this.props.selections.selectedDocument.text}"`}</label>
              </div>
            </h4>
            <ValueForm
              value_id= '0'
              initialValues= {initialValues}
              onSubmit={this.onSubmit}
              name='Opslaan' 
            />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dimensions: state.dimensions,
    value: state.values[999999999],
    selections: state.selections,
    documents: state.documents, 
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps, { setActiveMenu, setDimensions, fetchDocuments, setTypes, setDocument, createValue })(ValueCreate);