/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import { SEARCH_ALL } from "../actions/types";

const initialState = {
	term: "",
	result: [],
};
//Edit: Object-based approach

export default (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_ALL:
			return action.payload;
		default:
			return state;
	}
};
