import password from "secure-random-password";
import users from "../apis/users";
import cities from "../apis/cities";
import login from "../apis/login";
import mail from "../apis/mail";
import documents from "../apis/documents";
import values from "../apis/values";
import search from "../apis/search";
import _ from "lodash";
import { encryptData, decryptData } from "../secure/keys";
import { reset } from "redux-form";

import {
	SIGN_IN,
	SIGN_OUT,
	SHOW_PASSWORD,
	SHOW_PHONENUMBER,
	FETCH_ACTIVE_USERS,
	FETCH_ACTIVE_DELETION_USERS,
	FETCH_CITIES,
	SELECTED_CITY,
	SELECTED_ROLE,
	SELECTED_MENU,
	FETCH_CURRENT_USER,
	SELECTED_ITEMS_PER_PAGE,
	GET_PAGINATION,
	LOGIN,
	LOGOUT,
	SEND_CODE,
	SET_DIMENSIONS,
	FETCH_ACTIVE_DOCUMENTS,
	FETCH_ACTIVE_DELETION_DOCUMENTS,
	FETCH_ACTIVE_VALUES,
	FETCH_ACTIVE_DELETION_VALUES,
	SET_TAB,
	ADD_MULTIPLESELECTBOX_VALUES,
	DEL_MULTIPLESELECTBOX_VALUES,
	RESET_MULTIPLESELECTBOX_VALUES,
	SET_ORIGIN,
	SET_INTERPRETATION,
	SEND_MAIL,
	SET_TOPIC,
	FETCH_PROFILES,
	CHANGE_PASS,
	RANDOM_PASSWORD,
	FETCH_NOTCOUPLED_VALUES,
	SEARCH_ALL,
} from "./types";

const debugmode = process.env.REACT_APP_DEBUGMODE;

export const setDimensions = (dimensions) => {
	return {
		type: SET_DIMENSIONS,
		payload: dimensions,
	};
};

export const setRandomPassword = () => (dispatch) => {
	const pass = password.randomPassword({
		characters: password.lower + password.upper + password.digits,
	});
	dispatch({ type: RANDOM_PASSWORD, payload: pass });
};

export const fetchProfiles = () => async (dispatch) => {
	const response = await values.get("/read_all_profiles.php");

	dispatch({ type: FETCH_PROFILES, payload: response.data });
};

export const sendWWEmail = (email) => async (dispatch) => {
	const pass = password.randomPassword({
		characters: password.lower + password.upper + password.digits,
	});
	const data = encryptData({ ...email, password: pass });
	const response = await mail.post("/sendchangemail.php", data);

	dispatch({ type: CHANGE_PASS, payload: response.data });
};

export const searchAll = (term) => async (dispatch) => {
	let data = { search: term, debugmode: debugmode };
	const response = await search.post("search.php", data);
	data = { term: term, result: response.data };
	dispatch({ type: SEARCH_ALL, payload: data });
};

export const sendMail = (email) => async (dispatch) => {
	const data = encryptData(email);
	const response = await mail.post("/sendmail.php", data);

	dispatch({ type: SEND_MAIL, payload: response.data });
};

export const setOrigin = (origin) => {
	return {
		type: SET_ORIGIN,
		payload: origin,
	};
};

export const setTopic = (topic) => (dispatch) => {
	dispatch({ type: SET_TOPIC, payload: topic });
};

export const fetchMultipleSelectBoxValues = (value, action) => (dispatch) => {
	if (action === "reset") {
		dispatch({ type: RESET_MULTIPLESELECTBOX_VALUES, payload: null });
	} else if (action === "add") {
		dispatch({ type: ADD_MULTIPLESELECTBOX_VALUES, payload: value });
	} else {
		const id = Object.keys(value);
		dispatch({ type: DEL_MULTIPLESELECTBOX_VALUES, payload: id[0] });
	}
};

export const signIn = (unique_id) => async (dispatch) =>
	_signIn(unique_id, dispatch);
const _signIn = _.memoize(async (unique_id, dispatch) => {
	const response = await users.get(`/read_one.php?unique_id=${unique_id}`);
	const responseData = decryptData(response.data, 1);

	dispatch({ type: FETCH_CURRENT_USER, payload: responseData });
	dispatch({ type: SIGN_IN, payload: unique_id });
});

export const signOut = () => (dispatch) => {
	dispatch({ type: LOGOUT });
	dispatch({ type: SIGN_OUT });
};

export const sendCode = (formValues) => async (dispatch) => {
	const data = encryptData(formValues);

	const response = await login.post("/loginCode.php", data);

	if (response.data.succes === "true") {
		dispatch({ type: FETCH_CURRENT_USER, payload: response.data });
		dispatch({ type: SIGN_IN, payload: response.data.unique_id });
	} else {
		dispatch({ type: SEND_CODE, payload: response.data });
		dispatch(reset("loginCodeForm"));
	}
};

export const resetPassword = (formValues) => async (dispatch) => {
	const data = encryptData(formValues);

	const response = await login.post("login.php", data);
	dispatch({ type: LOGIN, payload: response.data });
};

export const fetchLogin = (formValues) => async (dispatch) => {
	formValues = { ...formValues, debugmode: debugmode };

	const data = encryptData(formValues);
	const response = await login.post("/login.php", data);

	dispatch({ type: LOGIN, payload: response.data });
};

export const showPassword = (passwordShown) => {
	return {
		type: SHOW_PASSWORD,
		payload: passwordShown ? false : true,
	};
};

export const setBlackedNumber = (blackedNumber) => {
	return {
		type: SHOW_PHONENUMBER,
		payload:
			blackedNumber === "blackednumber" ? "phonenumber" : "blackednumber",
	};
};

export const setTab = (tab) => {
	return {
		type: SET_TAB,
		payload: tab,
	};
};

export const setInterpretation = (val) => (dispatch) => {
	dispatch({ type: SET_INTERPRETATION, payload: val });
};

export const setActiveMenu = (id) => (dispatch) => {
	dispatch({ type: SELECTED_MENU, payload: id });
};

export const fetchCities = () => async (dispatch) => {
	const response = await cities.get("/read_all.php");

	dispatch({ type: FETCH_CITIES, payload: response.data });
};

export const setSelectedCity = (city) => {
	return {
		type: SELECTED_CITY,
		payload: city,
	};
};

export const setSelectedRole = (role, user, rights) => {
	user = { ...user, role: role.value, rights: rights };
	return {
		type: SELECTED_ROLE,
		payload: { user },
	};
};

export const setSelectedItemsPerPage = (item) => {
	return {
		type: SELECTED_ITEMS_PER_PAGE,
		payload: item,
	};
};

export const setPagination =
	(category, page, itemsPerPage) => async (dispatch) => {
		let response = {};
		if (category === "users") {
			response = await users.get(
				`/read_paging.php?page=${page}&rec_per_page=${itemsPerPage}&direction=0&debugmode=${debugmode}`
			);
		} else if (category === "beheer/users") {
			response = await users.get(
				`/read_paging.php?page=${page}&rec_per_page=${itemsPerPage}&direction=1&debugmode=${debugmode}`
			);
		} else if (category === "documents") {
			response = await documents.get(
				`/read_paging.php?page=${page}&rec_per_page=${itemsPerPage}&direction=0&debugmode=${debugmode}`
			);
		} else if (category === "beheer/documenten") {
			response = await documents.get(
				`/read_paging.php?page=${page}&rec_per_page=${itemsPerPage}&direction=1&debugmode=${debugmode}`
			);
		} else if (category === "values") {
			response = await values.get(
				`/read_paging.php?page=${page}&rec_per_page=${itemsPerPage}&direction=0&debugmode=${debugmode}`
			);
		} else if (category === "beheer/waarden") {
			response = await values.get(
				`/read_paging.php?page=${page}&rec_per_page=${itemsPerPage}&direction=1&debugmode=${debugmode}`
			);
		} else if (category === "beheer/waarden/koppelen") {
			response = await values.get(
				`/read_paging.php?page=${page}&rec_per_page=${itemsPerPage}&direction=2&debugmode=${debugmode}`
			);
		}

		if (category === "users") {
			if (response.data.result === 0) {
				dispatch({
					type: GET_PAGINATION,
					payload: { activePage: "1", pages: "1", totalRows: "0" },
				});
			} else {
				const responseData = decryptData(response.data, 1);
				dispatch(
					{ type: GET_PAGINATION, payload: responseData.paging },
					{ type: FETCH_ACTIVE_USERS, payload: responseData.users }
				);
			}
		} else if (category === "beheer/users") {
			if (response.data.result === 0) {
				dispatch({
					type: GET_PAGINATION,
					payload: { activePage: "1", pages: "1", totalRows: "0" },
				});
			} else {
				const responseData = decryptData(response.data, 1);
				dispatch(
					{ type: GET_PAGINATION, payload: responseData.paging },
					{ type: FETCH_ACTIVE_DELETION_USERS, payload: responseData.users }
				);
			}
		} else if (category === "documents") {
			if (response.data.result === 0) {
				dispatch(
					{
						type: GET_PAGINATION,
						payload: { activePage: "1", pages: "1", totalRows: "0" },
					},
					{ type: FETCH_ACTIVE_DOCUMENTS, payload: response.data.documents }
				);
			} else {
				dispatch(
					{ type: GET_PAGINATION, payload: response.data.paging },
					{ type: FETCH_ACTIVE_DOCUMENTS, payload: response.data.documents }
				);
			}
		} else if (category === "beheer/documenten") {
			if (response.data.result === 0) {
				dispatch(
					{
						type: GET_PAGINATION,
						payload: { activePage: "1", pages: "1", totalRows: "0" },
					},
					{
						type: FETCH_ACTIVE_DELETION_DOCUMENTS,
						payload: response.data.documents,
					}
				);
			} else {
				dispatch(
					{ type: GET_PAGINATION, payload: response.data.paging },
					{
						type: FETCH_ACTIVE_DELETION_DOCUMENTS,
						payload: response.data.documents,
					}
				);
			}
		} else if (category === "values") {
			if (response.data.result === 0) {
				dispatch(
					{
						type: GET_PAGINATION,
						payload: { activePage: "1", pages: "1", totalRows: "0" },
					},
					{ type: FETCH_ACTIVE_VALUES, payload: response.data.values }
				);
			} else {
				dispatch(
					{ type: GET_PAGINATION, payload: response.data.paging },
					{ type: FETCH_ACTIVE_VALUES, payload: response.data.values }
				);
			}
		} else if (category === "beheer/waarden") {
			if (response.data.result === 0) {
				dispatch(
					{
						type: GET_PAGINATION,
						payload: { activePage: "1", pages: "1", totalRows: "0" },
					},
					{ type: FETCH_ACTIVE_DELETION_VALUES, payload: response.data.values }
				);
			} else {
				dispatch(
					{ type: GET_PAGINATION, payload: response.data.paging },
					{ type: FETCH_ACTIVE_DELETION_VALUES, payload: response.data.values }
				);
			}
		} else if (category === "beheer/waarden/koppelen") {
			if (response.data.result === 0) {
				dispatch(
					{
						type: GET_PAGINATION,
						payload: { activePage: "1", pages: "1", totalRows: "0" },
					},
					{ type: FETCH_NOTCOUPLED_VALUES, payload: response.data.values }
				);
			} else {
				dispatch(
					{ type: GET_PAGINATION, payload: response.data.paging },
					{ type: FETCH_NOTCOUPLED_VALUES, payload: response.data.values }
				);
			}
		}
	};
