import React from 'react';
import '../css/login.css';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

class loginForm extends React.Component {

  renderInput= ({ input, label, meta, type, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    if (id === 'code1') {
      return (
        <React.Fragment>
          <input {... input} id={id} type={type} placeholder={label} className={className} maxLength="1" size="1" style={{ width:'44px', verticalAlign:"middle", display:"inline-block", margin:"0 8px auto"}} autoComplete="off" onChange={this.setFocus} onKeyDown={this.handleEnter} autoFocus="autofocus" />
        </React.Fragment>
      );
    } 
    return (
        <React.Fragment>
          <input {... input} id={id} type={type} placeholder={label} className={className} maxLength="1" size="1" style={{ width:'44px', verticalAlign:"middle", display:"inline-block", margin:"0 8px auto"}} autoComplete="off" onChange={this.setFocus} onKeyDown={this.handleEnter} />
        </React.Fragment>
    );
  };

  handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  setFocus(event) {
    if (event.target.value.length < 2){
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus()
      event.preventDefault();
    }
  }

  onSubmit = (formValues) => {
      const values = {...formValues, ...this.props.login }
      this.props.onSubmit(values);
  }

  setLoginbox = () => {
    const w = Math.ceil(this.props.width/2);
    const h = Math.ceil(this.props.height/2);
    const t = Math.ceil(h/2);
    const l = Math.ceil(w/2);

    return (
      <div id='login' className="main" style={{ width: w, height: h, left:l, top:t, border:'2px solid #85BE1A', borderRadius:'6px', backgroundColor:'#FFFFEE', opacity:'1'  }}>
        <div className="header" style={{ marginTop:'2px' }} >
          <img src={process.env.PUBLIC_URL + "/images/BWG-online.png"} alt='BWG-online' style={{ float:'left', width: '15%', height: 'auto'}} />
          <h1 className='ui header' style={{ color:'#4EA619', textAlign:'center'}}>
            Login bij de beleidswaarden generator online!
          </h1>
        </div>
        <form id="loginCodeForm" name='loginCodeForm' className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)} style={{ width:'90%' }}>
          <h2>
            Voor de SMS-code in:
          </h2>
          <div className="fields" style={{ position:"relative", textAlign:"center", marginBottom:"88px", }}>
            <Field id ="code1" name="code1" type="text" maxlength="1" size="1" component={this.renderInput} />
            <Field id ="code2" name="code2" type="text" maxlength="1" size="1" component={this.renderInput} />
            <Field id ="code3" name="code3" type="text" maxlength="1" size="1" component={this.renderInput} />
            <Field id ="code4" name="code4" type="text" maxlength="1" size="1" component={this.renderInput} />
            <Field id ="code5" name="code5" type="text" maxlength="1" size="1" component={this.renderInput} />
            <Field id ="code6" name="code6" type="text" maxlength="1" size="1" component={this.renderInput} />
          </div>
          <div className="ui below red basic label error" style= {{ position: 'relative', top:'-50px', display: `${this.props.err !== '' ? 'block' : 'none' }` }}>
            {this.props.err}
          </div>
          <button  style={{ position: 'relative', top:'-45px'}} className="ui fluid olive button" type="submit">Inloggen</button>
        </form>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.setLoginbox()}
      </React.Fragment>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  var regexcode = new RegExp(/^[0-9\b]+$/);
  
  if (!regexcode.test(formValues.code1)){
    errors.code1 = "Dit is geen cijfer!";
  }
  if (!regexcode.test(formValues.code2)){
    errors.code2 = "Dit is geen cijfer!";
  }
  if (!regexcode.test(formValues.code3)){
    errors.code3 = "Dit is geen cijfer!";
  }
  if (!regexcode.test(formValues.code4)){
    errors.code4 = "Dit is geen cijfer!";
  }
  if (!regexcode.test(formValues.code5)){
    errors.code5 = "Dit is geen cijfer!";
  }
  if (!regexcode.test(formValues.code6)){
    errors.code6 = "Dit is geen cijfer!";
  }
  if (!formValues.code1) {
    errors.code1 = 'U moet een cijfer invullen';
  }
  if (!formValues.code2) {
    errors.code2 = 'U moet een cijfer invullen';
  }
  if (!formValues.code3) {
    errors.code3 = 'U moet een cijfer invullen';
  }
  if (!formValues.code4) {
    errors.code4 = 'U moet een cijfer invullen';
  }
  if (!formValues.code5) {
    errors.code5 = 'U moet een cijfer invullen';
  }
  if (!formValues.code6) {
    errors.code6 = 'U moet een cijfer invullen';
  }

  return errors;
}

const mapStateToProps = (state) => {
  return {
    login: state.login
  }
}


export default connect(mapStateToProps)(reduxForm({ form: 'loginCodeForm', validate: validate, enableReinitialize: true})(loginForm));
