import React from 'react';
import ls from 'local-storage';
import { connect } from 'react-redux';
import { setActiveMenu, setDimensions, setPagination  } from '../../actions';
import { fetchValues } from '../../actions/valueActions';
import { fetchCurrentUser } from '../../actions/userActions';
import Header from '../Header';
import Pagination from '../elements/Pagination';
import { Link } from 'react-router-dom';

class ValuesList extends React.Component {

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
    if (this.props.auth !== undefined && this.props.auth.userId !== null) {
      this.props.fetchCurrentUser(this.props.auth.userId);
    }
    this.props.setActiveMenu('3');
    this.props.setPagination('values',this.props.match.params.page,this.props.pages.selectedItemsPerPage);
    this.props.fetchValues();
    ls.set('ll', `/values/list/${this.props.match.params.page}`);
  };

  componentDidUpdate() {
    ls.set('ll', `/values/list/${this.props.match.params.page}`);
  }

  renderOwner(value) {
    if (this.props.currentUser.rights.charAt(6) === '1' && this.props.currentUser.rights.charAt(7) === '1') {
      return ( 
        <div className="right floated content">
          <Link className="ui small primary labeled icon button" to={`/values/edit/${value.ind}`}>
            <i className="pencil alternate icon"></i>Wijzigen
          </Link>
          <Link className="ui small negative labeled icon button" to={`/values/delete/${value.ind}`}>
            <i className="trash alternate outline icon"></i>Verwijderen
          </Link>
        </div>  
      );
    } else if (this.props.currentUser.rights.charAt(6) === '1') {
      return ( 
        <div className="right floated content" >
          <Link className="ui small primary labeled icon button" to={`/values/edit/${value.ind}`}>
            <i className="pencil alternate icon"></i>Wijzigen
          </Link>
        </div>  
      );
    }else if (this.props.currentUser.rights.charAt(7) === '1') {
      return(
        <div className="right floated content">
          <Link className="ui small negative labeled icon button" to={`/values/delete/${value.ind}`}>
            <i className="trash alternate outline icon"></i>Verwijderen
          </Link>
        </div>
      );
    }
    return null;
  }

  renderCreate() {
    if (this.props.currentUser.rights.charAt(4) === '1') {
      if (this.props.auth.isSignedIn) {
        return (
          <div className="ui container">
            <div style={{ textAlign: 'right', zIndex: "110" }}>
              <Link to="/values/new" className="ui right floated primary button">
                Nieuwe Waarde
              </Link>
            </div>  
          </div>
        );
      }
    }
  };

  renderList() {
    if (this.props.values !== undefined){
      const itemsPerPage = this.props.pages.selectedItemsPerPage;
      const page = this.props.pages.activePage;
      const end = (parseInt(page) * parseInt(itemsPerPage) + 1);
      const start = (parseInt(end) - parseInt(itemsPerPage)); 
      return this.props.values.map((val, index) => {
        // if (val.value_id === 0) {
        //   return null;
        // }
        if (index + 1 < start || index + 1 >= end){
          return null;
        }
        const vals = val.topic.charAt(0).toUpperCase() + val.topic.slice(1);
        return(
          <div className="item" key={val.value_id}>
            {this.renderOwner(val)}
            <Link to={`/documents/${val.document_id}`}>
            {val.doc_type}
            </Link>
            <i className="large middle aligned icon file alternate outline" />
            
            <div className="content" key={val.ind}>
              <Link to={`/values/${val.value_id}`} className="header">
                {vals}
              </Link>
              <div className="description">{val.description}</div>
            </div>
          </div>);
      });
    }
  }

  render(){
    return(
      <div 
        style={{ 
          width:this.props.dimensions.width, 
          height: this.props.dimensions.height, 
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,179,165,0.48082983193277307) 100%)'
        }}
        className="ui container"
      >
      <div className="ui container">
      <Header location={this.props.location.pathname} />
      <h2>Waarden</h2>
      <div className="ui celled list">{this.renderList()}</div>
      {this.renderCreate()}
      <Pagination category="values"/>
    </div>
  </div>
    );
  }  
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    currentUser: state.currentUser,
    pages: state.pages,
    dimensions: state.dimensions,
    values: Object.values(state.values)
  };
};

export default connect(mapStateToProps, { setActiveMenu, setDimensions, setPagination, fetchValues, fetchCurrentUser })(ValuesList);