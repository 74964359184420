import React from 'react';
import CookieStatement from '../CookieStatement';
import history from '../history'

const renderCS = () => {
  return(
    <CookieStatement
    onDismiss={() => history.push('/')}
    />
  );
}

export default renderCS;