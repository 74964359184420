import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Header from '../Header';
import DocumentForm from '../documents/DocumentForm';
import Dropdown from '../elements/Dropdown';
import { setActiveMenu, setDimensions } from '../../actions';
import { setTypes, createDocument } from '../../actions/documentActions';

class DocumentCreate extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('2');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  renderDocTypeDropdown = () => {
    const types = [
      {key: 'BL', text: 'BL', value: 'Beleidswaarde'}, 
      {key: 'BP', text: 'BP', value: 'Bestemmingsplan'},
      {key: 'BS', text: 'BS', value: 'Bruidsschat'},
      {key: 'OV', text: 'OV', value: 'Omgevingsverordening'},
      {key: 'VOW', text: 'VOW', value: 'Ontwerpbesluit'},
      {key: 'VR', text: 'VR', value: 'Verordening'}
      
    ];
    return (
      <Dropdown
      label={'Type beleidswaarde'}
      options={types}
      selected={this.props.selections.selectedType}
      onSelectedChange={(setType) => this.changeSelection(setType)}
      image='false'
      />
    );
  }

  changeSelection = (type) => {
    this.props.setTypes(type)
  }

getTypeKey = (type) => {
  switch(type){
    case 'Beleidswaarde':
      return 'BL';
    case 'Bestemmingsplan':
      return 'BP';
    case 'Bruidsschat':
      return 'BS';
    case 'Omgevingsverordening':
      return 'OV';
    case 'Ontwerpbesluit':
      return 'VOW';
    case 'Verordening':
      return 'VR';
    default:
      return 'BL';
  }
}

  onSubmit = (formValues) => {
    formValues = { ...formValues, unique_id: this.props.currentUser.unique_id }
    const type= this.getTypeKey(this.props.selections.selectedType.value);

    let docs = this.props.documents.filter(doc => doc.type.includes(type));
    docs = docs.sort((a,b ) =>{
      return a.type.localeCompare(b.type);
    });
    let x = docs.length;
    let t = '';
    if (docs.length !== 0) {
      t = docs[x-1].type;
      let nr = t.split('-');
      x = parseInt(nr[1])+1;
      if (x < 10) {
        x = String('00' + x )
      } else if (x < 100 ){
        x = String('0' + x );
      }
      t = String(nr[0] + '-' + x);
    } else {
      t = String(type + '-001' );
    }
    
    const cityArr = this.props.cities;
    let city_id = 0;
    cityArr.map((city) => {
      if(this.props.currentUser !== undefined) {
        if (city.key === this.props.currentUser.city_oin) {
          city_id = city.id
        }
      }
      return null;
    });
    formValues = { ...formValues, type: t, city_id: city_id};
    this.props.createDocument(formValues);
  };

  render() {
    const initialValues = _.pick(this.props.document, 'document_id', 'unique_id', 'city_id', 'type', 'title', 'context', 'root_url', 'annual_determination', 'responsible', 'entry_year', 'experation_year', 'pages', 'binding', 'assessment', 'primary_domain', 'secundary_domain', 'theme', 'subtheme');
    return (
      <div 
      style={{ 
        width:this.props.dimensions.width, 
        height: this.props.dimensions.height,  
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,243,165,0.48082983193277307) 100%)'
      }}
      className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h3>Nieuw document maken:</h3>
          <h4 className="ui dividing header">
            <div>
              <div style={{ display: "inline-block" }} className="ui content">
                {this.renderDocTypeDropdown()}
              </div>
            </div>
          </h4>
          <DocumentForm
            document_id= '0'
            initialValues= {initialValues}
            onSubmit={this.onSubmit}
            name='Opslaan' 
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dimensions: state.dimensions,
    document: state.documents[0],
    selections: state.selections,
    currentUser: state.currentUser,
    documents: Object.values(state.documents),
    cities: Object.values(state.cities)
  }
}

export default connect(mapStateToProps, { setActiveMenu, setDimensions, setTypes, createDocument })(DocumentCreate);