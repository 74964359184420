import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { setActiveMenu, setDimensions, setBlackedNumber, fetchCities } from '../../actions';
import { fetchUser } from '../../actions/userActions';
import Header from '../Header';


class UserShow extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('4');
    this.props.fetchUser(this.props.match.params.id);
    this.props.fetchCities();
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  getUser = () => {
    let item = this.props.users.map((user) => {

      if (user.ind === parseInt(this.props.match.params.id)){
        return user;
      }
      return null
    });
    item = item.filter(i => i!== null);
    return item[0];
  }
  getUserName (user) {
    if (user.mname !== null) {
      return (user.fname + ' ' + user.mname + ' ' + user.lname ); 
    }
    return (user.fname + ' ' + user.lname);
  }

  showCity = () => {
    const convertSVG = (svg) => {
      let blob = new Blob([svg], {type: 'image/svg+xml'});
      return URL.createObjectURL(blob);
    }
    const user = this.getUser();
    const oin = user.city_oin;
    const gemeente = Object.values(this.props.cities).map((city) => {
      if (city.key === oin){
        return (<div className="text" key={city.key} >
          <img className='ui avatar image' src={convertSVG(city.city_logo)} alt={city.value} />
          {city.value}
        </div>);
      }
      return null;
    });
    return gemeente;
  }

renderPhoneNumber = () => {
  const user = this.getUser();
    if (this.props.blackedNumber === 'blackednumber'){
    return(
      <div className="ui content">
        <div id="phone" style={{ display: "inline-block" }} >
          {user.blackednumber}
        </div>
      <div style={{ display: "inline-block", marginLeft: "1em" }}>
        <i className="ui icon eye"></i>
      </div>
    </div>
    );
  }
  return(
    <div className="ui content">
      <div id="phone" style={{ display: "inline-block" }} >
        {user.phonenumber}
      </div>
      <div style={{ display: "inline-block", marginLeft: "1em" }}>
        <i className="ui icon eye slash"></i>
      </div>
  </div>
  );
}

  setBlackedNumber = () => {
    this.props.setBlackedNumber(this.props.blackedNumber);
  }

  getBack= () => {
    history.push(`/users/list/${this.props.pages.activePage}`);
  }

  render() {
    const user = this.getUser();

    if(!user) {
      return (
        <div className="ui segment">
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading...</div>
        </div>
      </div>
      );
    }

    return (
      <div 
      style={{ 
        width:this.props.dimensions.width, 
        height: this.props.dimensions.height,  
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(175,244,165,0.48082983193277307) 100%)'
      }}
      className="ui container"
    >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h4 className="ui dividing header" style={{ marginTop: "0"}}>&nbsp;</h4>
          <div>
            <h3>Gebruiker gegevens:</h3>
            <h4 className="ui dividing header"><div className="ui content">Gebruiker #{user.unique_id}</div><div style={{ float:"right" }}>Rol: {user.role}</div></h4>
          </div>
          <div>
            <div style={{ display: "inline-block", marginLeft: "1em", marginTop: "2em" }}>
              <h5>Naam</h5>
              <div className="ui content">
                {this.getUserName(user)}
              </div>
            </div>
            <div style={{ display: "inline-block", marginLeft: "4em", marginTop: "2em" }}>
              <h5>Gebruikersnaam</h5>
              <div className="ui content">
                {user.username}
              </div>
            </div>
            <div style={{ display: "inline-block", marginLeft: "4em", marginTop: "2em" }}>
              <h5>Email</h5>
              <div className="ui content">
                {user.email}
              </div>
            </div>
            <div style={{ display: "inline-block", marginLeft: "4em", marginTop: "2em", cursor: "pointer" }} onClick={this.setBlackedNumber}>
              <h5>Telefoonnummer</h5>
              {this.renderPhoneNumber()}
            </div>
            <div style={{ display: "inline-block", marginLeft: "4em", marginTop: "2em" }}>
              <h5>Gemeente</h5>
              <div className="ui content">
                {this.showCity()}
              </div>
            </div>        
          </div>
          <h4 className="ui dividing header">&nbsp;</h4>
          <div className="ui button primary" onClick={this.getBack}>Terug</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: Object.values(state.users),
    blackedNumber: state.password.blackedNumber,
    cities: state.cities,
    pages: state.pages, 
    dimensions: state.dimensions
  }
}

export default connect(mapStateToProps, { fetchUser, setActiveMenu, setDimensions, setBlackedNumber, fetchCities })(UserShow);