import React from 'react';
import Header from '../Header';

class ValueSearch extends React.Component {
  render() {
    return(
      <div className="ui container">
        <Header location={this.props.location.pathname} />
        <h2>Nieuwe Waarden Zoeken</h2>
      </div>
    );
  }
}

export default ValueSearch;