/* eslint-disable import/no-anonymous-default-export */
import  { 
  FETCH_CURRENT_USER,
  RESET_CURRENT_USER 
} from '../actions/types';

const initialState = 
    {
      id: 0,
      unique_id: 0,
      fname: '',
      mname: '',
      lname:'',
      city_oin:'',
      city_name:'',
      rights:'01000100010001000100',
      role:'user',
      email:'',
      remember_me: 0
    };
//Edit: Object-based approach

export default (state = initialState, action) => {
 
  switch (action.type) {
    case FETCH_CURRENT_USER:
      return { ...state, ...action.payload };
    case RESET_CURRENT_USER:
      return initialState;
    default:
      return state;
  }
};