import React from 'react';
import ReactDOM from 'react-dom';


const Modal = (props) => {
  let style = {};
  props.noBackgroundImage ? style={ opacity: '0.90' } : style={ backgroundImage: `url(${process.env.PUBLIC_URL}/images/background4.jpg)`, opacity: '0.95' }
  return ReactDOM.createPortal(
    <div onClick={props.onDismiss}  style={style} className="ui dimmer modals visible active">
      <div onClick={(e) => e.stopPropagation()} className="ui standard modal visible active">
        <div className="header">
          {props.title}
        </div>
        <div className="content">
          {props.content}
        </div>
        <div className="actions">
          {props.actions}
        </div>

      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export default Modal;
