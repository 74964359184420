import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import selectionsReducer from "./selectionsReducer";
import userReducer from "./userReducer";
import deletedUserReducer from "./deletedUserReducer";
import passwordReducer from "./passwordReducer";
import citiesReducer from "./citiesReducer";
import currentUserReducer from "./currentUserReducer";
import pageReducer from "./pageReducer";
import loginReducer from "./loginReducer";
import dimensionReducer from "./dimensionReducer";
import documentReducer from "./documentReducer";
import deletedDocumentReducer from "./deletedDocumentReducer";
import domainReducer from "./domainReducer";
import themesReducer from "./themesReducer";
import multipleSelectBoxValuesReducer from "./multipleSelectBoxValuesReducer";
import valuesReducer from "./valuesReducer";
import deletedValueReducer from "./deletedValueReducer";
import profilesReducer from "./profilesReducer";
import mailReducer from "./mailReducer";
import urlReducer from "./urlReducer";
import coupledValueReducer from "./coupledValueReducer";
import searchReducer from "./searchReducer";

export default combineReducers({
	auth: authReducer,
	form: formReducer,
	selections: selectionsReducer,
	users: userReducer,
	deletedUser: deletedUserReducer,
	password: passwordReducer,
	cities: citiesReducer,
	currentUser: currentUserReducer,
	pages: pageReducer,
	login: loginReducer,
	dimensions: dimensionReducer,
	documents: documentReducer,
	deletedDocument: deletedDocumentReducer,
	domains: domainReducer,
	themes: themesReducer,
	multipleSelectBoxValues: multipleSelectBoxValuesReducer,
	values: valuesReducer,
	deletedValue: deletedValueReducer,
	profiles: profilesReducer,
	mail: mailReducer,
	urls: urlReducer,
	notCoupledValue: coupledValueReducer,
	search: searchReducer,
});
