import React from 'react';
import Modal from '../../modal';
import history from '../../history';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetValueOnDocument, setDocumentChoice } from '../../actions/documentActions';

class DashboardValueDelete extends React.Component {

  getDocIdFromValId = (valId) => {
     if (this.props.deletedValue[valId].document_id !== undefined || this.props.deletedValue[valId].document_id !== null) {
      return this.props.deletedValue[valId].document_id;
     }
     return null;
  }

  renderActions = () => {
    const id = this.props.match.params.id;
    const docId = this.getDocIdFromValId(id);
    const action ={valId: id, docId: docId, actionSelected: this.props.selected, unique_id: this.props.currentUser.unique_id, activepage: this.props.pages.activePage}
    return (
      <React.Fragment>
        <button  
          onClick={() => this.props.resetValueOnDocument(action)} 
          className="ui primary button">
          Versturen
        </button>
        <Link to={`/beheer/waarden/list/${this.props.pages.activePage}`} className="ui button" style={{ float: 'right' }} >Terug</Link>
      </React.Fragment>
    );
  }

  renderWithUpperCase = (val) => {
    const withUpperCase = val.charAt(0).toUpperCase() + val.slice(1);
    return withUpperCase;
  }

  renderTitle() {
    return (
      <React.Fragment>
        Waarde is niet gekoppeld!
      </React.Fragment>
    );
  }

  setChecked = (e) => {
    this.props.setDocumentChoice(e.target.value);
  }

  renderContent = () => {
    let delText = '';
   if (this.setChoices()) {
        delText = 
        <React.Fragment>
          <p>De waarde is niet gekoppeld aan een document. Wilt u de waarde aan een document koppelen? Er zijn 3 mogelijkheden:</p>
          <div className="ui form">
            <div className="grouped fields">
              <div className="field" key='1'>
                <div className="ui radio checkbox">
                <input type='radio' id='delChoice1' name='valDel' value='1' onChange={e => this.setChecked(e)} />
                <label>Koppelen aan een nieuw document</label>
                </div>
              </div>
              <div className="field" key='2'>
                <div className="ui radio checkbox">
                <input type='radio' id='delChoice2' name='valDel' value='2' onChange={e => this.setChecked(e)}/>
                <label>Koppelen aan een ander bestaand document</label>
                </div>
              </div>
              <div className="field" key='3'>
                <div className="ui radio checkbox">
                <input type='radio' id='delChoice3' name='valDel' value='3' onChange={e => this.setChecked(e)}/>
                <label for = 'delChoice3'>Het bestaande document herstellen</label>
                </div>
              </div>
            </div>
          </div>
          <p><br />Selecteer uw keuze en klik op versturen.</p>
        </React.Fragment>
      } else {
        delText = 
        <React.Fragment>
          De waarde is niet gekoppeld aan een document. Wilt u de waarde aan een document koppelen? Er zijn 2 mogelijkheden:
          <div className="ui form">
            <div className="grouped fields">
              <div className="field" key='1'>
                <div className="ui radio checkbox">
                <input type='radio' id='delChoice1' name='valDel' value='1' onChange={e => this.setChecked(e)}/>
                <label>Koppelen aan een nieuw document</label>
                </div>
              </div>
              <div className="field" key='2'>
                <div className="ui radio checkbox">
                <input type='radio' id='delChoice2' name='valDel' value='2' onChange={e => this.setChecked(e) }/>
                <label for = 'delChoice3'>Koppelen aan een ander bestaand document</label>
                </div>
              </div>
            </div>
          </div>
          Selecteer uw keuze en klik op versturen.
        </React.Fragment>      
      }
      return delText;
    }

  render(){
    return (
          <Modal 
            title={this.renderTitle()}
            content={this.renderContent()}
            actions={this.renderActions()}
            onDismiss={() => history.push(`/beheer/waarden/list/${this.props.pages.activePage}`)}
          />
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pages: state.pages,
    deletedDocument: state.deletedDocument,
    deletedValue: state.deletedValue,
    currentUser: state.currentUser,
    selected: state.selections.checkedDocumentAction,
    nodocs: state.valArrDelNoDocs
  }
}

export default connect(mapStateToProps, { resetValueOnDocument, setDocumentChoice })(DashboardValueDelete);