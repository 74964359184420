import users from '../apis/users';
import mail from '../apis/mail';
import history from '../history';
import {snowflakeGenerator} from 'snowflake-id-js';
import { encryptData, decryptData } from '../secure/keys';

import { 
  FETCH_USERS,
  FETCH_USER,
  EDIT_USER,
  UPDATE_USER_RIGHTS,
  SET_NEW_USER,
  FETCH_CURRENT_USER,
  RESET_CURRENT_USER,
  UPDATE_NEW_USER,
  DELETE_USER,
  RESET_USERS,
  FETCH_DELETED_USERS,
  SET_USER_ARRAY_DELETE,
  SEND_MAIL
} from './types';

const debugmode = process.env.REACT_APP_DEBUGMODE;

export const fetchUsers = () => async dispatch => {
  const response = await users.get(`/read_all.php?debugmode=${debugmode}`);

  const responseData = decryptData(response.data,1);
  dispatch({ type: FETCH_USERS, payload: responseData })
 };

 export const fetchUser = (ind) => async dispatch => {
  const response = await users.get(`/read_one.php?ind=${ind}&debugmode=${debugmode}`);

  let responseData = decryptData(response.data,0);
  dispatch({ type: FETCH_USER, payload: JSON.parse(responseData)})
};

export const editUser = (formValues, activePage) => async dispatch => {
  formValues = formValues = { ...formValues, debugmode: debugmode };
  const data = encryptData(formValues);
  let responseMail=null;
  if (formValues.sendEmail) {
    responseMail = await mail.post('/sendmail.php', data);
  }
  const response = await users.post(`/update.php?id=${formValues.id}`, data);
  const responseData = decryptData(response.data);
  dispatch({ type: EDIT_USER, payload: JSON.parse(responseData) });
  if (formValues.sendEmail){
    dispatch({ type: SEND_MAIL, payload: responseMail.data});
  }
  history.push(`/users/list/${activePage}`)
};

export const updateUserRights = (user, rights) => {
  user = {...user, rights: rights}
  return {
    type: UPDATE_USER_RIGHTS,
    payload: {user}
  }
};

function makeId(){
  const generator = snowflakeGenerator(512);
  var idx = generator.next().value;
  return idx;
}

export const setNewUser = () => {
  const user =  {id: 0, ind: 0, unique_id: makeId(), fname: '', mname: '', lname:'', city_oin:'0345', city_name:'Veenendaal', rights:'01000100010001000100', role:'Gebruiker', email:'', phonenumber:'', blackednumber:'(0)6 ********', username:'', password:''}
  return {
    type: SET_NEW_USER,
    payload: user
  }
};

export const updateNewUser = (user, items) => {
  user = { ...user, ...items };
  return {
    type: UPDATE_NEW_USER,
    payload: {user}
  }
}

export const createUser = (formValues, activePage) => async dispatch => {
  formValues = formValues = { ...formValues, debugmode: debugmode };
  const data = encryptData(formValues);
  const unique_id = formValues.unique_id;
  let responseMail = null;
  if (formValues.sendEmail) {
    responseMail = await mail.post('/sendmail.php', data);
  }


  const response = await users.post(`/create.php?key=${unique_id}`, data);
  const responseData = decryptData(response.data,1);

  dispatch({ type: RESET_USERS, payload: null })
  dispatch({ type: FETCH_USERS, payload: responseData});
  if (formValues.sendEmail){
    dispatch({ type: SEND_MAIL, payload: responseMail.data});
  }
  history.push(`/users/list/${activePage}`);
};

export const fetchCurrentUser = (unique_id) => async dispatch => {
  const response = await users.get(`/read_one.php?unique_id=${unique_id}&debugmode=${debugmode}`);
  
  const responseData = decryptData(response.data, 1);
  dispatch({ type: FETCH_CURRENT_USER, payload: responseData })
};

export const resetCurrentUser = () => dispatch => {
  dispatch({ type: RESET_CURRENT_USER, payload: null })
}

export const deleteUser = (id, currentuser, activePage) => async dispatch => {
  const data = { id:id, unique_id:currentuser, debugmode: debugmode, no_arr: true };
  const response = await users.post(`/delete.php?id=${id}&debugmode=${debugmode}`, data);
  
  response.data = response.data.split('-');
  const idx =JSON.parse(response.data[0]);
  const responseData = decryptData(JSON.parse(response.data[1]),1);
  
  dispatch({ type: DELETE_USER, payload: idx.ind });
  dispatch({ type: RESET_USERS, payload: null })
  dispatch({ type: FETCH_USERS, payload: responseData});
  history.push(`/users/list/${activePage}`);
 };

export const setCookiesAccepted = (uid) => async dispatch => {
  const data = encryptData({unique_id: uid, cookies_accepted: 1, debugmode: debugmode});
  const response = await users.post(`/update.php?uid=${uid}`, data);
  
  const responseData = decryptData(response.data, 1);
  dispatch({ type: FETCH_CURRENT_USER, payload: responseData })
}

export const newAccount = (formValues) => async dispatch => {
  const unique_id = makeId();
  formValues = { ...formValues, unique_id: unique_id, debugmode: debugmode };
  const data = encryptData(formValues);
  await users.post(`create.php`, data);
}

export const getDeletedUsers = () => async dispatch => {
  const response = await users.get(`/read_deleted.php?debugmode=${debugmode}`);

  if (response.status === 200){
    const responseData = decryptData(response.data, 1);
    dispatch({ type: FETCH_DELETED_USERS, payload: responseData });
  } else {
    dispatch({ type: FETCH_DELETED_USERS, payload: null });
  }
  dispatch({ type: SET_USER_ARRAY_DELETE, payload: [] });
  
};

export const setArrDelete = (arr) => {
  return {
    type: SET_USER_ARRAY_DELETE,
    payload: arr
  }
};

export const resetUser = (arr) => async dispatch => {
  const data = encryptData({ arr: arr, debugmode: debugmode });
  const response = await users.post(`/update.php`, data);

  if (response.status === 200){
    const responseData = decryptData(response.data, 1);
    dispatch({ type: FETCH_DELETED_USERS, payload: responseData });
  } else {
    dispatch({ type: FETCH_DELETED_USERS, payload: null });
  }
  dispatch(fetchUsers());
  dispatch({ type: SET_USER_ARRAY_DELETE, payload: [] });
  history.push(`/beheer/dashboard`);
}

export const deleteUserFinal = (arr) => async dispatch => {
  const data = { arr: arr, debugmode: debugmode, no_arr: false };
  const response = await users.post(`/delete.php`, data);

  if (response.status === 200){
    const responseData = decryptData(response.data, 1);
    dispatch({ type: FETCH_DELETED_USERS, payload: responseData });
  } else {
    dispatch({ type: FETCH_DELETED_USERS, payload: null });
  }
  dispatch({ type: SET_USER_ARRAY_DELETE, payload: [] });
  history.push(`/beheer/dashboard`);
}