import React from 'react';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import history from '../history';
import Dropdown from '../components/elements/Dropdown';
import Copyright from '../components/Copyright';
import { setSelectedCity, fetchCities } from '../actions';

class NewAccountForm extends React.Component {
  state = { hideLabel: true };

  componentDidMount() {
    this.props.fetchCities();
    const cityArr = Object.values(this.props.cities);
    cityArr.map((city) => {
      if(this.props.initialValues !== undefined) {
        if (city.key === this.props.initialValues.city_oin) {
          this.props.setSelectedCity(city);
        }
      }
      return null;
    })
  };
  onSubmit = (formValues) => {
    const values = { ...formValues, city_name: this.props.selectedCity.value, city_oin: this.props.selectedCity.key};
    this.props.onSubmit(values);
  }
  
  getBack= () => {
    history.push(`/`);
  }

  renderError ({ error, touched }){
    if(touched && error) {
      if (this.props.selectedCity.value === undefined) this.setState({hideLabel: false})
      return(
          <label className="ui below red basic label error">{error}</label>
      );
    }
  };

  renderInput= ({ input, label, meta, type, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    if (id === "phone" ){
      return (
        <React.Fragment>
          <label>
            {label}
          </label>
          <div className= "ui fluid labeled input">
            <div className='ui label'>06</div>
              <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
          </div>
        {this.renderError(meta)}
      </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className={className}>
          <label>
            {label}
            <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
          </label>
        </div>
        {this.renderError(meta)}
      </React.Fragment>
    );
  };

  changeSelection = (city) => {
    if (this.props.selectedCity.value !== undefined) {
      this.setState({hideLabel: true});
    } else {
      this.setState({hideLabel: false});
    }
    this.props.setSelectedCity(city);
    this.setState({hideLabel: true});
  }  

  setDropDownCities = () => {
    if (!_.isEmpty(this.props.cities)){

      return (
        <Dropdown
          name='cities' 
          label={'Selecteer een gemeente:'} 
          options={Object.values(this.props.cities)} 
          selected={this.props.selectedCity} 
          onSelectedChange={(setSelectedCity) => this.changeSelection(setSelectedCity)}
          image='true'
          autoFocus = 'true'
        />
      );
    }
    return null;
  }

  setLoginBox = () => {
    const w = Math.ceil(this.props.width/2);
    const h = Math.ceil(this.props.height/2);
    const t = Math.ceil(h/2);
    const l = Math.ceil(w/2);

    return (
      <div id='login' style={{ width: w, height: h, left:l, top:t, border:'2px solid #85BE1A', borderRadius:'6px', backgroundColor:'#FFFFEE', opacity:'1'  }}>
        <div style={{ marginTop:'2px' }} >
          <img src={process.env.PUBLIC_URL + "/images/BWG-online.png"} alt='BWG-online' style={{ float:'left', width: '15%', height: 'auto'}} />
          <h1 className='ui header' style={{ color:'#4EA619', textAlign:'center'}}>
            Login bij de beleidswaarden generator online!
          </h1>
        </div>
        <form name='newLoginForm' id='newLoginForm' className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)} style={{ width:'90%' }}>
        <div className="field"  style={{ position: 'relative', height: '50px', top: `${this.state.hidelabel ? '0' : '-30px'}` }}>
          {this.setDropDownCities()}
          <label className="ui below red basic label error" style={{ display:(this.state.hideLabel ? 'none' : 'block'), position: 'relative', left: '200px', top: '-32px', width: '215px', marginLeft: "6px", height: '30px'}}>
            U moet een gemeente selecteren
          </label>
        </div>
        <div className="field">
          <div className="three fields">
            <div className="six wide field">
              <Field name="fname" id="fname" label="Voornaam" type="text" component={this.renderInput} />
            </div>
            <div className="three wide field">
              <Field name="mname" id="mname" label="Tussenvoegsel" type="text" component={this.renderInput} />
            </div>
            <div className="seven wide field">
              <Field name="lname" id="lname" label="Achternaam" type="text" component={this.renderInput} />
            </div>
          </div>
        </div>
        <div className="field">
          <div className="fields">
            <div className="sixteen wide field">
              <Field name="email" label="Email" id="email" type="text" component={this.renderInput} />
            </div>
          </div>
        </div>
        <div className="field">
          <div className="fields">
            <div className="sixteen wide field">              
              <Field id ="phone" name="phone" type="text" label="Mobiel nummer" component={this.renderInput} />
            </div>
          </div>
        </div>
        <button style={{ position: 'relative', top:'-5px'}} className="ui  olive button" type="submit">Verstuur</button>
        <button style={{ position: 'relative', top:'-5px' }} className="ui  grey button" onClick={this.getBack}>Terug</button>
        <Copyright color='olive' size='8px'/>
      </form>
      </div>
    );
  }

  render() {
    return(
      <div>
        {this.setLoginBox()}
      </div>
    );
  }
}


const validate = (formValues) => {
  const errors = {};
  var regexemail = new RegExp(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/);
  var regexcode = new RegExp(/^[0-9\b]+$/);
  // eslint-disable-next-line no-useless-escape
  var regexname = new RegExp(/^[a-z \.\-A-Z]+$/);
  
  if (!regexemail.test(formValues.email)){
    errors.email = "Emailadres klopt niet";
  }

  if (!formValues.email) {
    errors.email = 'U dient een emailadres in te vullen';
  }

  if (!regexcode.test(formValues.phone)){
    errors.phone = "Mobiel telefoonnummer klopt niet";
  }
  if (!formValues.phone) {
    errors.phone = 'U dient een correct mobielnummer in te vullen';
  }

  if (formValues.phone !== undefined){
    if (formValues.phone.length < 7 || formValues.phone.length > 10){
      errors.phone = 'U dient een 8 cijferig mobielnummer in te vullen';
    }
  }

  if (!formValues.fname) {
    errors.fname = 'U dient een voornaam in te vullen';
  }

  if (formValues.fname !== undefined && formValues.fname !== null){
    if (!formValues.fname.match(regexname)) {
      errors.fname = 'Alleen letters gebruiken.';
    }
  }

  if (formValues.mname !== undefined && formValues.mname !== ''){
    if(!formValues.mname.match(regexname)){
       errors.mname = "Alleen letters!";
    }        
  }

  if (!formValues.lname) {
    errors.lname = 'U dient een achternaam in te vullen';
  }

  if (formValues.lname !== undefined){
    if (!formValues.lname.match(regexname)) {
      errors.lname = 'Alleen letters gebruiken';
    }
  }

  return errors;
}

const mapStateToProps = (state) => {
  return {
    cities: state.cities,
    selectedCity: state.selections.selectedCity
  }
}

export default connect(mapStateToProps, { fetchCities, setSelectedCity })(reduxForm({ form: 'newLoginForm',validate: validate,  enableReinitialize: true})(NewAccountForm));