import React from 'react';
import { connect } from 'react-redux';
import '../css/login.css';
import history from '../history';
import { sendWWEmail, sendCode, setDimensions } from '../actions';
import WachtWoordForm from '../login/WachtWoordForm';
import Copyright from '../components/Copyright';


class Login extends React.Component {
  backgroundImage = `url(${process.env.PUBLIC_URL}/images/background3.jpg)`;

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };

  onSubmit = (formValues) => {
      this.props.sendWWEmail(formValues);
      history.push({pathname: '/', ...formValues });
  }

  renderForm = () => {
    return (
      <div style={{ width:this.props.dimensions.width, height: this.props.dimensions.height, backgroundImage: this.backgroundImage, opacity:'0,9' }}>
        <WachtWoordForm
          width={this.props.dimensions.width}
          height={this.props.dimensions.height}
          onSubmit={this.onSubmit}
          initialValues={{ email: this.props.location.email }}
          err={this.props.login.errors}
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderForm()}
        <Copyright color='white' />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    dimensions: state.dimensions,
    login: state.login,
    auth: state.auth
  }
}

export default connect(mapStateToProps, { sendWWEmail, sendCode, setDimensions })(Login);