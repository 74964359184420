import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import Header from '../Header';
import Pagination from '../elements/Pagination';
import { getDeletedDocuments, setArrDelete, resetDocument, deleteDocumentFinal } from '../../actions/documentActions';
import { setActiveMenu, setPagination } from '../../actions';
import ls from 'local-storage';

class DashboardDocs extends React.Component {

  componentDidMount(){
    this.props.getDeletedDocuments();
    this.props.setActiveMenu('5');
  };

  componentDidUpdate() {
    this.props.setPagination(`/beheer/documenten/list/${this.props.pages.activePage}`,this.props.match.params.page,this.props.pages.selectedItemsPerPage);
    ls.set('ll', `/beheer/documenten/list/${this.props.pages.activePage}`);
  }

  getBack= () => {
    history.push(`/beheer/dashboard/`);
  }

  renderButtons = () => {
    let buttons = '';
    if (this.props.documents.length  >= 2) {
      buttons = 
      <React.Fragment>
        <div className="ui small primary labeled icon button" onClick={(e) => {this.props.resetDocument(this.props.arrdelete)}}>
          <i className="undo icon"></i>Terug zetten
        </div>
        <div className="ui small negative labeled icon button" onClick={(e) => {this.props.deleteDocumentFinal(this.props.arrdelete)}}>
          <i className="trash alternate outline icon"></i>Verwijderen
        </div> 
      </React.Fragment>
    }

    return (
      <div className="ui container">
        <div style={{ textAlign: 'right', zIndex:'200', cursor: 'pointer' }}>
          <div className="right floated content">
            {buttons}       
            <div  className="right floated content" style={{ display: "inline-block", float: "right" }}>
              <div className="ui button" style={{ marginLeft: "1em" }} onClick={this.getBack}>Terug</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
 
  renderCheckBox = (id) => {
    return (
      <div className="right floated content" >
        <div className="ui checkbox" style={{ position: 'relative', top: '8px' }} >
          <input type="checkbox" onChange={(e) => {this.changeCheckbox(id)}} />
          <label></label>
        </div>
      </div>
    );
  }

  changeCheckbox = (id) => {
    let arrDelete = this.props.arrdelete;
    if (arrDelete.includes(id)) {
      let index = arrDelete.indexOf(id);
      if (index > -1){
        arrDelete.splice(index, 1);
      } 
    } else {
      arrDelete.push(id);
    }
    this.props.setArrDelete(arrDelete);
  }

  renderList() {
    const itemsPerPage = this.props.pages.selectedItemsPerPage;
    const page = 1;
    const end = (parseInt(page) * parseInt(itemsPerPage) + 1);
    const start = (parseInt(end) - parseInt(itemsPerPage));
     if (this.props.documents.length  < 2 ){
       return (
         <div className="item">
           Geen verwijderde documenten gevonden
         </div>
       )
     }
 
    return this.props.documents

   // eslint-disable-next-line array-callback-return
   .map((doc, index) => {
     if (doc.id !== 0) {
       if (index >= start && index < end){
         return (
           <div className="item" key={doc.document_id}>
           {this.renderCheckBox(doc.document_id)}
             <i className="large middle aligned icon file alternate outline" />
             <div className="content">
               <div className="header" style={{ color: '#2185D0'}}>
                 {doc.document_id}: {doc.title}
               </div>
               <div className="description">
                 {doc.type}
               </div>
             </div>
           </div>
         );
       }
     }
   });
 }

  render() {
    return(
      <div className="ui container">
        <Header location={this.props.location.pathname} />
        <h2>Dashboard Documenten</h2>
        <form>
          <div className="ui celled list">{this.renderList()}</div>
          {this.renderButtons()}
        </form>
        <Pagination category="beheer/documenten"/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documents: Object.values(state.deletedDocument),
    pages: state.pages,
    arrdelete: state.selections.arrDocumentDelete
  }
}

export default connect(mapStateToProps, { getDeletedDocuments, setArrDelete, resetDocument, deleteDocumentFinal, setActiveMenu, setPagination })(DashboardDocs);