import React from 'react';
import  { Link } from 'react-router-dom';

class Profiles extends React.Component {

setProfile = () => {
  if (this.props.profile.description === '') {
    return (
      <div className="ui segment">
        <div className="ui active inverted dimmer">
          <div className="ui text loader">
            Loading...
          </div>
        </div>

      </div>
    );
  }
  return this.props.profile.description;
};

setProfileThemes = () => {
  let themes = '';
  const profileThemes = this.props.profile.themes;
  for(let i=0; i< profileThemes.length; i++) {
    if (i !== profileThemes.length-1){
      themes = themes + profileThemes[i] + ', '; 
    } else {
      themes = themes + profileThemes[i];
    }
  }
  return themes;
};

getPathname = () => {
  let path = { pathname: `${process.env.PUBLIC_URL}/analyse/page/${this.props.profile.profile_id}`, profile: { ...this.props.profile, color: this.props.color}};  
  if (process.env.NODE_ENV === 'development'){
    path ={ pathname: `/analyse/page/${this.props.profile.profile_id}`, profile: { ...this.props.profile, color: this.props.color}};
  }
  return path;
}

  render(){
    return(
      <Link to={this.getPathname()} className={`${this.props.color} card`} >
        <div className="content">
        
          <img className="right floated mini ui image" src={`${process.env.PUBLIC_URL}/images/icons/${this.props.icon}`} alt={this.props.profile.profile_name} />
          <div className="header" style={{ color: this.props.color }}>
            {this.props.profile.profile_name}
          </div>
          <div className="meta">
            <i>{this.setProfileThemes()}</i>
          </div>
          <div className="description">
            {this.setProfile()}
          </div>
        </div>
        <div className="extra content">
          <div className="ui button" style={{width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: 'transparent' }}>
            <div className={`ui basic ${this.props.color} button`}>Bekijk Analyse</div>
          </div>
        </div>
      </Link>
    );
  }
};

export default Profiles;

