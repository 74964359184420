/* eslint-disable no-unused-vars */
import React from 'react';
import Modal from '../../modal';
import history from '../../history';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchDocument, deleteDocument } from '../../actions/documentActions';

class DocumentDelete extends React.Component {

  renderActions() {
    const id = this.props.match.params.id;
    return (
      <React.Fragment>
        <button  
          onClick={() => this.props.deleteDocument(id, this.props.currentUser.unique_id, this.props.pages.activePage)} 
          className="ui negative button">
          Verwijderen
        </button>
        <Link to={`/documents/list/${this.props.pages.activePage}`} className="ui button">Terug</Link>
      </React.Fragment>
    );
  }

  getWaarden = () => {
    const values = Object.values(this.props.values);
    const vals = values.filter(type => type.doc_type === this.props.document.type);
    const count = vals.length;
    return count;
  }

  renderTitle() {
    if (!this.props.document){
      return 'Verwijder Document';
    }
    return `Verwijder "${this.props.document.title}", type ${this.props.document.type}?` 
  }

  renderContent() {
    let text ='';
    if (this.props.document !== undefined){
      let year = this.props.document.experation_year;

      if (year === 0 || year === '0') {
        text = `geen experatie jaar`
      } else {
        text = `als experatie jaar ${year}`
      }
    }
    if (!this.props.document){
      return 'Weet je zeker dat je dit document en alle gekoppelde waarden wilt verwijderen?';
    }
    return `Dit document heeft ${text} en heeft ${this.getWaarden()} gekoppelde waarden. Weet je het zeker?`;
  }

  render(){
    return (
          <Modal 
            title={this.renderTitle()}
            content={this.renderContent()}
            actions={this.renderActions()}
            onDismiss={() => history.push(`/streams/list/${this.props.pages.activePage}`)}
          />
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pages: state.pages,
    document: state.documents[ownProps.match.params.id],
    currentUser: state.currentUser,
    values: state.values
  }
}

export default connect(mapStateToProps, { fetchDocument, deleteDocument })(DocumentDelete);