
import React from 'react';
import ls from 'local-storage';
import { connect } from 'react-redux';
import { signIn, signOut } from '../actions';
import { resetCurrentUser } from '../actions/userActions';
import { encryptData } from '../secure/keys';
import history from '../history';

class Auth extends React.Component {

  // onAuthChange = (isSignedIn) => {
  //   if (isSignedIn) {
  //     this.props.signIn(this.auth.currentUser);
  //     this.signOutClick();
  //   } else {
  //     this.signOutClick();
  //   }
  // };

  onSignOutClick = () => {
    const currentUser = this.props.currentUser;
    const rm = { unique_id: currentUser.unique_id, remember_me: currentUser.remember_me, email: currentUser.email, password: currentUser.password, cookies_accepted: currentUser.cookies_accepted };
    ls.set('rm', encryptData(rm));
    ls.remove('ll');
    ls.remove('cu');
    this.props.resetCurrentUser();
    this.props.signOut();
    history.push('/');
  };

  renderAuthButton(){
    if (process.env.NODE_ENV === 'development' || this.props.auth.isSignedIn){
      return (
        <button onClick={this.onSignOutClick} className="ui red button">
          Uitloggen
        </button>
      );
    } else
      return null;
  }

  render(){
    return (
      <div>{this.renderAuthButton()}</div>
    );    
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    currentUser: state.currentUser
  }
};

export default connect(mapStateToProps, { signIn, signOut, resetCurrentUser })(Auth);
