import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Auth from "./Auth";
import Copyright from "./Copyright";
import history from "../history";
import { setActiveMenu, searchAll } from "../actions";
import { fetchCurrentUser } from "../actions/userActions";

class Header extends React.Component {
	componentDidMount() {
		if (this.props.auth !== undefined && this.props.auth.userId !== null) {
			this.props.fetchCurrentUser(this.props.auth.userId);
		}
	}

	setMenu = (e) => {
		this.props.setActiveMenu(e.target.id);
	};

	renderMenu = () => {
		const menus = ["analyses", "documents", "values", "users", "beheer"];
		let menu1 = {};
		let menu2 = {};
		let menu3 = {};
		let menu4 = {};
		let menu5 = {};
		let result = [];
		result = menus.map((menu) => {
			if (menu === "analyses") {
				menu1 = (
					<Link
						key={menu}
						id="1"
						to={`/analyse`}
						className={`teal item ${
							this.props.activeMenu === "1" ? "active" : ""
						}`}
						onClick={this.setMenu}>
						Analyses
					</Link>
				);
				return menu1;
			}
			if (
				menu === "documents" &&
				this.props.currentUser.rights.charAt(1) === "1"
			) {
				menu2 = (
					<Link
						key={menu}
						id="2"
						to={"/documents/list/1"}
						className={`yellow item ${
							this.props.activeMenu === "2" ? "active" : ""
						}`}
						onClick={this.setMenu}>
						Documenten
					</Link>
				);
				return menu2;
			}
			if (
				menu === "values" &&
				this.props.currentUser.rights.charAt(5) === "1"
			) {
				menu3 = (
					<Link
						key={menu}
						id="3"
						to={"/values/list/1"}
						className={`purple item ${
							this.props.activeMenu === "3" ? "active" : ""
						}`}
						onClick={this.setMenu}>
						Waarden
					</Link>
				);
				return menu3;
			}
			if (
				menu === "users" &&
				this.props.currentUser.rights.charAt(17) === "1"
			) {
				menu4 = (
					<Link
						key={menu}
						id="4"
						to={"/users/list/1"}
						className={`green item ${
							this.props.activeMenu === "4" ? "active" : ""
						}`}
						onClick={this.setMenu}>
						Gebruikers
					</Link>
				);
				return menu4;
			}
			if (
				menu === "beheer" &&
				this.props.currentUser.rights.charAt(13) === "1"
			) {
				menu5 = (
					<Link
						key={menu}
						id="5"
						to={"/beheer/dashboard"}
						className={`grey item ${
							this.props.activeMenu === "5" ? "active" : ""
						}`}
						onClick={this.setMenu}>
						Beheer
					</Link>
				);
				return menu5;
			}

			// if (menu === 'beheer' && this.props.currentUser.rights.charAt(13) === '1') {
			//   menu5 = <div className="ui dropdown item" key={menu}>
			//             Beheer
			//             <i className="dropdown icon"></i>
			//             <div className="menu">
			//               <Link className={`grey item ${this.props.activeMenu === '5a' ? 'active' : ''}`} onClick={this.setMenu} to={'/users/list/deleted/all'} id='5a' key='5a'>Gebruikers</Link>
			//               <Link className={`grey item ${this.props.activeMenu === '5b' ? 'active' : ''}`} onClick={this.setMenu} to={'/documents/deleted/all'} id='5b' key='5b'>Documenten</Link>
			//               <Link className={`grey item ${this.props.activeMenu === '5c' ? 'active' : ''}`} onClick={this.setMenu} to={'/values/deleted/all'} id='5c' key='5c'>Waarden</Link>
			//               <Link className={`grey item ${this.props.activeMenu === '5d' ? 'active' : ''}`} onClick={this.setMenu} to={'/new/documents/all'} id='5d' key='5d'>Nieuwe Documenten</Link>
			//             </div>
			//           </div>
			//   return menu5;
			// }
			return null;
		});

		return (
			<div className="ui secondary menu">
				{result}
				<div className="right menu">
					<div className="item">
						<div className="ui icon input">
							<input id="search" type="text" placeholder="Zoeken..." />
							<i className="search link icon" onClick={this.searchTerm}></i>
						</div>
					</div>
					<div className="ui item">
						<Auth />
					</div>
				</div>
			</div>
		);
	};

	searchTerm = () => {
		const term = document.getElementById("search").value;
		if (term.length >= 3) {
			document.getElementById("search").value = "";
			//console.log(term);
			this.props.searchAll(term.toLowerCase());
			history.push("/search");
		}
	};

	render() {
		let username = "";
		if (this.props.currentUser.mname === "") {
			username =
				this.props.currentUser.fname + " " + this.props.currentUser.lname;
		} else {
			username =
				this.props.currentUser.fname +
				" " +
				this.props.currentUser.mname +
				" " +
				this.props.currentUser.lname;
		}
		return (
			<div
				className="ui placeholder segment"
				style={{
					boxShadow: "none",
					maxHeight: "135px !important",
					backgroundColor: "transparent",
					border: "0",
					padding: "0",
					minHeight: "0",
				}}>
				<div className="inline">
					<img
						src={process.env.PUBLIC_URL + "/images/BWG-online_transparant.png"}
						alt="BWG-online"
						style={{ float: "left", width: "15%", height: "auto" }}
					/>
					<h3
						style={{
							margin: "0px !important",
							color: "#4EA619",
							textAlign: "center",
						}}>
						Welkom bij de beleidswaarden generator online!
					</h3>
					<Copyright color="#CCCCCC" currentUser={username} />
				</div>
				{this.renderMenu()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		activeMenu: state.selections.activeMenu,
		activePage: state.pages.activePage,
		currentUser: state.currentUser,
		auth: state.auth,
	};
};

export default connect(mapStateToProps, {
	setActiveMenu,
	fetchCurrentUser,
	searchAll,
})(Header);
