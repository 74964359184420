import axios from 'axios';

let baseURL = 'http://restapi.bwg-online.nl/documents';

if (process.env.REACT_APP_DEBUGMODE === "0"){
  baseURL = 'https://www.bwg-online.nl/restapi/documents/';
}

export default axios.create({
  baseURL: baseURL
});