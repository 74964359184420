import React from "react";
import { connect } from "react-redux";
import history from "../history";
import ls from "local-storage";
import "../css/login.css";
import { decryptData } from "../secure/keys";
import { fetchLogin, sendCode, setDimensions, resetPassword } from "../actions";
import LoginForm from "./loginForm_old";
import LoginCodeForm from "./loginCodeForm";
import NewWachtWoordForm from "./NewWachtWoordForm";
import CookieScreenOK from "../components/CookieScreenOK";

class Login extends React.Component {
	backgroundImage = `url(${process.env.PUBLIC_URL}/images/background3.jpg)`;

	updateDimensions = () => {
		this.props.setDimensions({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
		if (this.props.auth.isSignedIn && this.props.auth.userId !== null) {
			const path = ls.get("ll");
			history.push(path);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	componentDidUpdate() {
		if (this.props.auth.isSignedIn && this.props.auth.userId !== null) {
			const path = ls.get("ll");
			history.push(path);
		}
	}

	onSubmit = (formValues) => {
		this.props.fetchLogin(formValues);
	};

	onSubmit2 = (formValues) => {
		this.props.sendCode(formValues);
		ls.set("ll", "/analyse");
	};

	onSubmit3 = (formValues) => {
		this.props.resetPassword(formValues);
	};

	renderForm = () => {
		const initVal = {
			code1: "",
			code2: "",
			code3: "",
			code4: "",
			code5: "",
			code6: "",
		};
		if (
			this.props.login.succes === "true" &&
			this.props.login.response_code === 200
		) {
			return (
				<div
					style={{
						width: this.props.dimensions.width,
						height: this.props.dimensions.height,
						backgroundImage: this.backgroundImage,
						backgroundRepeat: "repeat-x",
						opacity: "0,9",
					}}>
					<LoginCodeForm
						width={this.props.dimensions.width}
						height={this.props.dimensions.height}
						onSubmit={this.onSubmit2}
						initialValues={initVal}
						err=""
					/>
				</div>
			);
		} else if (
			this.props.login.succes === "true" &&
			this.props.login.response_code === 222
		) {
			return (
				<div
					style={{
						width: this.props.dimensions.width,
						height: this.props.dimensions.height,
						backgroundImage: this.backgroundImage,
						backgroundRepeat: "repeat-x",
						opacity: "0,9",
					}}>
					<NewWachtWoordForm
						width={this.props.dimensions.width}
						height={this.props.dimensions.height}
						onSubmit={this.onSubmit3}
						initialValues={{ unique_id: this.props.login.unique_id }}
						err=""
					/>
				</div>
			);
		} else if (
			this.props.login.succes === "false" &&
			this.props.login.response_code === 202
		) {
			return (
				<div
					style={{
						width: this.props.dimensions.width,
						height: this.props.dimensions.height,
						backgroundImage: this.backgroundImage,
						backgroundRepeat: "repeat-x",
						opacity: "0,9",
					}}>
					<LoginCodeForm
						width={this.props.dimensions.width}
						height={this.props.dimensions.height}
						onSubmit={this.onSubmit2}
						initialValues={initVal}
						err={this.props.login.errors}
					/>
				</div>
			);
		}
		return (
			<div
				className="main"
				style={{
					width: this.props.dimensions.width,
					height: this.props.dimensions.height,
					backgroundImage: this.backgroundImage,
					backgroundRepeat: "repeat-x",
					opacity: "0,9",
				}}>
				<div className="Fields">
					<LoginForm
						width={this.props.dimensions.width}
						height={this.props.dimensions.height}
						onSubmit={this.onSubmit}
						initialValues={this.getInitialValues()}
						err={this.props.login.errors}
					/>
					{this.getCookieScreen()};
				</div>
			</div>
		);
	};

	getCookieScreen = () => {
		if (ls.get("rm") !== null && ls.get("rm") !== undefined) {
			const rm = decryptData(ls.get("rm"), 3);
			if (!rm.cookies_accepted) {
				return <CookieScreenOK />;
			}
		}
		return null;
	};

	getInitialValues = () => {
		let init = {};
		let rm = {};
		if (ls.get("rm") !== null && ls.get("rm") !== undefined) {
			rm = decryptData(ls.get("rm"), 3);
		}
		if (rm.remember_me) {
			init = rm;
		} else {
			init = { email: "", password: "", remember_me: 0 };
		}
		if (this.props.location.email !== undefined) {
			init = { ...init, email: this.props.location.email };
		}
		return init;
	};

	render() {
		return <React.Fragment>{this.renderForm()}</React.Fragment>;
	}
}

const mapStateToProps = (state) => {
	return {
		dimensions: state.dimensions,
		login: state.login,
		auth: state.auth,
	};
};

export default connect(mapStateToProps, {
	fetchLogin,
	sendCode,
	setDimensions,
	resetPassword,
})(Login);
