import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ls from "local-storage";
import { encryptData, decryptData } from "../secure/keys";
import Login from "../login/Login";
import WachtwoordVergeten from "../login/WachtwoordVergeten";
import NewAccount from "../login/NewAccount";
import UserList from "./users/UserList";
import UserEdit from "./users/UserEdit";
import UserShow from "./users/UserShow";
import UserCreate from "./users/UserCreate";
import UserDelete from "./users/UserDelete";
import DocumentList from "./documents/DocumentList";
import DocumentShow from "./documents/DocumentShow";
import DocumentEdit from "./documents/DocumentEdit";
import DocumentCreate from "./documents/DocumentCreate";
import DocumentDelete from "./documents/DocumentDelete";
import DocumentResetValue from "./documents/DocumentResetValue";
import DashboardDocs from "./beheer/DashboardDocs";
import DashboardVals from "./beheer/DashboardVals";
import DashboardCoupleVals from "./beheer/DashboardCoupleVals";
import DashboardValueDelete from "./beheer/DashboardValueDelete";
import DashboardUsers from "./beheer/DashboardUsers";
import Dashboard from "./beheer/Dashboard";
import ValueList from "./values/ValueList";
import ValueShow from "./values/ValueShow";
import ValueEdit from "./values/ValueEdit";
import ValueDelete from "./values/ValueDelete";
import ValueCreate from "./values/ValueCreate";
import DocumentSearch from "./beheer/DocumentSearch";
import ValueSearch from "./beheer/ValueSearch";
import history from "../history";
import { signIn } from "../actions";
import { fetchCurrentUser } from "../actions/userActions";
import { PrivateRoute } from "./PrivateRoute";
import AnalyseMenu from "./analyses/AnalyseMenu";
import AnalysePage from "./analyses/AnalysePage";
import Error404 from "../404";
import RenderCS from "../components/RenderCS";
import Search from "./Search";

class App extends React.Component {
	componentDidMount() {
		if (process.env.REACT_APP_DEBUGMODE === "1") {
			this.props.fetchCurrentUser(process.env.REACT_APP_CURRENTUSERID);
			this.props.signIn(process.env.REACT_APP_CURRENTUSERID);
		}
		// const currentUser = this.props.fetchCurrentUser(this.props.auth.userId);
		if (
			this.props.auth !== undefined &&
			this.props.auth.isSignedIn &&
			this.props.auth.userId !== null
		) {
			const currentUser = this.props.currentUser;

			ls.backend("bwg-online");
			ls.set("cu", encryptData(currentUser));
		}
	}

	getLocalStorage = () => {
		const localStorage = ls.get("cu");
		// console.log('debugmode: ' + process.env.REACT_APP_DEBUGMODE);
		// console.log('ls: ' + localStorage);
		if (
			localStorage !== null &&
			localStorage !== undefined &&
			process.env.REACT_APP_DEBUGMODE === "1"
		) {
			const currentUser = decryptData(localStorage, 3);
			this.props.signIn(currentUser.unique_id);
		}
	};

	renderRouter = () => {
		this.getLocalStorage();

		if (process.env.REACT_APP_DEBUGMODE === "0") {
			return (
				<Router history={history}>
					<Switch>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/analyse/page/:id"
							component={AnalysePage}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/analyse"
							component={AnalyseMenu}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/users/list/:page"
							component={UserList}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/users/new"
							component={UserCreate}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/users/edit/:id"
							component={UserEdit}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/users/delete/:id"
							component={UserDelete}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/users/:id"
							component={UserShow}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/documents/list/:page"
							component={DocumentList}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/documents/new"
							component={DocumentCreate}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/documents/edit/:id"
							component={DocumentEdit}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/documents/delete/:id"
							component={DocumentDelete}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/documents/:id"
							component={DocumentShow}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/values/list/:page"
							component={ValueList}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/values/new"
							component={ValueCreate}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/values/edit/:id"
							component={ValueEdit}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/values/delete/:id"
							component={ValueDelete}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/values/:id"
							component={ValueShow}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/dashboard"
							component={Dashboard}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/documenten/list/reset/:value"
							component={DocumentResetValue}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/documenten/list/:page"
							component={DashboardDocs}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/waarden/list/:page"
							component={DashboardVals}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/waarden/koppelen/list/:page"
							component={DashboardCoupleVals}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/waarden/delete"
							component={DashboardValueDelete}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/users/list/:page"
							component={DashboardUsers}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/search/newdocuments/list/:page"
							component={DocumentSearch}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/beheer/search/newvalues/list/:page"
							component={ValueSearch}
						/>
						<PrivateRoute
							auth={this.props.auth}
							exact
							path="/search"
							component={Search}
						/>
						<Route exact path="/404" component={Error404} />
						<Route exact path="/cookiestatement" component={RenderCS} />
						<Route exact path="/login/newaccount" component={NewAccount} />
						<Route
							exact
							path="/login/wachtwoordvergeten"
							component={WachtwoordVergeten}
						/>
						<Route path="/" component={Login} />
						<Redirect to="/404" />
					</Switch>
				</Router>
			);
		}
		return (
			<Router history={history}>
				<Switch>
					<Route
						auth={this.props.auth}
						path="/analyse/page/:id"
						component={AnalysePage}
					/>
					<Route
						auth={this.props.auth}
						path="/analyse"
						component={AnalyseMenu}
					/>
					<Route
						auth={this.props.auth}
						path="/users/list/:page"
						component={UserList}
					/>
					<Route
						auth={this.props.auth}
						path="/users/new"
						component={UserCreate}
					/>
					<Route
						auth={this.props.auth}
						path="/users/edit/:id"
						component={UserEdit}
					/>
					<Route
						auth={this.props.auth}
						path="/users/delete/:id"
						component={UserDelete}
					/>
					<Route
						auth={this.props.auth}
						path="/users/:id"
						e
						component={UserShow}
					/>
					<Route
						auth={this.props.auth}
						path="/documents/list/:page"
						component={DocumentList}
					/>
					<Route
						auth={this.props.auth}
						path="/documents/new"
						component={DocumentCreate}
					/>
					<Route
						auth={this.props.auth}
						path="/documents/edit/:id"
						component={DocumentEdit}
					/>
					<Route
						auth={this.props.auth}
						path="/documents/delete/:id"
						component={DocumentDelete}
					/>
					<Route
						auth={this.props.auth}
						path="/documents/:id"
						component={DocumentShow}
					/>
					<Route
						auth={this.props.auth}
						path="/values/list/:page"
						component={ValueList}
					/>
					<Route
						auth={this.props.auth}
						path="/values/new"
						component={ValueCreate}
					/>
					<Route
						auth={this.props.auth}
						path="/values/edit/:id"
						component={ValueEdit}
					/>
					<Route
						auth={this.props.auth}
						path="/values/delete/:id"
						component={ValueDelete}
					/>
					<Route
						auth={this.props.auth}
						path="/values/:id"
						component={ValueShow}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/dashboard"
						component={Dashboard}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/documenten/list/reset/:value"
						component={DocumentResetValue}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/documenten/list/:page"
						component={DashboardDocs}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/waarden/list/:page"
						component={DashboardVals}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/waarden/koppelen/list/:page"
						component={DashboardCoupleVals}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/waarden/delete"
						component={DashboardValueDelete}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/users/list/:page"
						component={DashboardUsers}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/search/newdocuments/list/:page"
						component={DocumentSearch}
					/>
					<Route
						auth={this.props.auth}
						path="/beheer/search/newvalues/list/:page"
						component={ValueSearch}
					/>
					<Route auth={this.props.auth} path="/search" component={Search} />
					<Route path="/404" component={Error404} />
					<Route path="/cookiestatement" component={RenderCS} />
					<Route path="/" component={AnalyseMenu} />
					<Redirect to="/404" />
				</Switch>
			</Router>
		);
	};

	render() {
		return <div>{this.renderRouter()}</div>;
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		currentUser: state.currentUser,
	};
};

export default connect(mapStateToProps, { fetchCurrentUser, signIn })(App);
