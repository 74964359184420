/* eslint-disable no-unused-vars */
import React from 'react';
import history from '../../history';
import { connect } from 'react-redux';
import Header from '../Header';
import Dropdown from '../elements/Dropdown';
import Pagination from '../elements/Pagination';
import { getCoupledValues, setArrNotCoupled, coupleValue } from '../../actions/valueActions';
import { setDocument, fetchDocuments } from '../../actions/documentActions';
import { setActiveMenu, setPagination } from '../../actions';
import ls from 'local-storage';

class DashboardCoupleVals extends React.Component {

  componentDidMount(){
    this.props.fetchDocuments();
    this.props.getCoupledValues();
    this.props.setActiveMenu('5');
  };

  componentDidUpdate() {
    this.props.setPagination(`/beheer/waarden/list/${this.props.pages.activePage}`,this.props.pages.activePage,this.props.pages.selectedItemsPerPage);
    ls.set('ll', `/beheer/waarden/list/${this.props.pages.activePage}`);
  }

  getBack= () => {
    history.push(`/beheer/dashboard/`);
  }

  renderButtons = () => {
    let buttons = '';
    if (this.props.values.length  >= 2){
      buttons = 
      <React.Fragment>
        <div className="ui small primary labeled icon button" onClick={(e) => {this.setCoupleValues()}}>
          <i className="undo icon"></i>Koppelen
        </div>
    </React.Fragment>
    }

    return (
      <div className="ui container">
        <div style={{ textAlign: 'right', zIndex:'200', cursor: 'pointer' }}>
          <div className="right floated content">
            {buttons}
            <div  className="right floated content" style={{ display: "inline-block", float: "right" }}>
              <div className="ui button" style={{ marginLeft: "1em" }} onClick={this.getBack}>Terug</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  setCoupleValues = () => {
    const vals = { arr: this.props.arrnotcoupled, document: this.props.selections.selectedDocument, activePage: this.props.pages.activePage };
    this.props.coupleValue(vals);
  }

  renderCheckBox = (id) => {
    return (
      <div className="right floated content" >
        <div className="ui checkbox" style={{ position: 'relative', top: '8px' }} >
          <input type="checkbox" onChange={(e) => {this.changeCheckbox(id)}} />
          <label></label>
        </div>
      </div>
    );
  }

  changeCheckbox = (id) => {
    let arrNotCoupled = this.props.arrnotcoupled;
    if (arrNotCoupled.includes(id)) {
      let index = arrNotCoupled.indexOf(id);
      if (index > -1){
        arrNotCoupled.splice(index, 1);
      } 
    } else {
      arrNotCoupled.push(id);
    }
    this.props.setArrNotCoupled(arrNotCoupled);
  }

  renderList = () => {
    const itemsPerPage = this.props.pages.selectedItemsPerPage;
    const page = this.props.pages.activePage;
    const end = (parseInt(page) * parseInt(itemsPerPage));
    const start = (parseInt(end) - parseInt(itemsPerPage));
     if (this.props.values.length  < 2 ){
       return (
         <div className="item">
           Geen verwijderde waarden gevonden
         </div>
       )
     }
 
    return this.props.values

   // eslint-disable-next-line array-callback-return
   .map((val, index) => {
     let result = '';
     if (val.value_id !== 0) {
       if (index >= start && index < end){
         if (val.document_id === 0 ){
           result = 
            <div className="item" key={val.value_id}>
            {this.renderCheckBox(val.value_id)}
              <i className="large middle aligned icon file code outline" />
              <div className="content">
                <div className="header" style={{ color: '#2185D0'}}>
                  {val.value_id}: {val.topic} ()
                </div>
                <div className="description">
                  {`->${val.description}`}
                </div>
              </div>
            </div>
         } else {
         result = 
           <div className="item" key={val.value_id}>
           {this.renderCheckBox(val.value_id)}
             <i className="large middle aligned icon file code outline" />
             <div className="content">
               <div className="header" style={{ color: '#2185D0'}}>
                 {val.value_id}: {val.topic} ({val.document_name})
               </div>
               <div className="description">
                 {`${val.doc_type}->${val.description}`}
               </div>
             </div>
           </div>
         }
         return result;
       }
     }
   });
 }

 onChangeSelection = (doc) => {
  this.props.setDocument(doc);
}

  renderDocs = () => {

    let docs = this.props.documents.map((doc) => {
      if (doc.document_id === 0){
        return null;
      }

      return {key: doc.document_id, text: doc.title, value: doc.type}
    });
    docs = docs.filter(option => option !== null);

    return(
      <div className="right floated content" style={{ display: 'flex', justifyContent: 'flex-end', zIndex:'210', cursor: 'pointer', marginRight: '4.5em' }}>
        <Dropdown
        label={'Selecteer Document:'}
        options={docs}
        selected={this.props.selections.selectedDocument}
        onSelectedChange={(setDoc) => this.onChangeSelection(setDoc)}
        image='false'
        />
      </div>
    );
  }

  render() {
    return(
      <div className="ui container">
        <Header location={this.props.location.pathname} />
        <h2>Waarden koppelen aan documenten</h2>
        <form>      
          <div className="ui celled list" >{this.renderList()}</div>
          <div className="ui right aligned basic segment" >{this.renderDocs()}</div>
          <div className="ui right aligned basic segment" >{this.renderButtons()}</div>
        </form>
        <div className="ui left aligned basic segment">
          <Pagination category="beheer/waarden/koppelen"/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    values: Object.values(state.notCoupledValue),
    pages: state.pages,
    arrnotcoupled: state.selections.arrNotCoupled,
    documents: Object.values(state.documents),
    selections: state.selections
  }
}

export default connect(mapStateToProps, { getCoupledValues, setArrNotCoupled, setActiveMenu, setPagination, coupleValue, fetchDocuments, setDocument })(DashboardCoupleVals);