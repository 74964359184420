import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { showPassword } from '../actions';
import history from '../history';

class NewWachtWoordForm extends React.Component {

  renderError ({ error, touched }){
    if(touched && error) {
      return(
          <label className="ui below red basic label error">{error}</label>
      );
    }
  };

  setPasswordShown = () => {
    this.props.showPassword(this.props.passwordShown);
  }

  renderInput= ({ input, label, meta, type, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    if (id === "password"){
      return (
        <div className = {className}>
          <div className= "ui action input">
            <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
            <div className="ui icon button" onClick={this.setPasswordShown}>
              <i className="eye icon"></i>
            </div>
          </div>
          {this.renderError(meta)}
        </div>
      );
    } else {
      return (
        <div className = {className}>
          <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
        </div>
      );
    }
  };

  onSubmit = (formValues) => {
    formValues = { ...formValues, unique_id: this.props.initialValues.unique_id };
    this.props.onSubmit(formValues);
  }

  getBack = () => {
    history.push('/');
  }

  setLoginbox = () => {
    const w = Math.ceil(this.props.width/2);
    const h = Math.ceil(this.props.height/2);
    const t = Math.ceil(h/2);
    const l = Math.ceil(w/2);

    return (
      <div id='login' style={{ width: w, height: h, left:l, top:t, border:'2px solid #85BE1A', borderRadius:'6px', backgroundColor:'#FFFFEE', opacity:'1'  }}>
        <div style={{ marginTop:'2px' }} >
          <img src={process.env.PUBLIC_URL + "/images/BWG-online.png"} alt='BWG-online' style={{ float:'left', width: '15%', height: 'auto'}} />
          <h1 className='ui header' style={{ color:'#4EA619', textAlign:'center'}}>
            Login bij de beleidswaarden generator online!
          </h1>
        </div>
        <form name='newWachtwoordForm' className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)} style={{ width:'90%' }}>
          
          <div style={{ position: 'relative', top:'-65px'}}>
            <h4>Nieuw wachtwoord:</h4>
            <Field name="password" label="Wachtwoord" id="password" type={this.props.passwordShown ? "text" : "password"} component={this.renderInput} />
            <Field name="checkPassword" label="Wachtwoord controle" id="checkPassword" type={this.props.passwordShown ? "text" : "password"} component={this.renderInput} />
          </div>
          <div className="ui below red basic label error" style= {{ position: 'relative', top:'-50px', display: `${this.props.err !== '' ? 'block' : 'none' }` }}>{this.props.err}</div>
            <button style={{ position: 'relative', top:'-45px'}} className="ui fluid olive button" type="submit">Wachtwoord opslaan</button>
        </form>
        
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.setLoginbox()}
      </React.Fragment>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  
  var regexpass = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
  if (!regexpass.test(formValues.password)){
    errors.password = "Het password moet voldoen aan minimaal 8 karakters, waarvan 1 hoofdletter, 1 cijfer en een speciaal karakter.";
  }

  if (!formValues.password) {
    errors.password = 'Een wachtwoord is verplicht!';
  }

  if (formValues.checkPassword !== formValues.password) {
    errors.checkPassword = 'De ingevoerde wachtwoorden komen niet overeen!';
  }

  if (!formValues.checkPassword) {
    errors.checkPassword = 'Het wachtwoord herhalen is verplicht!';
  }

  return errors;
}

const mapStateToProps = (state) => {
  return{
    dimensions: state.dimensions,
    passwordShown: state.password.passwordShown,
  }
}

export default connect(mapStateToProps, { showPassword })(reduxForm({ form: 'newWachtwoordForm', validate: validate, enableReinitialize: true})(NewWachtWoordForm));