import React from 'react';
import AnimatedChart from '../elements/charts/AnimatedChart';


class BarChart extends React.Component {
  
  fetchChartData = () => {
    const themes = this.props.themes;
    let total =0;
    for(let i=0;i<themes.length; i++){
      total = total + themes[i].count;
    }
    const chartData = themes.map((theme) => {
      return { y: theme.count, label: theme.name };
    })
    const options = {
      animationEnabled: true,
      theme: "light2",
      backgroundColor: 'transparent',
      title:{
        text: "Aantallen"
      },
      axisX: {
        title: "Onderwerpen",
        reversed: true,
      },
      axisY: {
        title: "Aantal voorkomens",
        includeZero: true,
        labelFormatter: this.addSymbols
      },
      height: 270,
      width: 360,
      data: [{
        type: "bar",
        dataPoints: chartData
      }]
    }
    return options;
  }


  render(){
    return(
      <AnimatedChart options={this.fetchChartData()} />
    );
  }
}

export default BarChart;