import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setDimensions, setActiveMenu } from '../../actions';
import { fetchValue, editValue, resetIndex } from '../../actions/valueActions';
import Header from '../Header';
import ValueForm from '../values/ValueForm';

class ValueEdit extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('3');
    this.props.fetchValue(this.props.value.value_id);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  onSubmit = (formValues, activePage) => {
    formValues = { ...formValues, unique_id: this.props.currentUser.unique_id}
    this.props.editValue(formValues.ind, formValues.value_id, formValues, activePage);
    this.props.resetIndex();
  };
  
  prepareInitialValues = () => {
    let inVal= _.pick(this.props.value, 'value_id', 'unique_id','document_id', 'doc_type', 'document_name', 'description', 'interpretation', 'bondage', 'topic', 'ow_29', 'ow_210', 'status', 'qual_quan', 'reference','ind');
    inVal.topic = inVal.topic.charAt(0).toUpperCase() + inVal.topic.slice(1);
    inVal.interpretation = inVal.interpretation.charAt(0).toUpperCase() + inVal.interpretation.slice(1);
    return inVal;
  }

  render() {
    const initialValues = this.prepareInitialValues();
    return (
      <div 
      style={{ 
        width:this.props.dimensions.width, 
        height: this.props.dimensions.height,  
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,179,165,0.48082983193277307) 100%)'
      }}
      className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h3>Waarde wijzigen:</h3>
          <h4 className="ui dividing header">
            <div>
              <div style={{ display: "inline-block" }} className="ui content">
                Komt voor in {this.props.value.document_name} ({this.props.value.doc_type})
              </div>
            </div>
          </h4>
          <ValueForm
            id = {this.props.value.ind}
            value_id= {this.props.value.value_id} 
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            name='Wijzigen' 
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    dimensions: state.dimensions,
    value: state.values[ownProps.match.params.id],
    currentUser: state.currentUser,
    pages: state.pages
  }
}

export default connect(mapStateToProps, { setDimensions, setActiveMenu, fetchValue, editValue, resetIndex })(ValueEdit);