import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import Header from '../Header';
import { setDimensions } from '../../actions';
import PieChart from './PieChart';
import BarChart from './BarChart';
import { ExportToCSV } from './ExportToCSV';

class AnalysePage extends React.Component {

  getObjectName = () => {
    const name = this.props.location.profile.profile_name;
    return name;
  }

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
  };

  getFilteredThemes = () => {
    const themes = this.props.location.profile.themes.map((theme) => {
      const values = this.props.values.filter((val) => val.topic.toLowerCase() === theme.toLowerCase());
      return { name: theme, values: values, count: values.length }
    });
    return themes;
  };

getCSVData = () => {
  const themes = this.getFilteredThemes();
  const rows = themes.map((theme) => {
    return theme.values.map((val) => {
      return {
        id: val.value_id, 
        onderwerp: val.topic, 
        document_type: val.doc_type,
        document_naam: val.document_name,
        omschrijving: val.description,
        geo_duiding: val.interpretation,
        gebondenheid: val.bondage,
        voldoet_ow29: val.ow_29,
        voldoet_ow210: val.ow_210,
        status: val.status,
        kwalitatief: val.qual_quan,
        referentie: val.reference
      };
    });
  });
  return rows;
}

  getRows = (theme) => {
    return theme.values.map((val) => {
      return( 
        <tr key={val.value_id}>
          <td>{val.topic}</td>
          <td>{val.doc_type}</td>
          <td>{val.document_name}</td>
          <td>{val.description}</td>
          <td>{val.interpretation}</td>
          <td>{val.bondage}</td>
        </tr>
      );
    });
  }

  getTableRows = () => {
    const themes = this.getFilteredThemes();
    let rows = []
    for (let i=0;i<themes.length;i++){
      rows[i] = this.getRows(themes[i]);
    }
    return(
      <tbody>{rows}</tbody>
    );
  };
  
getBack = ()=> {
  history.push(`/analyse`);
}

  render() {
    return(
      <div 
        style={{ 
          width:this.props.dimensions.width, 
          height: this.props.dimensions.height, 
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(118,238,221,0.48082983193277307) 100%)'
        }}
        className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <ExportToCSV csvData={this.getCSVData()} fileName={this.getObjectName()} />
          <div className="ui button" style={{ float: 'right', marginLeft: "1em" }} onClick={this.getBack}>Terug</div>
          <h2 style={{ color: this.props.location.profile.color}}>Analyse {this.getObjectName()}</h2>
          <div className="ui horizontal segments" style={{ backgroundColor: 'transparent' }}>
            <div className="ui segment">
              <PieChart themes={this.getFilteredThemes()} values={this.props.values} />
            </div>
            <div className="ui segment">
              <BarChart themes={this.getFilteredThemes()} values={this.props.values} />
            </div>
          </div>
          <table className={`ui ${this.props.location.profile.color} table`}>
            <thead>
              <tr>
                <th className='two wide'>Onderwerp</th>
                <th>Doc Type</th>
                <th className='two wide'>Doc Titel</th>
                <th className='four wide'>Omschrijving waarde</th>
                <th>Geografische duiding</th>
                <th>Gebondenheid</th>
              </tr>
            </thead>
            {this.getTableRows()}
          </table>
          <div className="ui button" style={{ marginLeft: "1em" }} onClick={this.getBack}>Terug</div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    dimensions: state.dimensions,
    values: Object.values(state.values),
    profiles: state.profiles
  }
}

export default connect(mapStateToProps, { setDimensions })(AnalysePage);