import React from 'react';
import ls from 'local-storage';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveMenu, setDimensions, setPagination,  fetchMultipleSelectBoxValues } from '../../actions';
import {fetchDocuments, fetchThemes } from '../../actions/documentActions';
import { fetchValues } from '../../actions/valueActions';
import { fetchCurrentUser } from '../../actions/userActions';
import Header from '../Header';
import Pagination from '../elements/Pagination';

class DocumentList extends React.Component {

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
    if (this.props.auth !== undefined && this.props.auth.userId !== null) {
      this.props.fetchCurrentUser(this.props.auth.userId);
    }
    this.props.setActiveMenu('2');
    this.props.setPagination('documents',this.props.match.params.page,this.props.pages.selectedItemsPerPage);
    this.props.fetchDocuments();
    this.props.fetchThemes();
    this.props.fetchValues();
    this.props.fetchMultipleSelectBoxValues(null, 'reset');
    ls.set('ll', `/documents/list/${this.props.pages.activePage}`);
  };

  componentDidUpdate() {
    ls.set('ll', `/documents/list/${this.props.match.params.page}`);
  }

  renderOwner(doc) {
    if (this.props.currentUser.rights.charAt(2) === '1' && this.props.currentUser.rights.charAt(3) === '1') {
      return ( 
        <div className="right floated content">
          <Link className="ui small primary labeled icon button" to={`/documents/edit/${doc.document_id}`}>
            <i className="pencil alternate icon"></i>Wijzigen
          </Link>
          <Link className="ui small negative labeled icon button" to={`/documents/delete/${doc.document_id}`}>
            <i className="trash alternate outline icon"></i>Verwijderen
          </Link>
        </div>  
      );
    } else if (this.props.currentUser.rights.charAt(2) === '1') {
      return ( 
        <div className="right floated content" >
          <Link className="ui small primary labeled icon button" to={`/documents/edit/${doc.document_id}`}>
            <i className="pencil alternate icon"></i>Wijzigen
          </Link>
        </div>  
      );
    }else if (this.props.currentUser.rights.charAt(3) === '1') {
      return(
        <div className="right floated content">
          <Link className="ui small negative labeled icon button" to={`/documents/delete/${doc.document_id}`}>
            <i className="trash alternate outline icon"></i>Verwijderen
          </Link>
        </div>
      );
    }
    return null;
  }

  selectedSubThemes = (e,id) => {
    const subthemes = Object.values(this.props.documents[id].subthemes);
    const themas = this.props.themes.map((theme) => {
      if (subthemes.includes(theme.thema)) {
    const subtheme= {[theme.thema_id]:{thema:theme.thema, thema_id:theme.thema_id}};
        this.props.fetchMultipleSelectBoxValues(subtheme, 'add');
        return subtheme;
      };
      return null
    });
      const sub = themas.filter(option => option !== null);
      return sub;
  }

  renderCreate() {
    if (this.props.currentUser.rights.charAt(0) === '1') {
      if (this.props.auth.isSignedIn) {
        return (
          <div className="ui container">
            <div style={{ textAlign: 'right', zIndex: "110" }}>
              <Link to="/documents/new" className="ui right floated primary button">
                Nieuw Document
              </Link>
            </div>  
          </div>
        );
      }
    }
  };

  renderList() {
    const itemsPerPage = this.props.pages.selectedItemsPerPage;
    const page = this.props.pages.activePage;
    const end = (parseInt(page) * parseInt(itemsPerPage) + 1);
    const start = (parseInt(end) - parseInt(itemsPerPage)); 
    return this.props.documents.map((doc, index) => {
      if (doc.document_id === 0) {
        return null;
      }
      if (index < start || index >= end){
        return null;
      }
      return (
        <div className="item" key={doc.document_id}>
          {this.renderOwner(doc)}
          <i className="large middle aligned icon file pdf outline" />
          <div className="content" key={doc.document_id}>
            <Link to={`/documents/${doc.document_id}`} className="header">
              {doc.title}
            </Link>
            <div className="description">{doc.type}</div>
          </div>
        </div>
      );
    });
  }

  render(){
    return(
      <div 
        style={{ 
          width:this.props.dimensions.width, 
          height: this.props.dimensions.height,  
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,243,165,0.48082983193277307) 100%)'
        }}
        className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h2>Documenten</h2>
          <div className="ui celled list">{this.renderList()}</div>
          {this.renderCreate()}
          <Pagination category="documents"/>
        </div>
      </div>
    );
  }  
};

const mapStateToProps = (state) => {
  return { 
    auth: state.auth,
    currentUser: state.currentUser,
    pages: state.pages,
    dimensions: state.dimensions,
    documents: Object.values(state.documents),
    themes: Object.values(state.themes)
  };
};

export default connect(mapStateToProps, { setActiveMenu, setDimensions,fetchCurrentUser, fetchDocuments, setPagination, fetchThemes, fetchValues, fetchMultipleSelectBoxValues })(DocumentList);