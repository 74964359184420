import React from "react";
import Header from "../components/Header";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { snowflakeGenerator } from "snowflake-id-js";
import "../css/style.css";

class Search extends React.Component {
	occurrences = (string, subString, allowOverlapping) => {
		string += "";
		subString += "";
		if (subString.length <= 0) return string.length + 1;

		var n = 0,
			result = [],
			pos = 0,
			step = allowOverlapping ? 1 : subString.length;

		while (true) {
			pos = string.indexOf(subString, pos);
			if (pos >= 0) {
				result.push(pos);
				++n;
				pos += step;
			} else break;
		}

		return { n, result };
	};

	makeKey = () => {
		const generator = snowflakeGenerator(16);
		var idx = generator.next().value;
		return idx;
	};

	makeRedBold = (string, substring, n, pos) => {
		const len = substring.length;
		let end = "";
		let part = [];
		let span = <span className="redBold">{substring}</span>;

		// if (n > 1) {
		end = string.slice(pos[n - 1] + len);

		for (let i = 0; i < n; i++) {
			if (i === 0) {
				part[i] = string.slice(0, pos[i]);
			} else {
				part[i] = string.slice(pos[i - 1] + len, pos[i]);
			}
		}

		const result = part.map((item, index) => {
			if (pos[0] === 0 || item.slice(-2) === " .") {
				substring = substring.charAt(0).toUpperCase() + substring.slice(1);
				span = <span className="redBold">{substring}</span>;
			}
			return (
				<React.Fragment key={index}>
					{item}
					{span}
				</React.Fragment>
			);
		});

		return (
			<React.Fragment key={this.makeKey()}>
				{result}
				{end}
			</React.Fragment>
		);
	};

	renderList = () => {
		let searchResultValues = [];
		let searchResultDocuments = [];
		if (
			this.props.search.result[0] !== undefined &&
			this.props.search.result[0] !== null
		) {
			searchResultValues = this.props.search.result[0].map((val) => {
				let ind = 0;
				// eslint-disable-next-line array-callback-return
				this.props.values.map((myval) => {
					if (myval.value_id === val.value_id) {
						ind = myval.ind;
					}
				});
				let vals = val.topic.charAt(0).toUpperCase() + val.topic.slice(1);
				let val_lowercase = val.topic.toLowerCase();
				let document_name = val.document_name;
				let document_name_lowercase = val.document_name.toLowerCase();
				let description = val.description;
				let description_lowercase = val.description.toLowerCase();
				let find = this.props.search.term;
				let pos = [];

				let n = this.occurrences(val_lowercase, find, false);
				if (n.n > 0) {
					pos = n.result;
					vals = this.makeRedBold(vals, find, n.n, pos);
				}
				n = this.occurrences(document_name_lowercase, find, false);
				if (n.n > 0) {
					pos = n.result;
					document_name = this.makeRedBold(document_name, find, n.n, pos);
				}
				n = this.occurrences(description_lowercase, find, false);

				if (n.n > 0) {
					pos = n.result;
					description = this.makeRedBold(description, find, n.n, pos);
				}

				return (
					<div className="item" key={ind}>
						<h4>
							<Link to={`/documents/${val.document_id}`}>
								{document_name} &rarr;{val.doc_type}
							</Link>
						</h4>
						<i className="large middle aligned icon file alternate outline" />
						<div className="content" key={ind}>
							<Link to={`/values/${val.value_id}`} className="header">
								{vals}({ind})
							</Link>
							<div className="description">{description}</div>
						</div>
					</div>
				);
			});
		}
		if (
			this.props.search.result[1] !== undefined &&
			this.props.search.result[1] !== null
		) {
			searchResultDocuments = this.props.search.result[1].map((doc) => {
				let title = doc.title.charAt(0).toUpperCase() + doc.title.slice(1);
				let title_lowercase = doc.title.toLowerCase();
				let context = doc.context;
				let context_lowercase = doc.context.toLowerCase();
				let responsible = doc.responsible;
				let responsible_lowercase = doc.responsible.toLowerCase();
				let find = this.props.search.term;
				let pos = [];

				let n = this.occurrences(title_lowercase, find, false);
				if (n.n > 0) {
					pos = n.result;
					title = this.makeRedBold(title, find, n.n, pos);
				}

				n = this.occurrences(context_lowercase, find, false);
				if (n.n > 0) {
					pos = n.result;
					context = this.makeRedBold(context, find, n.n, pos);
				}

				n = this.occurrences(responsible_lowercase, find, false);
				if (n.n > 0) {
					pos = n.result;
					responsible = this.makeRedBold(responsible, find, n.n, pos);
				}

				return (
					<div className="item" key={doc.document_id}>
						<i className="large middle aligned icon file pdf outline" />
						<div className="content" key={doc.document_id}>
							<Link to={`/documents/${doc.document_id}`} className="header">
								{title}
							</Link>
							<div className="description">{context}</div>
							<div className="description">
								<strong>Verantwoordelijk orgaan: </strong>
								{responsible}
							</div>
						</div>
					</div>
				);
			});
		}

		return (
			<React.Fragment>
				<div className="item">
					<div className="header">Documenten:</div>
				</div>
				{searchResultDocuments}
				<div className="item">
					<div className="header">Waarden</div>
				</div>
				{searchResultValues}
			</React.Fragment>
		);
	};

	render() {
		const term =
			this.props.search.term.charAt(0).toUpperCase() +
			this.props.search.term.slice(1);
		return (
			<div className="ui container">
				<Header location={this.props.location.pathname} />
				<h2>Zoek resultaten:</h2>
				<h3>
					Zoekterm: &nbsp;
					<span style={{ color: "red" }}>{term}</span>
				</h3>

				<div className="ui celled list">{this.renderList()}</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		search: state.search,
		values: Object.values(state.values),
	};
};

export default connect(mapStateToProps, {})(Search);
