import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import history from '../../history';
import Dropdown from '../elements/Dropdown';
import { setInterpretation, setOrigin, setTopic } from '../../actions';
import { fetchThemes } from '../../actions/documentActions';


class ValueForm extends React.Component {
componentDidMount() {
  this.props.fetchThemes();
}

  renderError ({ error, touched }){
    if(touched && error) {
      return(
          <label className="ui below red basic label error">{error}</label>
      );
    }
  }

  renderTopic = () => {
    let topics = Object.values(this.props.themes).map((theme) => {
      const val= theme.thema;
      return { key: theme.thema_id, text: val.charAt(0).toUpperCase() + val.slice(1), value: val.charAt(0).toUpperCase() + val.slice(1) };
    });
    topics = topics.filter( topic => topic.value !== '');
    topics = topics.sort((a,b ) =>{
      return a.value.localeCompare(b.value);
    });
    let selected = {};
    const vals = this.props.initialValues.topic;
    if (this.props.selections !== undefined) {
      if (this.props.selections.origin.topic === 0 ){
        selected = { value: vals.charAt(0).toUpperCase() + vals.slice(1), text: vals.charAt(0).toUpperCase() + vals.slice(1), key: 93 };
      } else {
        selected = this.props.selections.selectedTopic;
      }
    }
    return(
      <Dropdown
      label={'Onderwerp'}
      options={topics}
      selected={selected}
      onSelectedChange={(topic) => this.changeTopic(topic)}
      image='false'
      />
    );  
  }

  renderInterpretation = () => {
    const val = this.props.initialValues.interpretation;
    let selection = '';
    if (this.props.selections !== undefined) { 
      if (this.props.selections.origin.interpretation === 0 ){
        selection = { value: val.charAt(0).toUpperCase() + val.slice(1)};
      } else {
        selection = { value: this.props.selections.interpretation.value};
      }
    }
    let options = [{value:'Bedrijventerrein', key:1},{value:'Buitengebied',key:2}, {value:'Centrum', key:3}, {value:'Gehele Grondgebied', key:4}, {value:'Woongebied', key:5}];
    options = options.map((option) => {
      return {
          text: option.value,
          key: option.key,
          value: option.value
      }
      });
    return(
      <Dropdown
      label={'Geografische duiding'}
      options={options}
      selected={selection}
      onSelectedChange={(interpretation) => this.changeSelection(interpretation)}
      image='false'
      />
    );
  }

  changeTopic = (topi) => {
    const lblTopic = document.getElementById('lblTopic');
    lblTopic.style.display = 'none';
    const origin = { ...this.props.selections.origin, topic: 1};
    this.props.setOrigin(origin);
    this.props.setTopic(topi);
  }

  changeSelection = (val) =>{
    const lblInterpretation = document.getElementById('lblInterpretation');
    lblInterpretation.style.display = 'none';
    const origin = { ...this.props.selections.origin, interpretation: 1};
    this.props.setOrigin(origin);
    this.props.setInterpretation(val);
  }

  valToUpperCase = (val) => {
    const vals = val.topic.charAt(0).toUpperCase() + val.topic.slice(1);
    return vals;
  }

  renderInput= ({ input, label, meta, type, id }) => {
    let className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    const inputFields = () => {
      if (id === 'description'){
        return (      
          <div className={className} >
            <label>{label}</label>
            <textarea {... input} placeholder={label} autoComplete="off">{input.value}</textarea>
          </div>
        );
      } else if (id === 'qual_quan'){
        return (
          <div className="inline fields" style={{ borderRadius:'5px' ,borderStyle:'solid', borderWidth:'1px', borderColor:'#CCCCCC', padding: '0.5em' }}>
            <label>{label}</label>
            <div className={className} style={{ position:'relative', top:'50%' }} >
              <div className="ui radio checkbox">
                <input type="radio" style={{ zIndex: '200' }} name={id} onChange={(e) => input.onChange('kwalitatief')} checked={input.value.toLowerCase() === 'kwalitatief'} /> 
                <label>Kwalitatief</label>
              </div>
            </div>
            <div className={className} style={{ position:'relative', top:'50%' }} >
              <div className="ui radio checkbox">
                <input type="radio" style={{ zIndex: '201' }} name={id} onChange={(e) => input.onChange('kwantitatief')} checked={input.value.toLowerCase() === 'kwantitatief'} />
                <label>Kwantitatief</label>
              </div>
            </div>
            
          </div>
        );
      } else if (id === 'bondage'){
      return (
        <div className="inline fields" style={{ borderRadius:'5px' ,borderStyle:'solid', borderWidth:'1px', borderColor:'#CCCCCC', padding: '0.5em' }}>
          <label>{label}</label>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '202' }} name={id} onChange={(e) => input.onChange('kaderstellend')} checked={input.value.toLowerCase() === 'kaderstellend'}/>
              <label>Kaderstellend</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '203' }} name={id} onChange={(e) => input.onChange('normstellend')} checked={input.value.toLowerCase() === 'normstellend'}/>
              <label>Normstellend</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '204' }} name={id} onChange={(e) => input.onChange('kader-/normstellend')} checked={input.value.toLowerCase() === 'kader-/normstellend'}/>
              <label>Kader-/normstellend</label>
            </div>
          </div>
        </div>
      );
    } else if (id === 'ow_29'){
      return (
        <div className="inline fields" style={{ borderRadius:'5px' ,borderStyle:'solid', borderWidth:'1px', borderColor:'#CCCCCC', padding: '0.5em' }}>
          <label>{label}</label>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '202' }} name={id} onChange={(e) => input.onChange('ja')} checked={input.value.toLowerCase() === 'ja'}/>
              <label>Ja</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '203' }} name={id} onChange={(e) => input.onChange('nee, lid 2')} checked={input.value.toLowerCase() === 'nee, lid 2'}/>
              <label>Nee, lid 2</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '204' }} name={id} onChange={(e) => input.onChange('nee, lid 3')} checked={input.value.toLowerCase() === 'nee, lid 3'}/>
              <label>Nee, lid 3</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '204' }} name={id} onChange={(e) => input.onChange('nee, lid 2 en 3')} checked={input.value.toLowerCase() === 'nee, lid 2 en 3'}/>
              <label>Nee, lid 2 en 3</label>
            </div>
          </div>
        </div>
      );
    } else if (id === 'ow_210'){
      return (
        <div className="inline fields" style={{ borderRadius:'5px' ,borderStyle:'solid', borderWidth:'1px', borderColor:'#CCCCCC', padding: '0.5em' }}>
          <label>{label}</label>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '202' }} name={id} onChange={(e) => input.onChange('inspanningsverplichting')} checked={input.value.toLowerCase() === 'inspanningsverplichting'} />
              <label>Inspanningsverplichting</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '203' }} name={id} onChange={(e) => input.onChange('resultaatverplichting')} checked={input.value.toLowerCase() === 'resultaatverplichting'} />
              <label>Resultaatverplichting</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '204' }} name={id} onChange={(e) => input.onChange('andere')} checked={input.value.toLowerCase() === 'andere'} />
              <label>Andere</label>
            </div>
          </div>
        </div>
      );
    } else if (id === 'status'){
      return (
        <div className="inline fields" style={{ borderRadius:'5px' ,borderStyle:'solid', borderWidth:'1px', borderColor:'#CCCCCC', padding: '0.5em' }}>
          <label>{label}</label>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '202' }} name={id} onChange={(e) => input.onChange('verplicht')} checked={input.value.toLowerCase() === 'verplicht'} />
              <label>Verplicht</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '203' }} name={id} onChange={(e) => input.onChange('autonoom, voldoet aan 2.9')} checked={input.value.toLowerCase() === 'autonoom, voldoet aan 2.9'} />
              <label>Autonoom, voldoet aan 2.9</label>
            </div>
          </div>
          <div className={className} style={{ position:'relative', top:'50%' }} >
            <div className="ui radio checkbox">
              <input type="radio" style={{ zIndex: '204' }} name={id} onChange={(e) => input.onChange('autonoom, voldoet niet aan 2.9')} checked={input.value.toLowerCase() === 'autonoom, voldoet niet aan 2.9'} />
              <label>Autonoom, voldoet niet aan 2.9</label>
            </div>
          </div>
        </div>
      );
    }

      return (      
        <div className={className} >
          <label>{label}</label>
          <input {...input} id={id} type={type} placeholder={label} autoComplete="off" />
        </div>
      );
    }
    return(
      <React.Fragment>
        {inputFields()}
        {this.renderError(meta)}
      </React.Fragment>
    );
  }

  getBack= () => {
    history.push(`/values/list/${this.props.pages.activePage}`);
  }

  onSubmit = (formValues) => {
    let go1 = false;
    let go2 = false;
    if (this.props.selections.origin.interpretation!== 0 ){
      const interpretation = { interpretation: this.props.selections.interpretation.value };
      formValues = { ...formValues, ...interpretation };
    } else
      formValues = { ...formValues, interpretation: this.props.initialValues.interpretation } 
    if (this.props.selections.origin.topic!== 0 ){
      const topic = { topic: this.props.selections.selectedTopic.value };
      formValues = { ...formValues, ...topic }
    } else {
      formValues = { ...formValues, topic: this.props.initialValues.topic }
    }
    const lblInterpretation = document.getElementById('lblInterpretation');
    const lblTopic = document.getElementById('lblTopic');

    if (lblTopic !== undefined && lblTopic !== null){
       if (!formValues.topic || formValues.topic === '-') {
        lblTopic.style.display = 'inline-block';
       } else {
        go1 = true;
      }
    } 
    if(lblInterpretation !== undefined && lblInterpretation !== null){  
      if (!formValues.interpretation || formValues.interpretation === '-'){
        lblInterpretation.style.display = 'inline-block';
      } else {
        go2 = true;
      }
    }
    if (go1 && go2) {
      this.props.onSubmit(formValues,this.props.pages.activePage);
    }
  }

  render () {
    const submit =this.props.handleSubmit(this.onSubmit);
    return (
      <div>
        <form  id="valueForm" name="valueForm" onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui fluid form error">
          <div className="field">
            <div className="three fields">
              <div className="six wide field">
                <Field name="reference" id="reference" label="Referentie" type="text" component={this.renderInput} />
              </div>
              <div className="three wide field">
                {this.renderTopic()}
                <label id='lblTopic' style={{ display: 'none', marginTop: '8px' }} className="ui top pointing red basic label">Selecteer een onderwerp!</label>
              </div>
              <div className="three wide field">
                {this.renderInterpretation()}
              <label id='lblInterpretation' style={{ display: 'none', marginTop: '8px' }} className="ui top pointing red basic label">Selecteer een geografische duiding!</label>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="two fields">
              <div className="twelve wide field">
                <Field name="description" id="description" label="Omschrijving" type="text" rows="5" component={this.renderInput} />
              </div>
              <div className="four wide field">
              </div>
            </div>
          </div>
          <h4 className="ui dividing header">Waarde Eigenschappen</h4>
          <div className="field">
            <div className="two fields">
              <div className="ten wide field">
                <Field name="bondage" id="bondage" label="Gebondenheid:" type="text" component={this.renderInput} />
              </div>
            </div>
          </div>
          <div className="field">
            <div className="two fields">
              <div className="ten wide field">
              <Field name="qual_quan" id="qual_quan" label="Kwalitatief/Kwantitatief:" type="text" component={this.renderInput}  />
              </div>
              <div className="six wide field">
              </div>
            </div>
          </div>
          <h4 className="ui dividing header">Relatie Omgevingswet</h4>
          <div className="field">
            <div className="two fields">
              <div className="ten wide field">
                <Field name="ow_29" id="ow_29" label="Voldoen aan artikel 2.9 OW" type="text" component={this.renderInput}  />
              </div>
              <div className="six wide field">
              </div>
            </div>
          </div>
          <div className="field">
            <div className="two fields">      
              <div className="ten wide field">
                <Field name="ow_210" id="ow_210" label="Voldoen aan artikel 2.10 OW" type="text" component={this.renderInput} />
              </div>
              <div className="six wide field">
              </div>
            </div>
          </div>
          <div className="field">
            <div className="two fields">         
              <div className="ten wide field">
                <Field name="status" id="status" label="Status beleidswaarde" type="text" component={this.renderInput} />
              </div>
              <div className="six wide field">
              </div>
            </div>
          </div>
        </form>
        <h4 className="ui dividing header" style={{ marginTop: "-10px"}} >&nbsp;</h4>
        <div>
          <div style={{ display: "inline-block" }}>
            <button 
              type="submit" 
              className="ui button primary" 
              onClick={event => {
                submit(event)
              }}
            >
              {this.props.name}
            </button>
            <div className="ui button" style={{ marginLeft: "1em" }} onClick={this.getBack}>Terug</div>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.topic) {
    errors.topic = 'U moet een onderwerp invullen';
  }

  if (!formValues.reference) {
    errors.reference = 'U moet een referentie invullen';
  }

  if (!formValues.description) {
    errors.description = 'U moet een omschrijving invullen';
  }

  if(!formValues.ow_29){
    errors.ow_29 = "U moet aangeven of de waarde voldoet aan artikel 2.9 van de Omgevingswet."
  }

  if(!formValues.ow_210){
    errors.ow_210 = "U moet aangeven of de waarde voldoet aan artikel 2.10 van de Omgevingswet."
  }

  if(!formValues.qual_quan){
    errors.qual_quan = "U moet aangeven of de waarde kwalitatief of kwantitatief is."
  }

  if(!formValues.status){
    errors.status = "U moet aangeven wat de status van de beleidswaarde is."
  }

  if(!formValues.bondage){
    errors.bondage = "U moet aangeven wat de gebondenheid van de beleidswaarde is."
  }

  if(!formValues.interpretation){

  }

  return errors;
}

const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    selections: state.selections,
    themes: state.themes
  }
}

export default connect(mapStateToProps, { setInterpretation, setOrigin, fetchThemes, setTopic })(reduxForm({ form: 'valueForm', validate: validate })(ValueForm));