import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import Header from '../Header';
import Pagination from '../elements/Pagination';
import { getDeletedUsers, setArrDelete, resetUser, deleteUserFinal } from '../../actions/userActions';
import { setDimensions, setActiveMenu, setPagination } from '../../actions';
import ls from 'local-storage';

class DashboardUsers extends React.Component {
  
  componentDidMount(){
    this.props.getDeletedUsers();
    this.props.setActiveMenu('5');
  };

  componentDidUpdate() {
    this.props.setPagination(`/beheer/users/list/${this.props.pages.activePage}`,this.props.match.params.page,this.props.pages.selectedItemsPerPage);
    ls.set('ll', `/beheer/users/list/${this.props.pages.activePage}`);
  }

  getBack= () => {
    history.push(`/beheer/dashboard/`);
  }

  renderButtons = () => {
    return (
      <div className="ui container">
        <div style={{ textAlign: 'right', zIndex:'200', cursor: 'pointer' }}>
          <div className="right floated content">
            <div className="ui small primary labeled icon button" onClick={(e) => {this.props.resetUser(this.props.arrdelete)}}>
              <i className="undo icon"></i>Terug zetten
            </div>
            <div className="ui small negative labeled icon button" onClick={(e) => {this.props.deleteUserFinal(this.props.arrdelete)}}>
              <i className="trash alternate outline icon"></i>Verwijderen
            </div>
            <div  className="right floated content" style={{ display: "inline-block", float: "right" }}>
              <div className="ui button" style={{ marginLeft: "1em" }} onClick={this.getBack}>Terug</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  changeCheckbox = (id) => {
    let arrDelete = this.props.arrdelete;
    if (arrDelete.includes(id)) {
      let index = arrDelete.indexOf(id);
      if (index > -1){
        arrDelete.splice(index, 1);
      } 
    } else {
      arrDelete.push(id);
    }
    this.props.setArrDelete(arrDelete);
  }

  renderCheckBox = (id) => {
    return (
      <div className="right floated content" >
        <div className="ui checkbox" style={{ position: 'relative', top: '8px' }} >
          <input type="checkbox" onChange={(e) => {this.changeCheckbox(id)}} />
          <label></label>
        </div>
      </div>
    );
  }

  getUserName (user) {
    if (user.mname !== null) {
      return (user.fname + ' ' + user.mname + ' ' + user.lname ); 
    }
    return (user.fname + ' ' + user.lname);
  }

  renderList() {
     const itemsPerPage = this.props.pages.selectedItemsPerPage;
     const page = 1;
     const end = (parseInt(page) * parseInt(itemsPerPage) + 1);
     const start = (parseInt(end) - parseInt(itemsPerPage));
      if (this.props.users.length  < 2 ){
        return (
          <div className="item">
            Geen verwijderde gebruikers gevonden
          </div>
        )
      }
     return this.props.users
    // eslint-disable-next-line array-callback-return
    .map((user, index) => {
      if (user.id !== 0) {
        if (index >= start && index < end){
          return (
            <div className="item" key={user.unique_id}>
            {this.renderCheckBox(user.id)}
              <i className="large middle aligned icon user" />
              <div className="content">
                <div className="header" style={{ color: '#2185D0'}}>
                  {user.ind}: {this.getUserName(user)}
                </div>
                <div className="description">
                  {user.email}
                </div>
              </div>
            </div>
          );
        }
      }
    });
  }

  render() {
    return(
      <div className="ui container">
        <Header location={this.props.location.pathname} />
        <h2>Gebruikers Beheer</h2>
        <form>
          <div className="ui celled list">{this.renderList()}</div>
          {this.renderButtons()}
        </form>
        <Pagination category="beheer/users"/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: Object.values(state.deletedUser),
    pages: state.pages,
    arrdelete: state.selections.arrUserDelete
  }
}

export default connect( mapStateToProps, { getDeletedUsers, setArrDelete,  resetUser, deleteUserFinal, setDimensions, setActiveMenu, setPagination })(DashboardUsers);