/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import {
	FETCH_VALUES,
	FETCH_ACTIVE_VALUES,
	FETCH_ACTIVE_DELETION_VALUES,
	FETCH_VALUE,
	DELETE_VALUE,
	EDIT_VALUE,
	CREATE_VALUE,
} from "../actions/types";

const initialState = {
	999999999: {
		value_id: 0,
		unique_id: 0,
		document_id: 0,
		doc_type: "",
		document_name: "",
		description: "",
		interpretation: "-",
		bondage: "",
		topic: "-",
		ow_29: "",
		ow_210: "",
		status: "",
		qual_quan: "",
		reference: "",
	},
};
//Edit: Object-based approach

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_VALUES:
			const dat = action.payload.map((val, index) => {
				return { ...val, ind: index };
			});
			return { ...state, ..._.mapKeys(dat, "ind") };
		case FETCH_VALUE:
			return { ...state, [action.payload.value_id]: action.payload };
		case FETCH_ACTIVE_VALUES:
			_.omit(state, state.values.activeValues);
			return {
				...state,
				activeValues: { ..._.mapKeys(action.payload, "value_id") },
			};
		case FETCH_ACTIVE_DELETION_VALUES:
			_.omit(state, state.values.activeValues);
			return {
				...state,
				activeValues: { ..._.mapKeys(action.payload, "value_id") },
			};
		case EDIT_VALUE:
			return { ...state, [action.payload.ind]: action.payload };
		case CREATE_VALUE:
			return { ...state, [action.payload.ind]: action.payload };
		case DELETE_VALUE:
			return _.omit(state, action.payload);
		default:
			return state;
	}
};
