/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
import  { 
  FETCH_DOCUMENTS,
  FETCH_DOCUMENT,
  FETCH_ACTIVE_DOCUMENTS,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  CREATE_DOCUMENT,
  FETCH_ACTIVE_DELETION_DOCUMENTS
} from '../actions/types';

const initialState = 
  {
    0:
    {
      document_id:0,
      unique_id:'',
      city_id:'0',
      type:'',
      title:'',
      context:'',
      root_url:'',
      characteristics: '-',
      relationships: '-',
      annual_determination:'0000',
      responsible:'',
      entry_year:'0000',
      experation_year:'0000',
      pages:'0',
      binding:'0',
      assessment:'0',
      primary_domain:'-',
      secundary_domain:'-',
      theme:'-',
      subtheme: ['-'],
      deleted:'0',
      deleted_user:'',
      deletion_stamp:''
    }
  };
//Edit: Object-based approach

export default (state = initialState, action) => {
 
  switch (action.type) {
    case FETCH_DOCUMENTS:
      return { ...state, ..._.mapKeys(action.payload, 'document_id')};
    case FETCH_DOCUMENT:
      return {...state, [action.payload.document_id]:action.payload};
    case FETCH_ACTIVE_DOCUMENTS:
      _.omit(state, state.documents.activeDocuments);
      return { ...state, activeDocuments: { ..._.mapKeys(action.payload, 'document_id')}};
    case FETCH_ACTIVE_DELETION_DOCUMENTS:
      _.omit(state, state.documents.activeDocuments);
      return { ...state, activeDocuments: { ..._.mapKeys(action.payload, 'document_id')}};   
    case EDIT_DOCUMENT:
      return {...state, [action.payload.document_id]:action.payload};
    case CREATE_DOCUMENT:
      return { ...state, [action.payload.document_id]:action.payload};
    case DELETE_DOCUMENT:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};