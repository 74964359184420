import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { setDimensions, setActiveMenu } from "../../actions";
import { fetchValues } from "../../actions/valueActions";
import { fetchDocument } from "../../actions/documentActions";
import history from "../../history";
import Header from "../Header";
import BarThemeChart from "../dashboard/BarWaardenChart";

class DocumentShow extends React.Component {
	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
		this.props.fetchDocument(this.props.match.params.id);
		this.props.fetchValues();
		this.props.setActiveMenu("2");
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		this.props.setDimensions({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	renderButton = () => {
		if (this.props.currentUser.rights.charAt(2) === "1") {
			return (
				<div
					className="right floated content"
					style={{ display: "inline-block" }}>
					<Link
						className="ui small primary labeled icon button"
						to={`/documents/edit/${this.props.document.document_id}`}>
						<i className="pencil alternate icon"></i>Wijzigen
					</Link>
				</div>
			);
		}
	};

	getBack = () => {
		history.push(`/documents/list/${this.props.pages.activePage}`);
	};

	getSubthemes = () => {
		let subthemes = this.props.document.subtheme;
		if (subthemes.length <= 1 && subthemes[0] === "") {
			return <li key="1">Geen waarden bekend</li>;
		} else {
			subthemes = subthemes.map((sub) => {
				return <li key={sub}>{sub}</li>;
			});
		}
		return <ul style={{ marginTop: "0" }}>{subthemes}</ul>;
	};

	getExperation = () => {
		if (this.props.document.experation_year === 0) {
			return (
				<div
					style={{
						display: "inline-block",
						marginTop: "1em",
						marginLeft: "4em",
						verticalAlign: "Top",
					}}>
					<h5>Experatie</h5>
					N.V.T.
				</div>
			);
		} else if (this.props.document.experation_year < new Date().getFullYear()) {
			return (
				<div
					style={{
						display: "inline-block",
						marginTop: "1em",
						marginLeft: "4em",
						verticalAlign: "Top",
						color: "red",
					}}>
					<h5>Experatie</h5>
					{this.props.document.experation_year}
				</div>
			);
		}
		return (
			<div
				style={{
					display: "inline-block",
					marginTop: "1em",
					marginLeft: "4em",
					verticalAlign: "Top",
				}}>
				<h5>Experatie</h5>
				{this.props.document.experation_year}
			</div>
		);
	};

	boolToString = (bool) => {
		if (bool) {
			return "Ja";
		}
		return "Nee";
	};

	fetchData = (values) => {
		let countValues = values.map((value) => {
			return { topic: value.topic.toLowerCase() };
		});
		countValues = _.orderBy(countValues, "topic", "desc");
		const countArr = _.countBy(countValues, "topic");
		return {
			vals_arr: countArr,
		};
	};

	getWaarden = () => {
		const values = Object.values(this.props.values);
		const vals = values.filter(
			(type) => type.doc_type === this.props.document.type
		);
		const count = vals.length;
		return (
			<React.Fragment>
				{`Dit document bevat ${count} waarden`}
				<BarThemeChart docTypeData={this.fetchData(vals)} />
			</React.Fragment>
		);
	};

	render() {
		if (!this.props.document) {
			return (
				<div className="ui segment">
					<div className="ui active inverted dimmer">
						<div className="ui text loader">Loading...</div>
					</div>
				</div>
			);
		}

		const {
			title,
			context,
			type,
			root_url,
			primary_domain,
			secundary_domain,
			theme,
			responsible,
			annual_determination,
			entry_year,
			pages,
			binding,
			assessment,
		} = this.props.document;

		return (
			<div
				style={{
					width: this.props.dimensions.width,
					height: this.props.dimensions.height,
					background:
						"linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,243,165,0.48082983193277307) 100%)",
				}}
				className="ui container">
				<div className="ui container">
					<Header location={this.props.location.pathname} />
					<h4 className="ui dividing header" style={{ marginTop: "0" }}>
						&nbsp;
					</h4>
					<div>
						<div>
							<h4>{title}</h4>
						</div>
						<div className="ui content">{context}</div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								verticalAlign: "Top",
							}}>
							<h5>Type</h5>
							{type}
						</div>
						<div
							style={{
								display: "inline-block",
								marginLeft: "2em",
								marginTop: "1em",
								verticalAlign: "Top",
							}}>
							<h5 style={{ marginBottom: "0" }}>URL</h5>
							<Link to={{ pathname: root_url }} target="_Blank">
								{root_url}
							</Link>
						</div>
						<div
							style={{
								display: "inline-block",
								marginLeft: "2em",
								marginTop: "1em",
								verticalAlign: "Top",
							}}>
							<h5>Aantal pagina's</h5>
							{pages}
						</div>
					</div>
					<h4 className="ui dividing header" style={{ marginTop: "0" }}>
						&nbsp;
					</h4>
					<div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								verticalAlign: "Top",
							}}>
							<h5 style={{ marginBottom: "0" }}>Aantal Waarden</h5>
							{this.getWaarden()}
						</div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								marginLeft: "4em",
								verticalAlign: "Top",
							}}>
							<h5>Primair domein</h5>
							{primary_domain.charAt(0).toUpperCase() + primary_domain.slice(1)}
						</div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								marginLeft: "4em",
								verticalAlign: "Top",
							}}>
							<h5>Secundair domein</h5>
							{secundary_domain.charAt(0).toUpperCase() +
								secundary_domain.slice(1)}
						</div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								marginLeft: "4em",
								verticalAlign: "Top",
							}}>
							<h5>Thema</h5>
							{theme.charAt(0).toUpperCase() + theme.slice(1)}
						</div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								marginLeft: "4em",
								verticalAlign: "Top",
							}}>
							<h5 style={{ marginBottom: "0" }}>SubThema(s)</h5>
							{this.getSubthemes()}
						</div>
					</div>
					<h4 className="ui dividing header" style={{ marginTop: "0" }}>
						&nbsp;
					</h4>
					<div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								verticalAlign: "Top",
							}}>
							<h5>Verantwoordelijke organisatie:</h5>
							{responsible.charAt(0).toUpperCase() + responsible.slice(1)}
						</div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								marginLeft: "4em",
								verticalAlign: "Top",
							}}>
							<h5>Vaststelling</h5>
							{annual_determination}
						</div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								marginLeft: "4em",
								verticalAlign: "Top",
							}}>
							<h5>Inwerkingtreding</h5>
							{entry_year}
						</div>
						{this.getExperation()}
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								marginLeft: "4em",
								verticalAlign: "Top",
							}}>
							<h5>Bindend</h5>
							{this.boolToString(binding)}
						</div>
						<div
							style={{
								display: "inline-block",
								marginTop: "1em",
								marginLeft: "4em",
								verticalAlign: "Top",
							}}>
							<h5>Toetsingskader</h5>
							{this.boolToString(assessment)}
						</div>
					</div>
					<h4 className="ui dividing header" style={{ marginTop: "-10px" }}>
						&nbsp;
					</h4>
					<div>
						<div style={{ display: "inline-block" }}>
							{this.renderButton()}
							<div
								style={{ display: "inline-block", marginLeft: "1em" }}
								className="ui button"
								onClick={this.getBack}>
								Terug
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		document: state.documents[ownProps.match.params.id],
		pages: state.pages,
		dimensions: state.dimensions,
		values: state.values,
		currentUser: state.currentUser,
	};
};

export default connect(mapStateToProps, {
	fetchDocument,
	setDimensions,
	fetchValues,
	setActiveMenu,
})(DocumentShow);
