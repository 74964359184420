/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
import  { 
  FETCH_DELETED_USERS
} from '../actions/types';

const initialState = 
  {
    0:
    {
      id: 0,
      ind: 0,
      unique_id: 0,
      fname: '',
      mname: '',
      lname:'',
      city_oin:'Veenendaal',
      city_name:'0345',
      rights:'01000100010001000100',
      role:'user',
      email:'',
      phonenumber:'',
      blackednumber:'(0)6 ********',
      username:'', 
      password:''
    }
  };

  export default (state = initialState, action) => {

    switch (action.type) {
      case FETCH_DELETED_USERS:
        state = initialState;
        return {  ...state, ..._.mapKeys(action.payload, 'ind')};          
      default:
        return state;
    }
  };