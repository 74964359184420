import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUser, deleteUser } from '../../actions/userActions';
import Modal from '../../modal';
import history from '../../history';

class UserDelete extends React.Component {
  componentDidMount(){
    this.props.fetchUser(this.props.match.params.id);
  }
  renderActions() {
    let id = 0;
    if(this.props.user !== undefined) {
    id = this.props.user.id;
    }
    return (
      <React.Fragment>
        <button  
          onClick={() => this.props.deleteUser(id, this.props.currentUser.unique_id, this.props.page.activePage)} 
          className="ui negative button">
          Verwijderen
        </button>
        <Link to={`/users/list/${this.props.page.activePage}`} className="ui button">Terug</Link>
      </React.Fragment>
    );
  }

  renderTitle() {
    let name='';
    if (this.props.user !== undefined){
      if (this.props.user.mname !== null) {
        name = this.props.user.fname + ' ' + this.props.user.mname + ' ' + this.props.user.lname;
      } else {
        name = this.props.user.fname + ' ' + this.props.user.lname;
      }
      if (!name){
        return 'Gebruiker Verwijderen';
      }
      return `Gebruiker ${name} verwijderen`
    }  
  }

  renderContent() {
    let name='';
    if (this.props.user !== undefined){
      if (this.props.user.mname !== null) {
        name = this.props.user.fname + ' ' + this.props.user.mname + ' ' + this.props.user.lname;
      } else {
        name = this.props.user.fname + ' ' + this.props.user.lname;
      }
      if (!name){
        return 'Wilt u deze gebruiker verwijderen?';
      }
      return `Weet u zeker dat u gebruiker ${name} wilt verwijderen?`;
    }
  }

  render(){
    return (
          <Modal 
            title={this.renderTitle()}
            content={this.renderContent()}
            actions={this.renderActions()}
            onDismiss={() => history.push(`/users/list/${this.props.page.activePage}`)}
          />
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.users[ownProps.match.params.id],
    currentUser: state.currentUser,
    page: state.pages
  } 
};

export default connect(mapStateToProps, { fetchUser, deleteUser })(UserDelete);