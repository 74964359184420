import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

const CookieStatement = () => {
  return ReactDOM.createPortal(
    <div className='ui container'>
      <label>
        <h2>COOKIEVERKLARING</h2>
        <h4>1. Het gebruik van cookies</h4>
        <i><b>www.bwg-online.nl</b> 'de beleidswaarde generator online' </i>
        maakt gebruik van cookies. Een cookie is een eenvoudig klein bestandje dat met pagina's van deze website wordt meegestuurd en door je browser op de harde schijf van je computer, mobiele telefoon, smart watch of tablet wordt opgeslagen.
        <br />
        De daarin opgeslagen informatie kan bij een volgend bezoek weer naar onze servers toegestuurd worden.
        <br />
        Het gebruik van cookies is van groot belang vor het goed laten werken van onze website, maar ook cookies waarvan je niet direct het effect ziet zijn zeer belamgrijk. Dankzij de (anonieme) input van bezoekers kunnen we het gebruik van de website verbeteren en deze gebruiksvriendelijker maken.
        <br />

        <h4>2. Toestemming voor het gebruik van cookies</h4>
        Voor het gebruik van bepaalde cookies is jouw toestemming vereist. Dit doen wij door middel van een zogenaamde cookiebanner.
        <br />
        <h4>3. Type cookies en hun doelstellingen</h4>
        Wij gebruiken de volgende type cookies:
        <br />
        <ul>
          <li>Functionele cookies: hiermee kunnen we de website beter laten functioneren en is die gebruiksvriendelijker voor de bezoeker. Bijvoorbeeld: we slaan je inloggegevens op zodat je bij het volgende bezoek deze niet hoeft in te voeren.</li>
          <li>Site verbetering cookies/optimalisatie cookies: hiermee kunnen we verschillende versies op een webpagina testen om te kijken welke pagina's het beste worden bezocht om hiermee de werking van de site en de gebruiksvriendelijkheid te verbeteren.</li>
        </ul> 
        <h4>4. Je rechten met betrekking tot je gegevens</h4>
        Je hebt recht op inzage, rectificatie, beperking en verwijdering van persoonsgegevens. Daarnaast heb je recht van bezwaar tegen verwerking van persoonsgegevens en recht op gegevensoverdraagbaarheid. Je kunt deze rechten uitoefenen door ons een mail te sturen via <a href='mailto:info@bwg-online.nl' target='_blank' rel='noreferrer'>info@bwg-online.nl</a>.
        <br />
        Om misbruik te voorkomen kunnen wij je daarbij vragen om je adequaat te identificeren. Wanneer het gaat om inzage in persoonsgegevens gekoppeld aan een cookie, vragen we je een kopie van het cookie in kwestie mee te sturen. Je kunt deze terugvinden in de instellingen van je browser.
        <br />
        <h4>5. Cookies blokkeren en verwijderen</h4>
        Je kunt cookies te allen tijde eenvoudig zelf blokkeren en verwijderen via je internetbrowser. Ook kun je je internetbrowser zodanig instellen dat je een bericht ontvangt als er een cookie wordt geplaatst. Je kunt ook aangeven dat bepaalde cookies niet geplaatst mogen worden. Bekijk voor deze mogelijkheid de helpfunctie van je browser. Als je de cookies in je browser verwijdert, kan dat gevolgen hebben voor het prettige gebruik van deze website.
        <br />
        Sommige tracking cookies worden geplaatst door derden die onder meer via onze website advertenties aan je vertonen. Deze cookies kan je centraal verwijderen via <a href='https://www.youronlinechoices.com' target='_blank' rel='noreferrer'> youronlinechoices.com</a>.
        <br />
        Wees je er wel van bewust dat als je geen cookies wilt, wij niet meer kunnen garanderen dat onze website helemaal goed werkt. Het kan zijn dat enkele functies van de site verloren gaan of zelfs dat je de website helemaal niet meer kunt bezoeken. Daarnaast betekent het weigeren van cookies ook niet dat je helemaal geen advertenties meer te zien krijgt. De advertenties zijn dan alleen niet meer toegesneden op jouw interesses en kunnen daarom vaker worden herhaald.
        <br />
        <br />
        Hoe je je instellingen kunt aanpassen verschilt per browser. Raadpleeg indien nodig de helpfunctie van jouw browser, og klik op één van de onderstaande links op direct naar de handleiding van je browser te gaan:
        <ul>
          <li><a href='https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen' target='_blank' rel='noreferrer'>Firefox</a></li>
          <li><a href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&hl=nl' target='_blank' rel='noreferrer'>Google Chrome</a></li>
          <li><a href='https://support.microsoft.com/nl-nl/kb/278835' target='_blank' rel='noreferrer'>Internet Explorer</a></li>
          <li><a href='https://support.apple.com/nl-nl/HT201265' target='_blank' rel='noreferrer'>Safari op smart phone</a></li>
          <li><a href='https://support.apple.com/nl-be/guide/safari/sfri11471/mac' target='_blank' rel='noreferrer'>Safari op Mac</a></li>
        </ul> 
        <h4>6. Nieuwe ontwikkelingen en onvoorziene cookies</h4>
        De teksten van onze website kunnen op ieder moment worden aangepast door voortdurende ontwikkelingen. Dit geldt ook voor onze cookieverklaring. Neem deze verklaring daarom regelmatig door om op de hoogte te blijven van eventuele wijzigingen.
        <br />
        In blogartikelen kan gebruik worden gemaakt van content die op andere sites wordt gehost en op <a href='mailto:info@bwg-online.nl' target='_blank' rel='noreferrer'>info@bwg-online.nl</a> wordt ontsloten door middel van bepaalde codes (embedded content). Denk hierbij aan bijvoorbeeld YouTube video's. Deze codes maken vaak gebruik van cookies. Wij hebben echter geen controle op wat deze derde partijen met hun cookies doen.
        <br />
        Het kan voorkomen dat via onze websites cookies worden geplaatst door anderen, waarvan wij zelf niet altijd op de hoogte zijn. Kom je op onze website onvoorziene cookies tegen die je niet kunt terugvinden in ons overzicht? Laat het ons weten via <a href='mailto:info@bwg-online.nl' target='_blank' rel='noreferrer'>info@bwg-online.nl</a>. Je kan ook rechtstreeks contact opnemen met de derde partij en vraag welke cookies zij plaatsen, wat de reden daarvoor is, wat de levensduur van de cookie is en op welke manier ze je privacy gewaarborgd hebben.
        <br />
        <h4>7. Slotopmerkingen</h4>
        Wij zullen deze verklaring af en toe aan moeten passen, bijvoorbeeld wanneer we onze website aanpassen of wanneer de regels rondom cookies wijzigen. Je kunt deze webpagina raadplegen voor de laatste versie.
        <br />
        Mocht je nog vragen en/of opmerkingen hebben neem dan contact met ons op via <a href='mailto:info@bwg-online.nl' target='_blank' rel='noreferrer'>info@bwg-online.nl</a>.
        <br />
        <br />
        Versie 1.0, 18 april 2021
        </label>

        <div>
          <Link to='/' className="ui button">Terug</Link> 
        </div>
        
      </div>,
      document.querySelector('#modal')
  );
}

export default CookieStatement;