export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SHOW_PASSWORD = "SHOW_PASSWORD";
export const SHOW_PHONENUMBER = "SHOW_PHONENUMBER";
export const FETCH_ACTIVE_VALUES = "FETCH_ACTIVE_VALUES";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_ACTIVE_USERS = "FETCH_ACTIVE_USERS";
export const FETCH_USER = "FETCH_USER";
export const EDIT_USER = "EDIT_USER";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const SELECTED_CITY = "SELECTED_CITY";
export const SELECTED_ROLE = "SELECTED_ROLE";
export const SELECTED_MENU = "SELECTED _MENU";
export const FETCH_CITIES = "FETCH_CITIES";
export const UPDATE_USER_RIGHTS = "UPDATE_USER_RIGHTS";
export const SET_NEW_USER = "SET_NEW_USER";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const RESET_CURRENT_USER = "RESET_CURRENT_USER";
export const UPDATE_NEW_USER = "UPDATE_NEW_USER";
export const SELECTED_ITEMS_PER_PAGE = "SELECTED_ITEMS_PER_PAGE";
export const GET_PAGINATION = "GET_PAGINATION";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SEND_CODE = "SEND_CODE";
export const SET_DIMENSIONS = "SET_DIMENSIONS";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const FETCH_DOCUMENT = "FETCH_DOCUMENT";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const FETCH_ACTIVE_DOCUMENTS = "FETCH_ACTIVE_DOCUMENTS";
export const SET_TAB = "SET_TAB";
export const FETCH_DOMAINS = "FETCH_DOMAINS";
export const SET_PRIMARY_DOMAIN = "SET_PRIMARY_DOMAIN";
export const SET_SECUNDARY_DOMAIN = "SET_SECUNDARY_DOMAIN";
export const ADD_DOMAIN = "ADD_DOMAIN";
export const FETCH_THEMES = "FETCH_THEMES";
export const SET_THEME = "SET_THEME";
export const ADD_THEME = "ADD_THEME";
export const SET_TYPE = "SET_TYPE";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const FETCH_VALUES = "FETCH_VALUES";
export const FETCH_VALUE = "FETCH_VALUE";
export const DELETE_VALUE = "DELETE_VALUE";
export const CREATE_VALUE = "CREATE_VALUE";
export const EDIT_VALUE = "EDIT_VALUE";
export const ADD_MULTIPLESELECTBOX_VALUES = "ADD_MULTIPLESELECTBOX_VALUES";
export const DEL_MULTIPLESELECTBOX_VALUES = "DEL_MULTIPLESELECTBOX_VALUES";
export const RESET_MULTIPLESELECTBOX_VALUES = "RESET_MULTIPLESELECTBOX_VALUES";
export const SET_ORIGIN = "SET_ORIGIN";
export const SET_INTERPRETATION = "SET_INTERPRETATION";
export const SET_TOPIC = "SET_TOPIC";
export const SEND_MAIL = "SEND_MAIL";
export const RESET_USERS = "RESET_USERS";
export const FETCH_PROFILES = "FETCH_PROFILES";
export const CHANGE_PASS = "CHANGE_PASS";
export const FETCH_DELETED_USERS = "FETCH_DELETED_USERS";
export const FETCH_DELETED_DOCUMENTS = "FETCH_DELETED_DOCUMENTS";
export const FETCH_DELETED_VALUES = "FETCH_DELETED_VALUES";
export const SET_USER_ARRAY_DELETE = "SET_USER_ARRAY_DELETE";
export const SET_DOCUMENT_ARRAY_DELETE = "SET_DOCUMENT_ARRAY_DELETE";
export const SET_VALUE_ARRAY_DELETE = "SET_VALUE_ARRAY_DELETE";
export const FETCH_ACTIVE_DELETION_VALUES = "FETCH_ACTIVE_DELETION_VALUES";
export const FETCH_ACTIVE_DELETION_DOCUMENTS =
	"FETCH_ACTIVE_DELETION_DOCUMENTS";
export const FETCH_ACTIVE_DELETION_USERS = "FETCH_ACTIVE_DELETION_USERS";
export const READ_ALL_URLS = "READ_ALL_URLS";
export const SET_ACCORDION_ID = "SET_ACCORDION_ID";
export const RANDOM_PASSWORD = "RANDOM_PASSWORD";
export const SET_DOC_CHOICE = "SET_DOC_CHOICE";
export const SET_DEL_ARRAY = "SET_DEL_ARRAY";
export const FETCH_NOTCOUPLED_VALUES = "FETCH_NOTCOUPLED_VALUES";
export const SET_VALUE_ARRAY_NOTCOUPLED = "SET_VALUE_ARRAY_NOTCOUPLED";
export const SEARCH_ALL = "SEARCH_ALL";
