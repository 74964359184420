import React from 'react';
import { connect } from 'react-redux';
import password from 'secure-random-password';
import Copyright from '../components/Copyright';
import NewAccountForm from '../login/NewAccountForm';
import history from '../history';
import { setDimensions, sendMail } from '../actions';
import { newAccount } from '../actions/userActions';

class NewAccount extends React.Component {

  backgroundImage = `url(${process.env.PUBLIC_URL}/images/background3.jpg)`;

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };

  onSubmit = (formValues) => {
    const username = (formValues.fname.substring(0,2) + formValues.lname.substring(0,3)).toLowerCase();
    const pass = password.randomPassword({ characters: password.lower + password.upper + password.digits });
    let mname = '';
    if (formValues.mname !== undefined){ mname = formValues.mname };
    formValues = { ...formValues, role:"Gebruiker", rights:"01000100000000000000", username: username, mname: mname, phonenumber: formValues.phone,  newAccount: true, password: pass };
    this.props.newAccount(formValues);

    const mail = {fname: formValues.fname, mname: formValues.mname, lname: formValues.lname, email: formValues.email, pass: pass};
    this.props.sendMail(mail);
    history.push({pathname: '/', email: formValues.email });
}

  renderForm = () => {
    return (
      <div style={{ width:this.props.dimensions.width, height: this.props.dimensions.height, backgroundImage: this.backgroundImage, opacity:'0,9' }}>
        <NewAccountForm
          width={this.props.dimensions.width}
          height={this.props.dimensions.height}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderForm()}
        <Copyright color='white' />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dimensions: state.dimensions
  }
}

export default connect(mapStateToProps, { setDimensions, newAccount, sendMail })(NewAccount)