import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import history from "../../history";
import { setDimensions, setActiveMenu } from "../../actions";
import { fetchValue } from "../../actions/valueActions";
import Header from "../Header";

class ValueShow extends React.Component {
	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
		this.props.setActiveMenu("3");
		this.props.fetchValue(this.props.match.params.id);
	}

	updateDimensions = () => {
		this.props.setDimensions({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	renderWithUpperCase = (val) => {
		const withUpperCase = val.charAt(0).toUpperCase() + val.slice(1);
		return withUpperCase;
	};

	renderButton = () => {
		if (this.props.currentUser.rights.charAt(6) === "1") {
			return (
				<div
					className="right floated content"
					style={{ display: "inline-block" }}>
					<Link
						className="ui small primary labeled icon button"
						to={`/values/edit/${this.props.value.value_id}`}>
						<i className="pencil alternate icon"></i>Wijzigen
					</Link>
				</div>
			);
		}
	};

	getBack = () => {
		history.push(`/values/list/${this.props.pages.activePage}`);
	};

	render() {
		const {
			bondage,
			description,
			doc_type,
			document_id,
			document_name,
			interpretation,
			ow_29,
			ow_210,
			qual_quan,
			reference,
			status,
			topic,
		} = this.props.value;
		return (
			<div
				style={{
					width: this.props.dimensions.width,
					height: this.props.dimensions.height,
					background:
						"linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,179,165,0.48082983193277307) 100%)",
				}}
				className="ui container">
				<div className="ui container">
					<Header location={this.props.location.pathname} />
					<h4 className="ui dividing header" style={{ marginTop: "0" }}>
						&nbsp;
					</h4>
					<div>
						<h4>{this.renderWithUpperCase(topic)}</h4>
					</div>
					<div style={{ marginTop: "1em", verticalAlign: "Top" }}>
						<h5>Omschrijving</h5>
						{description}
					</div>
					<div
						style={{
							display: "inline-block",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5 style={{ marginBottom: "0" }}>Type</h5>
						<Link to={`/documents/${document_id}`}>{doc_type}</Link>
					</div>
					<div
						style={{
							display: "inline-block",
							marginLeft: "2em",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5 style={{ marginBottom: "0" }}>Document</h5>
						<Link to={`/documents/${document_id}`}>{document_name}</Link>
					</div>
					<div
						style={{
							display: "inline-block",
							marginLeft: "2em",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5>Interpretatie</h5>
						{interpretation}
					</div>
					<div
						style={{
							display: "inline-block",
							marginLeft: "2em",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5>Gebondenheid</h5>
						{bondage}
					</div>
					<h4 className="ui dividing header" style={{ marginTop: "0" }}>
						&nbsp;
					</h4>
					<div
						style={{
							display: "inline-block",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5>Kwalitatief/Kwantitatief</h5>
						{qual_quan}
					</div>
					<div
						style={{
							display: "inline-block",
							marginLeft: "2em",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5>OW-2.9</h5>
						{ow_29}
					</div>
					<div
						style={{
							display: "inline-block",
							marginLeft: "2em",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5>OW-2.10</h5>
						{ow_210}
					</div>
					<div
						style={{
							display: "inline-block",
							marginLeft: "2em",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5>Status</h5>
						{status}
					</div>
					<div
						style={{
							display: "inline-block",
							marginLeft: "2em",
							marginTop: "1em",
							verticalAlign: "Top",
						}}>
						<h5>Referentie</h5>
						{reference}
					</div>
					<h4 className="ui dividing header" style={{ marginTop: "-10px" }}>
						&nbsp;
					</h4>
					<div>
						<div style={{ display: "inline-block" }}>
							{this.renderButton()}
							<div
								style={{ display: "inline-block", marginLeft: "1em" }}
								className="ui button"
								onClick={this.getBack}>
								Terug
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		dimensions: state.dimensions,
		value: state.values[ownProps.match.params.id],
		pages: state.pages,
		currentUser: state.currentUser,
	};
};

export default connect(mapStateToProps, {
	setDimensions,
	setActiveMenu,
	fetchValue,
})(ValueShow);
