import CryptoJS from 'crypto-js';

//The key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
const passphrase = 'Sup3rC@1iFr@g1isticXpi@1iD0ci0uS';

var CryptoJSAesJson = {
  stringify: function (cipherParams) {
      var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
  },
  parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams;
  }
}

export function encryptData(values) {
  return CryptoJS.AES.encrypt(JSON.stringify(values), passphrase, {format: CryptoJSAesJson}).toString();
} 

export function decryptData(values, arr=0){

  if (arr === 3){
    return JSON.parse(CryptoJS.AES.decrypt(values, passphrase, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
  }
  var encrypted = values.ct;
  var salt = CryptoJS.enc.Hex.parse(values.salt);
  var iv = CryptoJS.enc.Hex.parse(values.iv);   

  var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 569});

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});
  let data = null;
  if (arr === 1){ 
    data = JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted));
  } else {
    data = decrypted.toString(CryptoJS.enc.Utf8);
  } 

  return data
}