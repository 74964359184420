import values from '../apis/values';
import history from '../history';
import _ from 'lodash';

import { 
  FETCH_VALUES,
  FETCH_VALUE,
  DELETE_VALUE,
  CREATE_VALUE,
  EDIT_VALUE,
  SET_VALUE_ARRAY_DELETE,
  FETCH_DELETED_VALUES,
  FETCH_NOTCOUPLED_VALUES,
  SET_VALUE_ARRAY_NOTCOUPLED
} from './types';

const debugmode = process.env.REACT_APP_DEBUGMODE;
console.log('debugmode=' + debugmode);

export const fetchValues = () => async dispatch => _fetchValues(dispatch);
  const _fetchValues = _.memoize(async (dispatch) => {
  const response = await values.get(`read_all.php?debugmode=${debugmode}`);

  dispatch({ type: FETCH_VALUES, payload: response.data});
});

export const coupleValue = (vals) => async dispatch => {
  const activePage = vals.activepage;
  const data = { ...vals, debugmode: debugmode, couple: true };
  console.log(data);
  const response = await values.post(`update.php`, data);

  if (response.status === 200){
    dispatch(getCoupledValues());
  } else {
    console.log('Waarden niet gekoppeld');
  }
  history.push(`/beheer/waarden/koppelen/list/${activePage}`);
}

export const fetchAllValues = () => async (dispatch) => {
  const response = await values.get(`read_all.php?debugmode=${debugmode}`);

  dispatch({ type: FETCH_VALUES, payload: response.data });
}

export const fetchValue = (id) => async dispatch => _fetchValue(id, dispatch);
const _fetchValue = _.memoize(async(id, dispatch) => {
  const response = await values.get(`read_one.php?id=${id}`);

  dispatch({ type: FETCH_VALUE, payload: response.data })
});

export const editValue = (id, value_id, formValues, activePage) => async dispatch => {
  const response = await values.post(`/update.php?id=${value_id}`, formValues);
 
  dispatch({ type: EDIT_VALUE, payload: {...response.data, ind:id }});
  history.push(`/values/list/${activePage}`);
};

export const deleteValue = (id, value_id, unique_id, activePage) => async dispatch => {
  await values.post(`delete.php?value_id=${value_id}`, { value_id: value_id, unique_id: unique_id });

  dispatch({ type: DELETE_VALUE, payload: id });
  history.push(`/values/list/${activePage}`);
};

export const createValue = (formValues, activePage) => async dispatch => {
  const unique_id = formValues.unique_id;
  const response = await values.post(`create.php?id=${unique_id}`, formValues);

  dispatch({ type: CREATE_VALUE, payload: response.data });
  history.push(`/values/list/${activePage}`);
};

export const resetIndex = () => async dispatch => {
  const response = await values.get(`read_all.php`);

  dispatch({ type: FETCH_VALUES, payload: response.data});
};

export const getDeletedValues = () => async dispatch => {
  const response = await values.get(`/read_deleted.php?debugmode=${debugmode}`);

  if (response.status === 200){
    dispatch({ type: FETCH_DELETED_VALUES, payload: response.data });
  } else {
    dispatch({ type: FETCH_DELETED_VALUES, payload: null });
  }
  dispatch({ type: SET_VALUE_ARRAY_DELETE, payload: [] });
  
};

export const getCoupledValues = () => async dispatch => {
  const response = await values.get(`/read_coupled.php?debugmode=${debugmode}`);

  if (response.status === 200){
    dispatch({ type: FETCH_NOTCOUPLED_VALUES, payload: response.data });
  } else {
    dispatch({ type: FETCH_NOTCOUPLED_VALUES, payload: null });
  }
  dispatch({ type: SET_VALUE_ARRAY_NOTCOUPLED, payload: [] });
  
};

export const setArrDelete = (arr) => {
  return {
    type: SET_VALUE_ARRAY_DELETE,
    payload: arr
  }
};

export const setArrNotCoupled = (arr) => {
  return {
    type: SET_VALUE_ARRAY_NOTCOUPLED,
    payload: arr
  }
};

export const deleteValueFinal = (arr) => async dispatch => {
  const data = { arr: arr, debugmode: debugmode, no_arr: false };
  const response = await values.post(`/delete.php`, data);

  if (response.status === 200){
    dispatch({ type: FETCH_DELETED_VALUES, payload: response.data });
  } else {
    dispatch({ type: FETCH_DELETED_VALUES, payload: null });
  }
  dispatch({ type: SET_VALUE_ARRAY_DELETE, payload: [] });
  history.push(`/beheer/dashboard`);
}

export const resetValue = (arr) => async dispatch => {
  const data = { arr: arr, debugmode: debugmode };
  const response = await values.post(`/update.php`, data);

  if (response.status === 200){
    dispatch({ type: FETCH_DELETED_VALUES, payload: response.data });
  } else {
    dispatch({ type: FETCH_DELETED_VALUES, payload: null });
  }
  dispatch(fetchAllValues());
  dispatch({ type: SET_VALUE_ARRAY_DELETE, payload: [] });
  history.push(`/beheer/dashboard`);
}
