/* eslint-disable import/no-anonymous-default-export */

import  { 
  READ_ALL_URLS,
} from '../actions/types';
const INIT_STATE = [{
  root_url: '',
}]


//Edit: Object-based approach
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_ALL_URLS:
      return { ...state, ...action.payload}
    default:
      return state;
  }
};