import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';

let store = {};
if (process.env.NODE_ENV === 'development') {
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    reducers, 
    composeEnhancers(applyMiddleware(thunk))
  );
} else {
  store = createStore(
    reducers, 
    applyMiddleware(thunk)
  );
}

export default store;


// import thunk from 'redux-thunk';
// import { createStore, applyMiddleware, compose } from 'redux';
// import reducers from './reducers';

//PRODUCTIE
// const store = createStore(
//   reducers, 
//   applyMiddleware(thunk)
// );


//DEVELOPMENT
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   reducers, 
//   composeEnhancers(applyMiddleware(thunk))
// );