import React from 'react';
import { connect } from 'react-redux';
import Dropdown from './Dropdown';
import { setSelectedItemsPerPage, setPagination } from '../../actions';
import history  from '../../history';


class Pagination extends React.Component {
  componentDidMount(){
    this.props.setPagination(this.props.category,1,this.props.pages.selectedItemsPerPage)
  }

  renderPagination = () => {
    let pagination = []
    let i = 1;
    const firstPage = 1;
    const lastPage = parseInt(this.props.pages.pages);
    const middlePage =Math.ceil(parseInt(this.props.pages.pages)/2);

    if (this.props.pages.pages > 10){
      for (i; i<=this.props.pages.pages; i++ ){

        if (firstPage === parseInt(this.props.pages.activePage) && firstPage === i) {
           pagination[i] = ('active item');
           pagination[i+3] = ('disabled item');
           pagination[middlePage+1] = ('disabled item');
        } 
        else if (parseInt(this.props.pages.activePage) !== firstPage && parseInt(this.props.pages.activePage) !== lastPage  && this.props.pages.activePage === String(i)) {
          pagination[i] = ('active item');
          if (i > 4) pagination[i-3] = ('disabled item');
          if(i < lastPage-4) pagination[i+3] = ('disabled item');
          if(i <= middlePage-3 ) pagination[middlePage+1] = ('disabled item');
          if(i >= middlePage+3 ) pagination[middlePage-1] = ('disabled item');
        }
        else if ( lastPage === parseInt(this.props.pages.activePage) && lastPage === i) {
        pagination[i] = ('active item');
        pagination[i-3] = ('disabled item');
        pagination[middlePage-1] = ('disabled item');
        }
        else if (middlePage !== this.props.pages.activePage && middlePage === i){
          pagination[i] = ('item');
        }
        else if (
          firstPage === i || 
          parseInt(this.props.pages.activePage) -2 === i || 
          parseInt(this.props.pages.activePage) -1 === i || 
          parseInt(this.props.pages.activePage) + 1 === i || 
          parseInt(this.props.pages.activePage) + 2 === i || 
          lastPage === i) {
          pagination[i] = ('item');
        }
      }
    } else {
      for (i; i<=this.props.pages.pages; i++ ){ 
        if (this.props.pages.activePage === String(i)) {
            pagination[i] = ('active item');
        } else {
          pagination[i] = ('item');
        }
      }
    }

    const links = pagination.map((className,index) => {
      if (className === 'disabled item'){
        return (
          <div key={index} id={index} className={className}>
            ...
          </div>);
      }
      return (
        <div key={index} style={{ cursor: 'pointer' }} id={index} className={className} onClick={(e) => this.setNewPage(e)}>
          {index}
        </div>);
    });

    let paginate = '';
    if (this.props.currentUser.rights.charAt(12) === '1' && this.props.menu === '5'){
      if (links.length > 2) {
      paginate = 
      <div style={{ marginTop: '0.5em', top: '2.25em', position:'relative' }} className="ui borderless pagination menu">
        {links}
      </div>
      } else {
        paginate = 
        <div style={{ marginTop: '0.5em', top: '5em', position:'relative' }} className="ui borderless pagination menu">
          {links}
        </div>
      }
    } else { 
      if (this.props.currentUser.rights.charAt(0) === '1' || this.props.currentUser.rights.charAt(4) === '1' || this.props.currentUser.rights.charAt(16) === '1' ){
        paginate = 
        <div style={{ marginTop: '0.5em', top: '5em', position:'relative' }} className="ui borderless pagination menu">
          {links}
        </div>
        } else {
        paginate = 
        <div style={{ marginTop: '1em', position:'relative' }} className="ui borderless pagination menu">
          {links}
        </div>
      }   
    }
    return paginate
  }

  setNewPage = (e) => {
   const page = e.target.id
    this.props.setPagination(this.props.category, page, this.props.pages.selectedItemsPerPage);
    history.push(`/${this.props.category}/list/${page}`);
  }

  changeSelection = (page) => {
    this.props.setPagination(this.props.category,'1',page.value)
  };

 setSelected = () => {
    if (!this.props.pages.selectedItemsPerPage){
      return {value: "5"}
    }
    return { value:this.props.pages.selectedItemsPerPage};
  }

  setStyle = () => {
    //createDocument
    if (this.props.currentUser.rights.charAt(0) === '1'){
      return ( {
        position: 'relative',
        marginBottom: '1em'
      } );
    }

  }

  renderDropdown = () => {
    const items= [
      {key: "1", text: "5", value: "5"}, 
      {key: "2", text: "10", value: "10"}, 
      {key: "3", text: "25", value: "25"}, 
      {key: "4", text: "100", value: "100"}
    ];

    const dropdown = 
        <Dropdown 
        label={'Aantal items per pagina'} 
        options={items} 
        selected={this.setSelected()}
        onSelectedChange={(setSelectedItemsPerPage) => this.changeSelection(setSelectedItemsPerPage)}
        image='false'
        />;

    //createDocument
    if (this.props.currentUser.rights.charAt(0) === '1' || this.props.currentUser.rights.charAt(4) === '1' || this.props.currentUser.rights.charAt(16) === '1'){
      return (
        <div style={{ position: 'relative', textAlign:'top', height: '1em', top: '-2em' }}>
          {dropdown}
        </div>
      );      
    } else {
      return (
        <div style={{ position: 'relative', marginTop: '0.5em' }}>
          {dropdown}
        </div>
      );
    }
  }
  render(){
    // let bottom = '0px';
    // if (this.props.menu === "1"){
    //   this.props.currentUser.rights.charAt(4) === "1" ? bottom = '0px' : bottom = '37px';
    // } else if(this.props.menu === "2"){
    //     this.props.currentUser.rights.charAt(8) === "1" ? bottom = '0px' : bottom = '37px';
    // } else if (this.props.menu === "3"){
    //     this.props.currentUser.rights.charAt(12) === "1" ? bottom = '0px' : bottom = '37px';
    // } else if (this.props.menu === "4"){
    //     this.props.currentUser.rights.charAt(16) === "1" ? bottom = '4px' : bottom = '37px';
    // } else if (this.props.menu === "5"){
    //     this.props.deletedUser.length < 2 ? bottom = '4px' :  bottom = '37px';
    //     this.props.deletedDocument.length < 2 ? bottom = '4px' :  bottom = '37px';
    //     this.props.deletedValue.length < 2 ? bottom = '4px' :  bottom = '80px';
    // };

    return(
      <div className='left floated content' style={{ marginBottom:'0.5em'}}>
        {this.renderDropdown()}  
        {this.renderPagination()}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    currentUser: state.currentUser, 
    menu: state.selections.activeMenu
  }
}

export default connect(mapStateToProps, { setSelectedItemsPerPage, setPagination })(Pagination);