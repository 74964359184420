/* eslint-disable import/no-anonymous-default-export */
import { SET_DIMENSIONS } from '../actions/types';
const INIT_STATE = {
  height: 800,
  width: 1200
};

export default (state = INIT_STATE, action) => {
  switch(action.type){
    case SET_DIMENSIONS:
      return { ...state, ...action.payload };
    default: 
    return state;
  };
};