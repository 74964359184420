import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {  setActiveMenu, setSelectedRole, setDimensions, fetchCities } from '../../actions';
import { fetchUser, editUser } from '../../actions/userActions';
import UserForm from './UserForm';
import Dropdown from '../elements/Dropdown';
import Header from '../Header';

class UserEdit extends React.Component {
  
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('4');
    this.props.fetchUser(this.props.match.params.id);
    this.props.fetchCities();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  onSubmit = (formValues, activePage) => {
    this.props.editUser(formValues, activePage);
  };

  changeSelection = (role) => {
    var rights = '';
    if(role.value === "Gebruiker") rights="01000100010001000100"; 
    if(role.value === "Beheerder") rights="11101110010001000100";
    if(role.value === "Admin") rights="11111111111111110100";
    if(role.value === "SuperAdmin") rights="11111111111111111111";
    this.props.setSelectedRole(role, this.props.user, rights);
  }
  
  renderDropdown = () =>{
    const roles= [
      {key: "1", text: "User", value: "Gebruiker"}, 
      {key: "2", text: "SuperUser", value: "Beheerder"}, 
      {key: "3", text: "Admin", value: "Admin"}, 
      {key: "4", text: "SuperAdmin", value: "SuperAdmin"}
    ];
    const sel = {value: this.props.user.role}
    return(
      <Dropdown 
        label='' 
        options={roles}
        selected={sel} 
        onSelectedChange={(setSelectedRole) => this.changeSelection(setSelectedRole)}
        image='false'
      />
    );
  }

  render() {
    if(!this.props.user) {
      return (
        <div className="ui segment">
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading...</div>
        </div>
      </div>
      );
    }
    const initialValues = _.pick(this.props.user, 'fname', 'mname', 'lname', 'city_oin', 'city_name', 'email', 'role', 'username', 'password', 'phonenumber', 'blackednumber', 'ind', 'id');
    return (
      <div 
      style={{ 
        width:this.props.dimensions.width, 
        height: this.props.dimensions.height,  
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(175,244,165,0.48082983193277307) 100%)'
      }}
      className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h3>Gebruikergegevens wijzigen:</h3>
          <h4 className="ui dividing header">
            <div>
              <div style={{ display: "inline-block" }} className="ui content">
                Gebruiker #{this.props.user.unique_id}
              </div>
              <div style={{ float:"right", marginTop: "-43px" }}>
                Rol: {this.renderDropdown()}
              </div>
            </div>
          </h4>
          <UserForm
            ind= {this.props.user.ind}
            userid= {this.props.user.id} 
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            name='Wijzigen' 
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.users[ownProps.match.params.id],
    dimensions: state.dimensions,
    cities: state.cities
  }
};

export default connect(mapStateToProps, { fetchUser, editUser, setActiveMenu, setSelectedRole, setDimensions, fetchCities })(UserEdit);