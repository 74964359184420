import React from 'react';
import Modal from '../../modal';
import history from '../../history';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchValue, deleteValue } from '../../actions/valueActions';

class ValueDelete extends React.Component {

  renderActions() {
    const id = this.props.match.params.id;
    return (
      <React.Fragment>
        <button  
          onClick={() => this.props.deleteValue(id, this.props.value.value_id, this.props.currentUser.unique_id, this.props.pages.activePage)} 
          className="ui negative button">
          Verwijderen
        </button>
        <Link to={`/values/list/${this.props.pages.activePage}`} className="ui button">Terug</Link>
      </React.Fragment>
    );
  }

  renderWithUpperCase = (val) => {
    const withUpperCase = val.charAt(0).toUpperCase() + val.slice(1);
    return withUpperCase;
  }

  renderTitle() {
    if (!this.props.value){
      return 'Verwijder Waarde';
    }
    return `Verwijder "${this.renderWithUpperCase(this.props.value.topic)}"?` 
  }

  renderContent() {
    if (!this.props.value){
      return 'Weet je zeker dat je deze waarde wilt verwijderen?';
    }
    return `Deze waarde heeft als omschrijving: "${this.props.value.description}". Weet je zeker dat je deze waarde wilt verwijderen?`;
  }

  render(){
    return (
          <Modal 
            title={this.renderTitle()}
            content={this.renderContent()}
            actions={this.renderActions()}
            onDismiss={() => history.push(`/values/list/${this.props.pages.activePage}`)}
          />
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pages: state.pages,
    value: state.values[ownProps.match.params.id],
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps, { fetchValue, deleteValue })(ValueDelete);