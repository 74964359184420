import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import history from '../history';

class WachtWoordForm extends React.Component {

  renderError ({ error, touched }){
    if(touched && error) {
      if (this.props.selectedCity.value === undefined) this.setState({hideLabel: false})
      return(
          <label className="ui below red basic label error">{error}</label>
      );
    }
  };

  renderInput= ({ input, label, meta, type, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    return (
      <React.Fragment>
        <div className={className}>
          <label>
            {label}
              <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
          </label>
        </div>
        {this.renderError(meta)}
      </React.Fragment>
    );
  };

  onSubmit = (formValues) => {
      this.props.onSubmit(formValues);
  }

  getBack = () => {
    history.push('/');
  }

  setLoginbox = () => {
    const w = Math.ceil(this.props.width/2);
    const h = Math.ceil(this.props.height/2);
    const t = Math.ceil(h/2);
    const l = Math.ceil(w/2);

    return (
      <div id='login' style={{ width: w, height: h, left:l, top:t, border:'2px solid #85BE1A', borderRadius:'6px', backgroundColor:'#FFFFEE', opacity:'1'  }}>
        <div style={{ marginTop:'2px' }} >
          <img src={process.env.PUBLIC_URL + "/images/BWG-online.png"} alt='BWG-online' style={{ float:'left', width: '15%', height: 'auto'}} />
          <h1 className='ui header' style={{ color:'#4EA619', textAlign:'center'}}>
            Login bij de beleidswaarden generator online!
          </h1>
        </div>
        <form name='wachtwoordForm' className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)} style={{ width:'90%' }}>
          
          <div style={{ position: 'relative', top:'-65px'}}>
            <h4>Wachtwoord vergeten:</h4>
            <Field name="email" label="Email" id="email" type="email" component={this.renderInput} />
          </div>
          <div className="ui below red basic label error" style= {{ position: 'relative', top:'-50px', display: `${this.props.err !== '' ? 'block' : 'none' }` }}>{this.props.err}</div>
            <button style={{ position: 'relative', top:'-45px'}} className="ui  olive button" type="submit">Verstuur</button>
            <button  style={{ position: 'relative', top:'-45px' }} className="ui  grey button" onClick={this.getBack}>Terug</button>
        </form>
        
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.setLoginbox()}
      </React.Fragment>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  var regexemail = new RegExp(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/);
  
  if (!regexemail.test(formValues.email)){
    errors.email = "Emailadres klopt niet";
  }

  if (!formValues.email) {
    errors.email = 'U dient een emailadres in te vullen';
  }

  return errors;
}

const mapStateToProps = (state) => {
  return{
    dimensions: state.dimensions
  }
}

export default connect(mapStateToProps)(reduxForm({ form: 'wachtwoordForm', validate: validate, enableReinitialize: true})(WachtWoordForm));
