/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
import  { 
  FETCH_USERS,
  FETCH_USER,
  EDIT_USER,
  DELETE_USER, 
  RESET_USERS,
  SET_NEW_USER,
  UPDATE_NEW_USER,
  SELECTED_ROLE,
  UPDATE_USER_RIGHTS,
  FETCH_ACTIVE_DELETION_USERS
} from '../actions/types';

const initialState = 
  {
    0:
    {
      id: 0,
      ind: 0,
      unique_id: 0,
      fname: '',
      mname: '',
      lname:'',
      city_oin:'Veenendaal',
      city_name:'0345',
      rights:'01000100010001000100',
      role:'user',
      email:'',
      phonenumber:'',
      blackednumber:'(0)6 ********',
      username:'', 
      password:''
    }
  };

  export default (state = initialState, action) => {

    switch (action.type) {
      case RESET_USERS:
        return initialState;
      case FETCH_USERS:
        return { ...state, ..._.mapKeys(action.payload, 'ind')};
      case FETCH_USER:
          return { ...state, [action.payload.ind]: action.payload };
      case EDIT_USER:
        return { ...state, [action.payload.ind]: action.payload };
      case DELETE_USER:
        return _.omit(state, [action.payload]);
      case SET_NEW_USER:
        return { ...state, [action.payload.ind]: action.payload };
      case UPDATE_NEW_USER:
        return { ...state, [action.payload.user.ind]: action.payload.user };
      case SELECTED_ROLE:
        return { ...state, [action.payload.user.ind]: action.payload.user };
      case UPDATE_USER_RIGHTS:
        return { ...state, [action.payload.user.ind]: action.payload.user };
      case FETCH_ACTIVE_DELETION_USERS:
        _.omit(state, state.users.deletedUsers);
        return { ...state, deletedUsers: { ..._.mapKeys(action.payload, 'id')}};      
      default:
        return state;
    }
  };