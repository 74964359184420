/* eslint-disable import/no-anonymous-default-export */
import { LOGIN, LOGOUT, SEND_CODE } from '../actions/types';
const initialState = {
  attempt: "",
  errors: "",
  loginId: "",
  succes: false,
  unique_id: "",
  response_code: 0
};

export default (state = initialState, action) => {
  switch(action.type){
    case LOGIN:
      return { ...state, ...action.payload };
    case SEND_CODE:
      return { ...state, ...action.payload };
    case LOGOUT:
      return initialState
    default: 
    return state;
  };
};