import React from 'react';
import AnimatedChart from '../elements/charts/AnimatedChart';

class PieChart extends React.Component {

  fetchChartData = () => {
    const themes = this.props.themes;
    let total =0;
    for(let i=0;i<themes.length; i++){
      total = total + themes[i].count;
    }
    const chartData = themes.map((theme) => {
      return { y: Math.ceil((theme.count/total)*100), label: theme.name };
    })
    const options = {
      animationEnabled: true,
      exportEnabled: false,
      theme: "light2", // "light1", "dark1", "dark2"
      backgroundColor: 'transparent',
      title:{
        text: "Percentages"
      },
      height: 270,
      width: 360,
      data: [{
        type: "pie",
        indexLabel: "{label}: {y}%",		
        startAngle: -90,
        dataPoints: chartData
      }]
    }
    return options;
  }

  render(){
    return(
      <AnimatedChart options={this.fetchChartData()} />
    );
  }
}

export default PieChart;