import React from 'react';
import history from './history';

const ErrorPage = () => {

const getBack = () => {
  console.log(history);
  history.goBack();
}

  return(
    <div className='ui container'>
      <img src={`${process.env.PUBLIC_URL}/images/404-error-page-template.jpg`} alt='Error 404: Page Not Found!' onClick={getBack}/>
    </div>
  );
};

export default ErrorPage