/* eslint-disable no-useless-escape */
import React from 'react';
import _ from 'lodash';
import password from 'secure-random-password';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import history from '../../history';
import { showPassword, setBlackedNumber, setSelectedCity, fetchCities, sendMail } from '../../actions';
import { fetchCurrentUser, updateNewUser } from '../../actions/userActions';
import Dropdown from '../elements/Dropdown';
import UserRights from './UserRights';

class UserForm extends React.Component{
  
  componentDidMount() {
    const cityArr = Object.values(this.props.cities);
    cityArr.map((city) => {
      if(this.props.initialValues !== undefined) {
        if (city.key === this.props.initialValues.city_oin) {
          this.props.setSelectedCity(city);
        }
      }
      return null;
    });
    if(this.props.name === "Opslaan") {
      this.props.setBlackedNumber('blackednumber');
    }
  }

  setBlackedNumber = () => {
    const val = document.getElementById('phone').value;
    if (val !== this.props.initialValues.phonenumber){
    }
    this.props.setBlackedNumber(this.props.blackedNumber);
  }

  setPasswordShown = () => {
    this.props.showPassword(this.props.passwordShown);
  }

  renderError ({ error, touched }){
    if(touched && error) {
      return(
          <label className="ui below red basic label error">{error}</label>
      );
    }
  }

  setRandomPassword = (formValues) => {
    const pass = password.randomPassword({ characters: password.lower + password.upper + password.digits });
    const items = {password: pass};
    this.props.updateNewUser(this.props.user, items);
  };

  renderInput= ({ input, label, meta, type, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    const lbl = () => {
      if (id === 'pass'){
        return (
          <React.Fragment>
            <div className= "ui action input">
              <div className="ui icon button" onClick={this.setPasswordShown}>
                <i className="eye icon"></i>
              </div>
              <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
              <div className="ui icon button" onClick={this.setRandomPassword}>
                <i className="plus square icon"></i>
              </div>
            </div>
            {this.renderError(meta)}
          </React.Fragment>
        );
      } else if (id === 'phone' && this.props.name === 'Wijzigen'){
          if (this.props.blackedNumber==="blackednumber") {
            return (
              <React.Fragment>
                <div className= "ui labeled action input">
                  <div className='ui label'>+31</div>
                  <input {... input} id={id} type={type} placeholder={label} autoComplete="off" disabled />
                  <div className="ui icon button" onClick={this.setBlackedNumber}>
                    <i className="eye icon"></i>
                  </div>
                </div>
                {this.renderError(meta)}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment>
              <div className= "ui labeled action input">
                <div className='ui label'>+31</div>
                <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
                <div className="ui icon button" onClick={this.setBlackedNumber}>
                  <i className="eye icon"></i>
                </div>
              </div>
              {this.renderError(meta)}
            </React.Fragment>
        );
      } else if (id === 'phone'){
        
        return (
          <React.Fragment>
            <div className= "ui labeled input">
              <div className='ui label'>+31</div>
              <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
            </div>
            {this.renderError(meta)}
          </React.Fragment>
      );
      }

      return (
        <React.Fragment>
          <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
          {this.renderError(meta)}
        </React.Fragment>
      );
    };

    return(
      <div className={className}>
        <label>{label}</label>
        {lbl()}
      </div>
    );
  }

  selectCityDropdown = () => {
    if(!_.isEmpty(this.props.selectedCity)){
      return (
        <Dropdown 
        label={'Selecteer een gemeente:'} 
        options={Object.values(this.props.cities)} 
        selected={this.props.selectedCity} 
        onSelectedChange={(setSelectedCity) => this.changeSelection(setSelectedCity)}
        image='true'
        />
      );
    }
  }

  getBack= () => {
    history.push(`/users/list/${this.props.pages.activePage}`);
  }

  changeSelection = (city) => {
    this.props.setSelectedCity(city);
  }

  onSubmit = (formValues) => {
    let pass = document.getElementById('pass').value;
    const values = {...this.props.user, ...formValues, city_name: this.props.selectedCity.value, city_oin: this.props.selectedCity.key, pass: pass, sendEmail: true};
    this.props.fetchCurrentUser(this.props.currentUser.unique_id);
    this.props.onSubmit(values,this.props.pages.activePage);
  }

  showLabel = () => {
     const lname= document.getElementById('lname');
     const fname = document.getElementById('fname');
     const prefill = document.getElementById('prefill');
     if (fname.value === '' || lname.value === '') {
      prefill.style.visibility = 'visible';
     }
  }

  hideLabel = () => {
    const prefill = document.getElementById('prefill');
    prefill.style.visibility = 'hidden';
  } 

  setPrefillButton = () => {
    if (this.props.name === "Opslaan") {
      return (
        <div style={{ float: "right", marginBottom: "1em" }}>
        <label id='prefill' style={{ visibility:"hidden" }} className="ui right pointing green basic label">Vul eerst de voor en achternaam in!</label>
          <div 
          className="ui green button" 
          style={{ float: "right", marginBottom: "1em" }} 
          onMouseOver={this.showLabel}
          onMouseOut={this.hideLabel}
          onClick={this.setPrefill}>Prefill</div>
        </div>
      );
    }
    return null;
  }

  setPrefill = () => {
    let fname = document.getElementById('fname').value;
    let mname = document.getElementById('mname').value; 
    let lname = document.getElementById('lname').value;
    let email='';
    const username = (fname.substring(0,2) + lname.substring(0,3)).toLowerCase();
    const cuEmail= this.props.currentUser.email;
    const city = this.props.currentUser.city_name;
    const oin = this.props.currentUser.city_oin;
    const ind = cuEmail.indexOf('@');
    const userEmail = cuEmail.substring(ind);
    const fnam = fname.replaceAll(' ', '.');
    const mnam = mname.replaceAll(' ', '.');
    const lnam = lname.replaceAll(' ', '.');
    if (mname === '') {
      email = (fnam + '.' + lnam + userEmail).toLowerCase();
    } else {
      email = (fnam + '.' + mnam + '.' + lnam + userEmail).toLowerCase();
    }
    const pass = password.randomPassword({ characters: password.lower + password.upper + password.digits });
    const items = { fname:fname, mname:mname, lname:lname, username:username, password:pass, email:email, city_name:city, city_oin:oin  };

    this.props.updateNewUser(this.props.user, items);
  }



  render() {
    const submit =this.props.handleSubmit(this.onSubmit);
    return(
      <div>
      <form  id="userForm" name="userForm" onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui fluid form error">
        <div className="field">
          <div className="three fields">
            <div className="seven wide field">
              <Field name="fname" id="fname" label="Voornaam" type="text" component={this.renderInput} />
            </div>
            <div className="two wide field">
              <Field name="mname" id="mname" label="Tussenvoegsel" type="text" component={this.renderInput} />
            </div>
            <div className="seven wide field">
              <Field name="lname" id="lname" label="Achternaam" type="text" component={this.renderInput} />
            </div>
          </div>
        </div>
        <div className="field">
          <div className="fields">
          <div className="eight wide field">
            <Field name="username" id="username" label="Gebruikersnaam" type="text" component={this.renderInput} />
          </div>
          <div className="eight wide field">
            <Field id ="pass" name="password" type={this.props.passwordShown ? "text" : "password"} label="Wachtwoord" component={this.renderInput} />
          </div>
          </div>
        </div>
        <h4 className="ui dividing header">Overige gegevens</h4>
        <div className="field">
          <div className="fields">
            <div className="six wide field">
              <Field name="email" label="Email" id="email" type="email" component={this.renderInput} />
            </div>
            <div className="six wide field">
              <Field name={this.props.blackedNumber} id="phone" label="Telefoonnummer" type="text" component={this.renderInput}  />
            </div>
            <div className="four wide field">
            {this.selectCityDropdown()}
            </div>
          </div>
          {this.setPrefillButton()}
        </div>
        <br />
      </form>  
      <div style={{ marginTop: "-3px"}}> 
        <UserRights ind={this.props.ind} />
      </div>
      <h4 className="ui dividing header" style={{ marginTop: "-10px"}} >&nbsp;</h4>
      <div>
        <div style={{ display: "inline-block" }}>
          <div className="ui small negative labeled icon button" onClick={event => { submit(event) }} >
            <i className="envelope outline icon"></i>
            {this.props.name} en verstuur Email
          </div>
        </div>
        <div  className="right floated content" style={{ display: "inline-block", float: "right" }}>
          <div className="ui button" style={{ marginLeft: "1em" }} onClick={this.getBack}>Terug</div>
        </div>
      </div>
    </div>
    );
  }
}

const validate = (formValues) => {
 
  const errors = {};
  var regexname = new RegExp(/^[a-z \.\-A-Z]+$/);
  var regexemail = new RegExp(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/);
  var regexphone = new RegExp(/^[0-9\b]+$/);

  if (!formValues.fname) {
    errors.fname = 'U dient een voornaam in te vullen';
  }

  if (formValues.fname !== undefined && formValues.fname !== null){
    if (!formValues.fname.match(regexname)) {
      errors.fname = 'Alleen letters gebruiken.';
    }
  }

  if (formValues.mname !== undefined && formValues.mname !== ''){
    if(!formValues.mname.match(regexname)){
       errors.mname = "Alleen letters!";
    }        
  }

  if (!formValues.lname) {
    errors.lname = 'U dient een achternaam in te vullen';
  }

  if (formValues.lname !== undefined){
    if (!formValues.lname.match(regexname)) {
      errors.lname = 'Alleen letters gebruiken';
    }
  }

  if (!formValues.username) {
    errors.username = 'U dient een gebruikersnaam opgeven!';
  }

  if (!formValues.password) {
    errors.password = 'Een wachtwoord is verplicht!';
  }

  if (!regexemail.test(formValues.email)){
    errors.email = "Emailadres klopt niet";
  }

  if (!formValues.email) {
    errors.email = 'U dient een emailadres in te vullen';
  }
  
  if (!formValues.phonenumber) {
    errors.phonenumber = 'U dient een correct mobielnummer in te vullen';
  }

  if (!regexphone.test(formValues.phonenumber)) {
    errors.phonenumber = 'U mag alleen cijfers invullen';
  }

  if (formValues.phonenumber !== undefined){
    if (formValues.phonenumber.length < 9 && formValues.phonenumber.length > 10){
      errors.phonenumber = 'U dient een 10 cijferig mobielnummer in te vullen';
    }
  }

  return errors;
}

const mapStateToProps = (state, ownProps) => {;
  if (ownProps.name === 'Wijzigen') {
    return {
      passwordShown: state.password.passwordShown,
      blackedNumber: state.password.blackedNumber,
      selectedCity: state.selections.selectedCity,
      cities: state.cities,
      user: state.users[ownProps.ind],
      currentUser: state.currentUser,
      pages: state.pages
    }
  }
  return {
    passwordShown: state.password.passwordShown,
    blackedNumber: state.password.blackedNumber,
    selectedCity: state.selections.selectedCity,
    cities: state.cities,
    user: state.users[ownProps.ind],
    currentUser: state.currentUser,
    initialValues: state.users[ownProps.ind],
    pages: state.pages
  }
}

export default connect(mapStateToProps, { showPassword, setBlackedNumber, setSelectedCity, fetchCities, fetchCurrentUser, updateNewUser, sendMail })(reduxForm({ form: 'userForm', validate: validate, enableReinitialize: true })(UserForm));