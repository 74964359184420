/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import history from '../../history';
import Dropdown from '../elements/Dropdown';
import MultiSelectBox from '../elements/MultiSelectBox';
import {  fetchMultipleSelectBoxValues, setOrigin, fetchCities } from '../../actions';
import { fetchValues } from '../../actions/valueActions';
import { addNewDomain, fetchDomains, setDomain, fetchThemes, setTheme, addNewTheme } from '../../actions/documentActions';

class DocumentForm extends React.Component {
  componentDidMount(){
    this.props.fetchDomains();
    this.props.fetchValues();
    this.props.fetchThemes();
    this.props.fetchCities();
  }

  renderError ({ error, touched }){
    if(touched && error) {
      return(
          <label className="ui below red basic label error">{error}</label>
      );
    }
  }

  renderSecundaryDomainDropdown = () => {
    let sec = {value: '-'};
    const secundaryDomain = this.props.initialValues.secundary_domain;
    if (this.props.selections !== undefined) {  
      if (this.props.selections.origin.secundaryOrigin === 0 ){
        sec = { value: secundaryDomain.charAt(0).toUpperCase() + secundaryDomain.slice(1)};
      } else {
        sec = { value: this.props.selections.selectedSecundaryDomain.value};
      }
    }
    this.props.fetchDomains();
    let domains = this.props.domains.filter(domain => domain.domein_id !== 0);
      domains = domains.sort((a,b ) =>{
          return a.domein.localeCompare(b.domein);
      });
      const options = domains.map((domain) => {
          return {
              text: domain.domein,
              key: domain.domein_id,
              value: domain.domein
          }
      });
    return(
      <Dropdown 
      label={'Secundair Domein'} 
      options={options} 
      selected={sec} 
      onSelectedChange={(setSecundairDomain) => this.changeSelection(2,setSecundairDomain)}
      image='false'
      />
    );
  }

  renderPrimaryDomainDropdown = () => {
    let sel = {value: '-'};
    const primaryDomain = this.props.initialValues.primary_domain;
    if (this.props.selections !== undefined) {  
      if (this.props.selections.origin.primaryOrigin === 0 ){
        sel = { value: primaryDomain.charAt(0).toUpperCase() + primaryDomain.slice(1)};
      } else {
        sel = { value: this.props.selections.selectedPrimaryDomain.value};
      }
    }
    this.props.fetchDomains();
    let domains = this.props.domains.filter(domain => domain.domein_id !== 0);
      domains = domains.sort((a,b ) =>{
          return a.domein.localeCompare(b.domein);
      });
      const options = domains.map((domain) => {
          return {
              text: domain.domein,
              key: domain.domein_id,
              value: domain.domein
          }
      });
    return(
      <Dropdown 
      label={'Primair Domein'} 
      options={options} 
      selected={sel} 
      onSelectedChange={(setPrimaryDomain) => this.changeSelection(1,setPrimaryDomain)}
      image='false'
      />
    );
  }

  renderThemesDropdown = () => {
    let selection = {value: '-'};
    const theme = this.props.initialValues.theme;
    if (this.props.selections !== undefined) {  
      if (this.props.selections.origin.themeOrigin === 0 ){
        selection = { value: theme.charAt(0).toUpperCase() + theme.slice(1)};
      } else {
        selection = { value: this.props.selections.selectedTheme.value};
      }
    }
    this.props.fetchThemes();
    let themes = this.props.themes.filter(theme => theme.thema_id !== 0);
      themes = themes.sort((a,b ) =>{
          return a.thema.localeCompare(b.thema);
      });
      const options = themes.map((theme) => {
          return {
              text: theme.thema,
              key: theme.thema_id,
              value: theme.thema
          }
      });
    return(
      <Dropdown
      label={'Thema'}
      options={options}
      selected={selection}
      onSelectedChange={(setTheme) => this.changeSelection(3,setTheme)}
      image='false'
      />
    );
  }

  renderMultiSelect = () => {
    let selected = this.props.subthemes;
    selected = selected.map((selOpt) => {
      return (
          selOpt.charAt(0).toUpperCase() + selOpt.slice(1)
      );
    });
    if(selected.length>1){
      selected = selected.filter(select => select !== ["-"]);
    }
    return(
      <MultiSelectBox
      options={this.props.themes} 
      name="Subthema"
      selected={selected}
      />
    );
  }

  renderInput= ({ input, label, meta, type, id }) => {
    let className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    if (id === 'experation_year') {
      if (input.value < new Date().getFullYear() && input.value !== '0000') {
        className = `field error`;
      }
    }
    const inputFields = () => {
      if (id === 'context'){
        return (      
          <div className={className} >
            <label>{label}</label>
            <textarea {... input} placeholder={label} autoComplete="off">{input.value}</textarea>
            {this.renderError(meta)}
          </div>
        );
      }
      if (id === 'binding' || id === 'assessment') {
        
        if (input.value === '1' || input.value === 'Ja' || input.value === 1) {
          return (
            <div className={className} style={{ position:'relative', top:'50%' }} >
              <div className="ui checked checkbox">
                <input type="checkbox" style={{ zIndex: '200' }} defaultChecked="true" onChange={(e) => input.onChange(0)} />
                <label>{label}</label>
              </div>
            </div>
          );
        } else {
          return (
            <div className={className} style={{ position:'relative', top:'50%' }} >
              <div className="ui checkbox">
                <input type="checkbox" style={{ zIndex: '201' }} name={label} onChange={(e) => input.onChange(1)} />
                <label>{label}</label>
              </div>
            </div>
          );
        }
      }
    
      return (      
        <div className={className} >
          <label>{label}</label>
          <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
          {this.renderError(meta)}
        </div>
      );
    }
    return(
      <React.Fragment>
        {inputFields()}
      </React.Fragment>
      
    );
  }

  addTheme = (e) => {
    if(e.target.id === 'addTheme') {
      const theme = document.getElementById('extra_theme').value;
      if (theme !== ''){
        const isOk = this.props.themes.map((theme) => theme.thema) 
        .filter((value) => value.toLowerCase() === theme.toLowerCase());
        const thema = { thema: theme.charAt(0).toUpperCase() + theme.slice(1) };
        document.getElementById('extra_theme').value = '';
        if (isOk.length === 0) {
          this.props.addNewTheme(thema);
        }
      }
    }
  }

  addDomain = (e) => {
    if(e.target.id === 'addDomain') {
      const domain = document.getElementById('extra_domain').value;
      const isOk = this.props.domains.map((doms) => doms.domein) 
      .filter((value) => value.toLowerCase() === domain.toLowerCase());
      if(domain !== ''){
        const dom = { domein: domain.charAt(0).toUpperCase() + domain.slice(1) };
        document.getElementById('extra_domain').value = '';
        if (isOk.length === 0) {
          this.props.addNewDomain(dom);
        }
      }
    }
  }

  changeSelection = (ind, item) => {
    let origin ={};
    if (ind === 3 && this.props.selections !== undefined){
      origin = { ...this.props.selections.origin, themeOrigin: 1};
      this.props.setOrigin(origin);
      this.props.setTheme(item)
    } else {
      if (ind === 1 && this.props.selections !== undefined) {
        origin = { ...this.props.selections.origin, primaryOrigin: 1};
        this.props.setOrigin(origin);
      } else if(ind === 2 && this.props.selections !== undefined){
        origin = { ...this.props.selections.origin, secundaryOrigin: 1};
        this.props.setOrigin(origin);
      }
      this.props.setDomain(ind, item);
    }
  }

  getBack= () => {
    const origin = {primaryOrigin: 0, secundaryOrigin: 0, themeOrigin: 0}
    this.props.setOrigin(origin)
    history.push(`/documents/list/${this.props.pages.activePage}`);
  }

  onSubmit = (formValues) => {
    if (this.props.selections.origin.primaryOrigin !== 0 ){
      const selectedPrimaryDomain = { primary_domain: this.props.selections.selectedPrimaryDomain.value};
      formValues = {...formValues, ...selectedPrimaryDomain};
    }
    if (this.props.selections.origin.secundaryOrigin !== 0 ){
      const selectedSecundaryDomain = { secundary_domain: this.props.selections.selectedSecundaryDomain.value};
      formValues = {...formValues, ...selectedSecundaryDomain};
    }
    if (this.props.selections.origin.themeOrigin !== 0 ){
      const selectedTheme = { theme: this.props.selections.selectedTheme.value};
      formValues = {...formValues, ...selectedTheme};
    }
    if(this.props.msbv.length > 1){
      const msbv = this.props.msbv.filter(val => val.thema_id !== 58 );
      const values = msbv.map((val) => {
        return val.thema;
      });
     let val = values.toString();
     formValues = { ...formValues, subtheme: val }
    } else {
      let val = this.props.msbv[0].thema;
      formValues = { ...formValues, subtheme: val }
    }
    const origin = {primaryOrigin: 0, secundaryOrigin: 0, themeOrigin: 0}
    this.props.setOrigin(origin)
    const values = { ...this.props.document, ...formValues };
    this.props.onSubmit(values,this.props.pages.activePage);
  }

  render() {
    const submit =this.props.handleSubmit(this.onSubmit);
    const typeCode =this.props.document.type.split('-');
    return (
        <div>
          <form  id="documentForm" name="documentForm" onSubmit={this.props.handleSubmit(this.onSubmit)} className="ui fluid form error">
            <div className="field">
              <div className="three fields">
                <div className="seven wide field">
                  <Field name="title" id="title" label="Titel" type="text" component={this.renderInput} />
                </div>
                <div className="two wide field">
                  <Field name="pages" id="pages" label="Aantal pagina's" type="text" component={this.renderInput} />
                </div>
                <div className="seven wide field">
                  <Field name="root_url" id="root_url" label="URL" type="text" component={this.renderInput} />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="two fields">
                <div className="twelve wide field">
                  <Field name="context" id="context" label="Beschrijving" type="text" rows="5" component={this.renderInput} />
                </div>
                <div className="four wide field" style={{ display: `${typeCode[0] === 'BP' ? 'block' : 'none'}` }}>
                </div>
              </div>
            </div>
            <div className="field" style={{ display: `${typeCode[0] === 'BP' ? 'block' : 'none'}` }}>
              <div className="two fields">
                <div className="twelve wide field">
                  <Field name="relationships" id="relationships" label="Heeft relatie met" type="text" component={this.renderInput} />
                </div>
                <div className="four wide field">
                  <Field name="characteristics" id="characteristics" label="Plankarakter" type="text" component={this.renderInput} />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="two fields">
                <div className="eight wide field">
                  <h4 className="ui dividing header">Domeinen</h4>
                  <div className="field">
                    <div className="two fields">
                      <div className="eight wide field">
                        {this.renderPrimaryDomainDropdown()}
                      </div>
                      <div className="eight wide field">
                      {this.renderSecundaryDomainDropdown()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eight wide field">
                  <h4 className="ui dividing header">Thema's</h4>
                  <div className="field">
                    <div className="two fields">
                      <div className="eight wide field">
                        {this.renderThemesDropdown()}
                      </div>
                      <div className="eight wide field">
                        {this.renderMultiSelect()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="two fields">
                <div className="eight wide field" style={{ borderRadius:'5px' ,borderStyle:'solid', borderWidth:'1px', borderColor:'#CCCCCC', background: '#EEEEEE' }}>
                  <div className="field">
                    <div className="two fields">
                      <div className="eight wide field">
                        <Field name="extra_domain" id="extra_domain" label="Domein toevoegen" type="text" component={this.renderInput}  />
                      </div>
                      <div className="eight wide field">
                        <div className="field" style={{ position:'relative', top:'40%' }} >
                          <div id='addDomain' className="ui button primary" onClick={(e)=>this.addDomain(e)} >Domein toevoegen</div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="eight wide field" style={{ borderRadius:'5px' ,borderStyle:'solid', borderWidth:'1px', borderColor:'#CCCCCC', background: '#EEEEEE' }}>
                  <div className="field">
                    <div className="two fields">
                      <div className="eight wide field">
                        <Field name="extra_theme" id="extra_theme" label="Thema toevoegen" type="text" component={this.renderInput}  />
                      </div>
                      <div className="eight wide field">
                        <div className="field" style={{ position:'relative', top:'40%' }} >
                          <div id='addTheme' className="ui button primary" onClick={(e)=>this.addTheme(e)} >Thema toevoegen</div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>

            <h4 className="ui dividing header">Document metadata</h4>
            <div className="field">
              <div className="five fields">
                <div className="six wide field">
                  <Field name="responsible" id="responsible" label="Verantwoordelijke organisatie" type="text" component={this.renderInput}  />
                </div>
                <div className="two wide field">
                </div>
                <div className="three wide field">
                  <Field name="binding" id="binding" label="Bindend" type="checkbox" component={this.renderInput} />
                </div>
                <div className="two wide field">
                </div>
                <div className="three wide field">
                  <Field name="assessment" id="assessment" label="Toetsingskader" type="checkbox" component={this.renderInput} />
                </div>
              </div>
            </div>
            <div className="field">
              <div className="five fields">
                <div className="three wide field">
                  <Field name="annual_determination" id="annual_determination" label="Jaar van vaststelling" type="number" maxLength="4" size="4" component={this.renderInput}  />
                </div>
                <div className="two wide field">
                </div>
                <div className="three wide field">
                  <Field id="entry_year" name="entry_year" label="Jaar van inwerkingtreding" type="number" size="4" maxLength="4" component={this.renderInput}  />
                </div>
                <div className="two wide field">
                </div>
                <div className="three wide field">
                  <Field name="experation_year" id="experation_year" label="Jaar van experatie" type="number" maxLength="4" size="4" component={this.renderInput}  />
                </div>
              </div>
            </div>
          </form>

          <h4 className="ui dividing header" style={{ marginTop: "-10px"}} >&nbsp;</h4>
          <div>
            <div style={{ display: "inline-block" }}>
              <button 
                type="submit" 
                className="ui button primary" 
                onClick={event => {
                  submit(event)
                }}
              >
                {this.props.name}
              </button>
              <div className="ui button" style={{ marginLeft: "1em" }} onClick={this.getBack}>Terug</div>
            </div>
          </div>
        </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
//  var regexonlyalpha = new RegExp(/^[a-z \.\-A-Z]+$/);
//  var regexemail = new RegExp(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/);
  var regexonlynummeric = new RegExp(/^[0-9\b]+$/);
  // eslint-disable-next-line
  var regexcheckurl = new RegExp(/(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/?/g);

  if (!formValues.title) {
    errors.title = 'U moet een titel invullen';
  }

  if(formValues.pages !== 0){
    if (!formValues.pages) {
      errors.pages = "Aantal pagina's invullen";
    }
  }

  if (!regexonlynummeric.test(formValues.pages)) {
    errors.pages = 'Alleen cijfers';
  }

  if (!formValues.root_url) {
    errors.root_url = "U moet een url of n.v.t. invullen";
  }

  if (!regexcheckurl.test(formValues.root_url) && formValues.root_url !== 'n.v.t.' && formValues.root_url !== 'nvt') {
    errors.root_url = "U moet een url of n.v.t. invullen";
  }

  if (!formValues.context) {
    errors.context = "U moet de context opgeven.";
  }

  if (!formValues.responsible) {
    errors.responsible = "U moet de verantwoordelijke organisatie opgeven.";
  }

  return errors;
}

const mapStateToProps = (state,ownProps) => {
  return {
    domains: Object.values(state.domains),
    selections: state.selections,
    themes: Object.values(state.themes),
    document_id: ownProps.document_id,
    document: state.documents[ownProps.document_id],
    msbv: Object.values(state.multipleSelectBoxValues),
    subthemes: Object.values(state.documents[ownProps.document_id].subtheme),
    pages: state.pages,
    cities: Object.values(state.cities)
  }
}

export default connect(mapStateToProps, { fetchDomains, setDomain, addNewDomain, fetchThemes, setTheme, addNewTheme, fetchMultipleSelectBoxValues, setOrigin, fetchCities, fetchValues })(reduxForm({ form: 'documentForm', validate: validate })(DocumentForm));