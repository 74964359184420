/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

import  { 
  FETCH_DOMAINS,
  ADD_DOMAIN
} from '../actions/types';
const INIT_STATE = [{
  domein_id: 0,
  domein:''
}]


//Edit: Object-based approach
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DOMAINS:
      return { ...state, ..._.mapKeys(action.payload, 'domein_id')}
    case ADD_DOMAIN:
      return { ...state, [action.payload.domein_id]:action.payload }
    default:
      return state;
  }
};