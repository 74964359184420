import React from 'react';
import { connect } from 'react-redux';
import { setDimensions, setActiveMenu } from '../../actions';
import ls from 'local-storage';
import Header from '../Header';
import DashboardCards from './DashboardCards';

class Dashboard extends React.Component {

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('5');
    ls.set('ll', '/beheer/dashboard');
  }

  componentDidUpdate() {
    ls.set('ll', '/beheer/dashboard');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  
  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  render(){
    return(
      <div 
        style={{ 
          width:this.props.dimensions.width, 
          height: this.props.dimensions.height,  
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(200,200,200,0.50) 100%)'
        }}
        className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h2>Dashboard</h2>
          <div className="ui three cards">
            <DashboardCards color="orangered" to="/beheer/users/list/1"  header="Gebruikersbeheer" description="Het volledig verwijderen of terugzetten van verwijderde gebruikers" meta="Lijst met verwijderde gebruikers beheren" icon="gebruikersbeheer-icon.png" alt="Gebruikersbeheer" action="getDeletedUsers" />
            <DashboardCards color="cornflowerblue" to="/beheer/documenten/list/1" header="Documentbeheer" description="Het volledig verwijderen of terugzetten van verwijderde documenten inclusief de bijbehorende waarden" meta="Lijst met verwijderde documenten beheren" icon="documentenbeheer-icon.png" alt="Documentenbeheer" />
            <DashboardCards color="mediumseagreen" to="/beheer/waarden/list/1" header="Waardenbeheer" description="Het volledig verwijderen of terugzetten van verwijderde waarden" meta="Lijst met verwijderde waarden beheren" icon="waardenbeheer-icon.png" alt="Waardenbeheer" />
          </div>
          <div className="ui three cards">
            <DashboardCards color="chocolate" to="/beheer/waarden/koppelen/list/:page" header="Waarden koppelen" description="Het koppelen van waarden aan documenten" meta="Lijst met waarden koppelen" icon="waardekoppeling-icon.png" alt="Waardekoppelen" />
            <DashboardCards color="mediumorchid" to="/beheer/search/newdocuments/list/:page" header="Nieuwe beleidsdocumenten zoeken" description="Het doorzoeken van wettelijke publicatie sites naar nieuwe beleidsdocumenten" meta="Lijst met nieuwe beleidsdocumenten beheren" icon="searchdocument-icon.png" alt="Gebruikersbeheer" />
            <DashboardCards color="teal" to="/beheer/search/newvalues/list/:page" header="Nieuwe beleidswaarden zoeken" description="Het doorzoeken van nieuwe documenten naar beleidswaarden." meta="Lijst met nieuwe gevonden beleidswaarden beheren" icon="searchvalue-icon.png" alt="Documentenbeheer" />
        </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps =(state) => {
  return {
    dimensions: state.dimensions
  }
}

export default connect(mapStateToProps, { setDimensions, setActiveMenu })(Dashboard);