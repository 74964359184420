import React from 'react';
import { Link } from 'react-router-dom';

class DashboardCards extends React.Component {

  render() {
    return(
      <Link to={this.props.to}className={`${this.props.color} card`} >
        <div className="content">
          <img className="right floated mini ui image" src={`${process.env.PUBLIC_URL}/images/icons/${this.props.icon}`} alt={this.props.alt} />
          <div className="header" style={{ color: this.props.color }}>
            {this.props.header}
          </div>
          <div className="meta">
            {this.props.meta}
          </div>
          <div className="description">
            {this.props.description}
          </div>
        </div>
        <div className="extra content">
          <div className="ui button" style={{width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: this.props.color }}>
            <div className='ui inverted button'>Ga naar {this.props.header}</div>
          </div>
        </div>
      </Link>
    )
  }
}

export default DashboardCards;
