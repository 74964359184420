/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
import  { 
  ADD_MULTIPLESELECTBOX_VALUES,
  DEL_MULTIPLESELECTBOX_VALUES,
  RESET_MULTIPLESELECTBOX_VALUES
} from '../actions/types';
const INIT_STATE = {58:{
    thema_id: 58,
    thema: "-"
}}


//Edit: Object-based approach
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_MULTIPLESELECTBOX_VALUES:
      return { ...state, ...action.payload };
    case DEL_MULTIPLESELECTBOX_VALUES:
      return _.omit(state, action.payload);
    case RESET_MULTIPLESELECTBOX_VALUES:
      return INIT_STATE;
    default:
      return state;
  }
};