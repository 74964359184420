/* eslint-disable import/no-anonymous-default-export */
import { SEND_MAIL, CHANGE_PASS } from '../actions/types';
const INIT_STATE = {
  succes: false,
  message: ''
};

export default (state = INIT_STATE, action) => {
  switch(action.type){
    case SEND_MAIL:
      return { succes: action.payload.succes, message: action.payload.message }
    case CHANGE_PASS:  
      return { succes: action.payload.succes,message: action.payload.message };
    default: 
    return state;
  };
};