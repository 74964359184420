/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

import  { 
  FETCH_THEMES,
  ADD_THEME
} from '../actions/types';
const INIT_STATE = [{
  thema_id: 0,
  thema:''
}]


//Edit: Object-based approach
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_THEMES:
      return { ...state, ..._.mapKeys(action.payload, 'thema_id')}
    case ADD_THEME:
      return { ...state, [action.payload.thema_id]:action.payload }
    default:
      return state;
  }
};