/* eslint-disable import/no-anonymous-default-export */
import { SELECTED_ITEMS_PER_PAGE, GET_PAGINATION } from '../actions/types';
const INIT_STATE = { 
  selectedItemsPerPage: "5",
  activePage: "1",
  pages: "1",
  totalRows: "0"
};

export default (state = INIT_STATE, action) => {
  switch(action.type){
    case SELECTED_ITEMS_PER_PAGE:
      return {  ...state, selectedItemsPerPage: action.payload };
    case GET_PAGINATION:
      return {...state, ...action.payload }  
    default:
    return state;
  };
};