import React from 'react';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setSelectedCity, fetchCities } from '../actions';
import Dropdown from '../components/elements/Dropdown';
import Copyright from '../components/Copyright';


class loginForm extends React.Component {
  state = { hideLabel: true };

  componentDidMount() {
    this.props.fetchCities();
    const cityArr = Object.values(this.props.cities);
    cityArr.map((city) => {
      if(this.props.initialValues !== undefined) {
        if (city.key === this.props.initialValues.city_oin) {
          this.props.setSelectedCity(city);
        }
      }
      return null;
    })
  };

  renderError ({ error, touched }){
    if(touched && error) {
      if (this.props.selectedCity.value === undefined) this.setState({hideLabel: false})
      return(
          <label className="ui below red basic label error">{error}</label>
      );
    }
  };

  setCookieStatement = () => {
    return(
      <Link style={{fontSize: '8px', cursor:'pointer'}} to='/cookiestatement'>Cookie verklaring</Link>
    );
  }

  renderInput= ({ input, label, meta, type, id }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    if (id === "remember_me" ){
      return (
        <label>
          <input {... input} id={id} type={type} style={{ marginRight: '6px', top: '30px'}}/>
          {label}
        </label>
      );
    }
    return (
      <React.Fragment>
        <div className={className}>
          <label>
            {label}
            <input {... input} id={id} type={type} placeholder={label} autoComplete="off" />
          </label>
        </div>
        {this.renderError(meta)}
      </React.Fragment>
    );
  };

  onSubmit = (formValues) => {
    const values = { ...formValues, city_name: this.props.selectedCity.value, city_oin: this.props.selectedCity.key};
    this.props.onSubmit(values);
  }

  setDropDownCities = () => {
    
    if (!_.isEmpty(this.props.cities)){

      return (
        <Dropdown
          name='cities' 
          label={'Selecteer een gemeente:'} 
          options={Object.values(this.props.cities)} 
          selected={this.props.selectedCity} 
          onSelectedChange={(setSelectedCity) => this.changeSelection(setSelectedCity)}
          image='true'
          autoFocus = 'true'
        />
      );
    }
    return null;
  }

  getEmail = () => {
    const email = document.getElementById('email');
    if(email !== null && email.value !== null) {return email.value;}
  }

  changeSelection = (city) => {
    if (this.props.selectedCity.value !== undefined) {
      this.setState({hideLabel: true});
    } else {
      this.setState({hideLabel: false});
    }
    this.props.setSelectedCity(city);
    this.setState({hideLabel: true});
  }

  setLoginbox = () => {
    const w = Math.ceil(this.props.width/2);
    const h = Math.ceil(this.props.height/2);
    const t = Math.ceil(h/2);
    const l = Math.ceil(w/2);

    return (
      <div id='login' style={{ width: w, height: h, left:l, top:t, border:'2px solid #85BE1A', borderRadius:'6px', backgroundColor:'#FFFFEE', opacity:'1'  }}>
        <div style={{ marginTop:'2px' }} >
          <img src={process.env.PUBLIC_URL + "/images/BWG-online.png"} alt='BWG-online' style={{ float:'left', width: '15%', height: 'auto'}} />
          <h1 className='ui header' style={{ color:'#4EA619', textAlign:'center'}}>
            Login bij de beleidswaarden generator online!
          </h1>
        </div>
        <form name='loginForm' id='loginForm' className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)} style={{ width:'90%' }}>
          <div className="field"  style={{ position: 'relative', height: '50px', top: `${this.state.hidelabel ? '0' : '-30px'}` }}>
            {this.setDropDownCities()}
            <label className="ui below red basic label error" style={{ display:(this.state.hideLabel ? 'none' : 'block'), position: 'relative', left: '200px', top: '-32px', width: '215px', marginLeft: "6px", height: '30px'}}>
              U moet een gemeente selecteren
            </label>
            <div style={{ float: "right", marginBottom: "6px", fontSize: "10px", position: 'relative', top:'-30px', zIndex:'100' }}>
              Wachtwoord vergeten? Klik <Link to={{ pathname: '/login/wachtwoordvergeten', email: this.getEmail() }}>hier.</Link>
            </div>
          </div>
          
          <div style={{ position: 'relative', top:'-45px'}}>
            <Field name="email" label="Email" id="email" type="email" component={this.renderInput} />
            <Field id ="pass" name="password" type="password" label="Wachtwoord" component={this.renderInput} />
          </div>
          <div className="field">
            <div className="ui item" style={{ float: "left", marginBottom: "6px", fontSize: "10px", position: 'relative', top:'-45px' }}>
              <Field name="remember_me" label="Onthoud mijn inloggegevens" id="remember_me" type="checkbox" component={this.renderInput} />
            </div>
            <div className="ui item" style={{ float: "right", marginBottom: "6px", fontSize: "10px", position: 'relative', top:'-45px' }}>
              Nog geen account? Klik <Link to={{pathname: '/login/newaccount', city: this.props.selectedCity  }}>hier.</Link>
            </div>
          </div>
          <div className="ui below red basic label error" style= {{ position: 'relative', top:'-50px', display: `${this.props.err !== '' ? 'block' : 'none' }` }}>{this.props.err}</div>
          <button  style={{ position: 'relative', top:'-45px'}} className="ui fluid olive button" type="submit">Inloggen</button>
          {this.setCookieStatement()}
          <Copyright color='olive' size='8px'/>
        </form>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.setLoginbox()}
      </React.Fragment>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  var regexemail = new RegExp(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/);
  
  if (!regexemail.test(formValues.email)){
    errors.email = "Emailadres klopt niet";
  }

  if (!formValues.email) {
    errors.email = 'U dient een emailadres in te vullen';
  }

  if (!formValues.password) {
    errors.password = 'Een wachtwoord is verplicht!';
  }

  return errors;
}

const mapStateToProps = (state) => {
  return{
    cities: state.cities,
    selectedCity: state.selections.selectedCity,
    dimensions: state.dimensions
  }
}

export default connect(mapStateToProps, { setSelectedCity, fetchCities })(reduxForm({ form: 'loginForm', validate: validate, enableReinitialize: true})(loginForm));
