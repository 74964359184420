import React from 'react';
import { connect } from 'react-redux';
import { fetchUser, updateUserRights, setNewUser } from '../../actions/userActions';

class UserRights extends React.Component {

  componentDidMount() {
    if (!this.props.userid === 0) {
      this.props.fetchUser(this.props.ind);
    } else {
      this.props.setNewUser();
    }
  }

replaceAt = (str, index, chr) => {
  if(parseInt(index) > str.length-1) return str;
  if (parseInt(index) === 0 ) return chr + str.substring(parseInt(index)+1,str.length);
  return str.substring(0,parseInt(index)) + chr + str.substring(parseInt(index)+1,str.length);
}

changeIcon = (e) => {
  const id = e.target.id;
  const x = this.props.user.rights.charAt(id) === "1" ? "0" : "1";
  const rights = this.replaceAt(this.props.user.rights, id, x);
  this.props.updateUserRights(this.props.user, rights)
}

  setIcon = (id) => {
    if ( this.props.user.rights.charAt(id) === '1') {
      return(
        <div style={{ cursor:'pointer' }} id={id} onClick={(e) => this.changeIcon(e)}>
          <i id={id} className="large green checkmark icon" ></i>
        </div>
      );
    }
    return(
      <div style={{ cursor:'pointer' }} id={id} onClick={(e) => this.changeIcon(e)}>
        <i id={id} className="large red close icon" ></i>
      </div>
    );
  }

  render() {
//    const table = this.setTableRows
    return(
        <div >
          <h4 className="ui dividing header">Gebruikersrechten</h4>
          <table style={{ marginTop: "-42px"}} className="ui definition table">
            <thead>
              <tr>
                <th></th>
                <th className="center aligned">Maken</th>
                <th className="center aligned">Lezen</th>
                <th className="center aligned">Wijzigen</th>
                <th className="center aligned">Verwijderen</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td>Documenten</td>
              <td className="center aligned">{this.setIcon(0)}</td>
              <td className="center aligned">{this.setIcon(1)}</td>
              <td className="center aligned">{this.setIcon(2)}</td>
              <td className="center aligned">{this.setIcon(3)}</td>
            </tr>
            <tr>
            <td>Waarden</td>
              <td className="center aligned">{this.setIcon(4)}</td>
              <td className="center aligned">{this.setIcon(5)}</td>
              <td className="center aligned">{this.setIcon(6)}</td>
              <td className="center aligned">{this.setIcon(7)}</td>
              </tr>
            <tr>
            <td>Analyses</td>
              <td className="center aligned">{this.setIcon(8)}</td>
              <td className="center aligned">{this.setIcon(9)}</td>
              <td className="center aligned">{this.setIcon(10)}</td>
              <td className="center aligned">{this.setIcon(11)}</td>
            </tr>
            <tr>
              <td >Beheer</td>
              <td className="center aligned">{this.setIcon(12)}</td>
              <td className="center aligned">{this.setIcon(13)}</td>
              <td className="center aligned">{this.setIcon(14)}</td>
              <td className="center aligned">{this.setIcon(15)}</td>
            </tr>
            <tr>
            <td >Gebruikers</td>
              <td className="center aligned">{this.setIcon(16)}</td>
              <td className="center aligned">{this.setIcon(17)}</td>
              <td className="center aligned">{this.setIcon(18)}</td>
              <td className="center aligned">{this.setIcon(19)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.users[ownProps.ind],
  }
}

export default connect(mapStateToProps, { fetchUser, updateUserRights, setNewUser } )(UserRights);  


