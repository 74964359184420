import React from 'react';
import { connect } from 'react-redux';
import { setDimensions } from '../../actions';
import { getAllURLs, changeAccordionId } from '../../actions/documentActions';
import ls from 'local-storage';
import Header from '../Header';

class DocumentSearch extends React.Component {

  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
    this.props.getAllURLs();
    ls.set('ll', '/beheer/dashboard');
  }

  componentDidUpdate() {
    ls.set('ll', '/beheer/dashboard');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  
  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  setSelectedAccordion = (id) => {
    this.props.changeAccordionId(id);
  }

  renderHeaders = () => {

    const headers = this.props.urls.map((url, index) => {
      if (index !== 0) {
        const header = url.root_url.split("/",3);
        return header[2].toString();
      }
      return null;
    });

    let distinctHeaders = [...new Set(headers)];
    distinctHeaders = distinctHeaders.filter(option => option !== null);

    distinctHeaders = distinctHeaders.sort((a,b ) =>{
        return a.localeCompare(b);
    });

    return distinctHeaders;
  }

  renderUrls = (header) => {
    
    const urlMap = [...new Set(this.props.urls)];
    let list = urlMap.map((url) => {
      let item = null;
      if (url.root_url.includes(header)) { 
        item = url.root_url.toLowerCase();
        return item;
      }
      return item;
    });
    list = list.filter(option => option !== null);
    return list;
  }

  renderList() {
    const headers = this.renderHeaders();
    const accordion = headers.map((header, index) => {    
      const urlList = this.renderUrls(header);
      const list = urlList.map((item, ind) => {

        return (
          <li key={ind}>
            {item}
          </li>
        );
      });

      let content = '';
      console.log(this.props.accordionId);
      if (this.props.accordionId !== null) { 
        if (index === this.props.accordionId) {
              content = 
              <React.Fragment key={index}>
                <div className="title active" >
                  <i className="dropdown icon"></i>
                  {header}
                </div>
                <div className="content active" >
                  <ol className="transition visible" style={{ display: 'block !important' }}>
                    {list}
                  </ol>
                </div>
              </React.Fragment>
          } else {
            content =
            <React.Fragment key={index}>
            <div className="title" onClick={() => this.setSelectedAccordion(index)}>
              <i className="dropdown icon"></i>
              {header}
            </div>
            <div className="content" >
              <ol>
                {list}
              </ol>
            </div>
          </React.Fragment>
          }
        }
        return content;
    });

    return (
      <div className="ui styled fluid accordion">
        {accordion}
      </div>);
  }


  render(){
    return(
      <div 
        style={{ 
          width:this.props.dimensions.width, 
          height: this.props.dimensions.height,  
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(200,200,200,0.50) 100%)'
        }}
        className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h2>Nieuwe Documenten Zoeken</h2>
          {this.renderList()}
        </div>
        
      </div>
    );
  }
}

const mapStateToProps =(state) => {
  return {
    dimensions: state.dimensions,
    urls: Object.values(state.urls),
    accordionId: state.selections.accordionId
  }
}

export default connect(mapStateToProps, { setDimensions, getAllURLs, changeAccordionId })(DocumentSearch);