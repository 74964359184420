import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setDimensions, setActiveMenu, fetchMultipleSelectBoxValues, setSelectedCity } from '../../actions';
import { fetchDocument, editDocument, setTheme, setDomain } from '../../actions/documentActions'
import Header from '../Header';
import DocumentForm from './DocumentForm';

class DocumentEdit extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('2');
    this.fetchDocumentAndSetSelectBoxValues();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  fetchDocumentAndSetSelectBoxValues = () => {
    this.props.fetchDocument(this.props.match.params.id);
    this.selectedSubThemes();
  }

  onSubmit = (formValues, activePage) => {
    formValues = { ...formValues, unique_id: this.props.currentUser.unique_id}
    this.props.editDocument(this.props.match.params.id, formValues, activePage);
  };

  selectedSubThemes = () => {
    const themas = this.props.themes.map((theme) => {
      if (Object.values(this.props.document.subtheme).includes(theme.thema)) {
        const subtheme= {[theme.thema_id]:{thema:theme.thema, thema_id:theme.thema_id}};
        this.props.fetchMultipleSelectBoxValues(subtheme, 'add');
        return subtheme;
      };
      return null
    });
      const sub = themas.filter(option => option !== null);
      return sub;
  }

  render() {
    const initialValues = _.pick(this.props.document, 'document_id', 'unique_id', 'city_id', 'type', 'title', 'context', 'root_url','characteristics', 'relationships', 'annual_determination', 'responsible', 'entry_year', 'experation_year', 'pages', 'binding', 'assessment', 'primary_domain', 'secundary_domain', 'theme', 'subtheme');
    initialValues.characteristics = initialValues.characteristics.charAt(0).toUpperCase() + initialValues.characteristics.slice(1);
    initialValues.relationships = initialValues.relationships.charAt(0).toUpperCase() + initialValues.relationships.slice(1);
    return (
      <div 
      style={{ 
        width:this.props.dimensions.width, 
        height: this.props.dimensions.height,  
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(244,243,165,0.48082983193277307) 100%)'
      }}
      className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h3>Documentdata wijzigen:</h3>
          <h4 className="ui dividing header">
            <div>
              <div style={{ display: "inline-block" }} className="ui content">
                Documenttype #{this.props.document.type}
              </div>
            </div>
          </h4>
          <DocumentForm
            document_id= {this.props.document.document_id} 
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            name='Wijzigen' 
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    dimensions: state.dimensions,
    document: state.documents[ownProps.match.params.id],
    currentUser: state.currentUser,
    domains: Object.values(state.domains),
    themes: Object.values(state.themes),
    cities:state.cities
  }
}

export default connect(mapStateToProps, { setDimensions, setActiveMenu, fetchDocument, editDocument, fetchMultipleSelectBoxValues, setTheme, setDomain, setSelectedCity })(DocumentEdit);