
import React from 'react';
import { CanvasJSChart } from 'canvasjs-react-charts'; 

class AnimatedChart extends React.Component {	
	render() {

		return (
		<div>
			<CanvasJSChart options = {this.props.options} 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}
 
export default AnimatedChart; 