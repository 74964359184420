/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
import  { 
  FETCH_DELETED_DOCUMENTS
} from '../actions/types';

const initialState = 
  {
    0:
    {
      document_id:0,
      unique_id:'',
      city_id:'0',
      type:'',
      title:'',
      context:'',
      root_url:'',
      characteristics: '-',
      relationships: '-',
      annual_determination:'0000',
      responsible:'',
      entry_year:'0000',
      experation_year:'0000',
      pages:'0',
      binding:'0',
      assessment:'0',
      primary_domain:'-',
      secundary_domain:'-',
      theme:'-',
      subtheme: ['-'],
      deleted:'0',
      deleted_user:'',
      deletion_stamp:''
    }
  };

  export default (state = initialState, action) => {

    switch (action.type) {
      case FETCH_DELETED_DOCUMENTS:
        state = initialState;
        return { ...state, ..._.mapKeys(action.payload, 'document_id')};          
      default:
        return state;
    }
  };