import React from 'react';
import ls from 'local-storage';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveMenu, fetchCities, setPagination, setDimensions } from '../../actions';
import { fetchUsers, fetchCurrentUser } from '../../actions/userActions';
import Pagination from '../elements/Pagination';
import Header from '../Header';


class UserList extends React.Component {
  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
    this.props.setActiveMenu('4')
    this.props.setPagination('users',this.props.match.params.page,this.props.pages.selectedItemsPerPage)
    this.props.fetchUsers();
    this.props.fetchCities();
    ls.set('ll', `/users/list/${this.props.match.params.page}`);
  }

  componentDidUpdate() {
    ls.set('ll', `/users/list/${this.props.match.params.page}`);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  
  updateDimensions = () => {
    this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  renderCreate() {
    if (this.props.currentUser.rights.charAt(16) === '1') {
      return (
        <div className="ui container">
          <div style={{ textAlign: 'right', zIndex:'200', cursor: 'pointer' }}>
            <Link to="/users/new" className="ui right floated small primary labeled icon button">
              <i className="user icon"></i> Nieuwe Gebruiker
            </Link>
          </div>

       </div>    

      );
    }
  };

  renderOwner(user) {
    if (this.props.currentUser.rights.charAt(18) === '1' && this.props.currentUser.rights.charAt(19) === '1') {
      return ( 
        <div className="right floated content" z-index='120'>
          <Link className="ui small primary labeled icon button" z-index='120' to={`/users/edit/${user.ind}`}>
            <i className="pencil alternate icon"></i>Wijzigen
          </Link>
          <Link className="ui small negative labeled icon button" z-index='120' to={`/users/delete/${user.ind}`}>
            <i className="trash alternate outline icon"></i>Verwijderen
          </Link>
        </div>  
      );
    } else if (this.props.currentUser.rights.charAt(18) === '1') {
      return ( 
        <div className="right floated content" z-index='120'>
          <Link className="ui small primary labeled icon button" z-index='120' to={`/users/edit/${user.ind}`}>
            <i className="pencil alternate icon"></i>Wijzigen
          </Link>
        </div>  
      );
    }else if (this.props.currentUser.rights.charAt(19) === '1') {
      return(
        <div className="right floated content" z-index='120'>
          <Link className="ui small negative labeled icon button" z-index='120' to={`/users/delete/${user.ind}`}>
            <i className="trash alternate outline icon"></i>Verwijderen
          </Link>
        </div>
      );
    }
    return null;
  }

  getUserName (user) {
    if (user.mname !== null) {
      return (user.fname + ' ' + user.mname + ' ' + user.lname ); 
    }
    return (user.fname + ' ' + user.lname);
  }

  renderList() {
    const itemsPerPage = this.props.pages.selectedItemsPerPage;
    const page = this.props.pages.activePage;
    const end = (parseInt(page) * parseInt(itemsPerPage) + 1);
    const start = (parseInt(end) - parseInt(itemsPerPage));
    return this.props.users
    // eslint-disable-next-line array-callback-return
    .map((user, index) => {
      if (user.id !== 0) {
        if (index >= start && index < end){
          return (
            <div className="item" z-index="110" key={user.unique_id}>
              {this.renderOwner(user)}
              <i className="large middle aligned icon user" />
              <div className="content">
                <Link to={`/users/${user.ind}`} className="header">
                  {user.ind}: {this.getUserName(user)}
                </Link>
                <div className="description">{user.email}</div>
              </div>
            </div>
          );
        }
      }
    });
  }

  render() {
    return (
      <div 
        style={{ 
          width:this.props.dimensions.width, 
          height: this.props.dimensions.height,  
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(175,244,165,0.48082983193277307) 100%)'
        }}
        className="ui container"
      >
        <div className="ui container">
          <Header location={this.props.location.pathname} />
          <h2>Gebruikers</h2>
          <div className="ui celled list">{this.renderList()}</div>
          {this.renderCreate()}
          <Pagination category="users"/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: Object.values(state.users),
    currentUserId: state.auth.userId,
    currentUser: state.currentUser,
    isSignedIn: state.auth.isSignedIn,
    pages: state.pages,
    dimensions: state.dimensions
  }
}

export default connect(mapStateToProps, { fetchUsers, setActiveMenu, fetchCurrentUser, fetchCities, setPagination, setDimensions })(UserList);