import React from 'react';
import AnimatedChart from '../elements/charts/AnimatedChart';

class BarWaardenChart extends React.Component {

  renderWaardenOptions = () => {
    const domdata = this.props.docTypeData;
    let dataPoints = [];
    if (domdata.vals_arr !== undefined) {

      Object.entries(domdata.vals_arr).forEach(([key, val]) => {
        if (key === undefined || key === "") { 
          return null;
        }
        dataPoints = [...dataPoints, { y: val, label: key }]
      });
    }
    
    const options = {
      animationEnabled: true,
      theme: "light1",
      backgroundColor: 'transparent',
      title:{
        text: ""
      },
      axisX: {
        title: "Beleidswaarden",
        reversed: true,
      },
      axisY: {
        title: "Aantal keren benoemd",
        includeZero: true,
        labelFormatter: this.addSymbols
      },
      height: 270,
      width: 680,
      data: [{
        type: "column",
        dataPoints: dataPoints
      }]
    }
    return options;
  }

  render(){
    return(
      <AnimatedChart options={this.renderWaardenOptions()} />
    );
  }
}

export default BarWaardenChart;