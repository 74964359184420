import React from 'react';
import { connect } from 'react-redux';
import { fetchMultipleSelectBoxValues } from '../../actions';
import { Form } from 'semantic-ui-react'

class MultiSelectBox extends React.Component {
    componentDidMount() {
        this.selectedOptions();
    }

  selectedOptions = () => {
      let selectedOption = this.props.options.map((option) => { 
        if (this.props.selected.includes(option.thema)) {
            return ( 
             option.thema_id
            );
        }
        return null;
      }); 
      selectedOption = selectedOption.filter(option => option !== null);
      return (
          selectedOption
      );
  }

  getIdfromTheme = (val) => {
    let id = this.props.themes.map((theme) => {

        if (theme.thema === val){
            return theme;
        }
        return null;
    });
    id = id.filter(option => option !== null);
    return id;
  }

  renderOptions = () => {
    let options=this.props.options;
    options = options.filter(option => option.thema_id !== 0);
    options = options.sort((a,b ) =>{
        return a.thema.localeCompare(b.thema);
    });
    options = options.map((option) => {
        return {
            text: option.thema,
            key: option.thema_id,
            value: option.thema_id
        }
    });
    return (
        options
    );
  }

  onSelectChange = (e) => {
    let val={}
    
    if (e.target.innerText === '') {
        val = this.props.themes.filter(theme => theme.thema === e.target.parentElement.innerText);
        val = val[0];
        val={[val.thema_id]:{thema:val.thema, thema_id: val.thema_id}}
        this.props.fetchMultipleSelectBoxValues(val, 'del');
    } else {
        val = this.props.themes.filter(theme => theme.thema === e.target.innerText);
        val = val[0];
        val={[val.thema_id]:{thema:val.thema, thema_id: val.thema_id}};
        if (this.props.msbv.length === 1){
            if(this.props.msbv[0].thema_id === 58){
               const deldash ={[this.props.msbv[0].thema_id]:this.props.msbv[0]} 
                const node = document.querySelector('#selectedItems').children[1].firstChild;
                const child  = document.querySelector('#selectedItems').children[1].firstChild.children[0];
                if(child.innerText === "-"){
                    node.removeChild(child);
                }
                this.props.fetchMultipleSelectBoxValues(deldash, 'del');
            }
        }  
        this.props.fetchMultipleSelectBoxValues(val, 'add');
    }
  }

  render() {
      return (
        <div className="inline field" id="selectedItems">
            <label style={{ fontSize: '.92857143em', fontWeight: '700' }}>{this.props.name}</label>
            <Form.Dropdown

            style={{ borderRadius:'5px' ,borderStyle:'solid', borderWidth:'1px', borderColor:'#CCCCCC' }}
            fluid
            multiple
            search
            defaultValue={this.selectedOptions()}
            options={this.renderOptions()}
            onChange={(e) => this.onSelectChange(e)}
          />
        </div>

      );
  }
}

const mapStateToProps = (state) => {
    return {
        themes: Object.values(state.themes),
        msbv: Object.values(state.multipleSelectBoxValues)
    }
}

export default connect(mapStateToProps, { fetchMultipleSelectBoxValues })(MultiSelectBox);
