import React from 'react';

const Copyright = (props) => {
  const _COPY ='\u00A9';
  const color = props.color;
  
  const size = props.size === null ? '12px' : props.size;
  const copyright = 'Powered by  Canisa 2020'+_COPY;
  const loginname = props.page === 'login' ? null : `Ingelogd als ${props.currentUser}`;
    
  return (
    <div style={{ float:'right', color:`${color}`, fontSize:`${size}`, fontWeight: 'bold' }}>  
        {copyright}
        <p>{loginname}</p>
    </div>
  );
}

export default Copyright;